export const albums_data = {
   "2019-10-14-svalbard": {
      "name": "2019-10-14 Svalbard",
      "photos": [
         {
            "faces": [],
            "height": 3024,
            "name": "00000IMG_00000_BURST20191015113122480_COVER.jpg",
            "slug": "00000img-00000-burst20191015113122480-cover-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015113122480-cover-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_00000img-00000-burst20191015113122480-cover-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015113122480-cover-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015113122480-cover-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_00000img-00000-burst20191015113122480-cover-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015113122480-cover-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "00000IMG_00000_BURST20191015113123491_COVER.jpg",
            "slug": "00000img-00000-burst20191015113123491-cover-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015113123491-cover-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_00000img-00000-burst20191015113123491-cover-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015113123491-cover-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015113123491-cover-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_00000img-00000-burst20191015113123491-cover-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015113123491-cover-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "00000IMG_00000_BURST20191015115704976_COVER.jpg",
            "slug": "00000img-00000-burst20191015115704976-cover-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015115704976-cover-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_00000img-00000-burst20191015115704976-cover-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015115704976-cover-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015115704976-cover-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_00000img-00000-burst20191015115704976-cover-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015115704976-cover-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "00000IMG_00000_BURST20191015115706133_COVER.jpg",
            "slug": "00000img-00000-burst20191015115706133-cover-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015115706133-cover-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_00000img-00000-burst20191015115706133-cover-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015115706133-cover-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015115706133-cover-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_00000img-00000-burst20191015115706133-cover-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015115706133-cover-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "00000IMG_00000_BURST20191015121627146_COVER.jpg",
            "slug": "00000img-00000-burst20191015121627146-cover-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015121627146-cover-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_00000img-00000-burst20191015121627146-cover-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015121627146-cover-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015121627146-cover-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_00000img-00000-burst20191015121627146-cover-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015121627146-cover-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "00000IMG_00000_BURST20191015121627689_COVER.jpg",
            "slug": "00000img-00000-burst20191015121627689-cover-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015121627689-cover-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_00000img-00000-burst20191015121627689-cover-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015121627689-cover-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015121627689-cover-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_00000img-00000-burst20191015121627689-cover-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015121627689-cover-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "00000IMG_00000_BURST20191015121628604_COVER.jpg",
            "slug": "00000img-00000-burst20191015121628604-cover-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015121628604-cover-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_00000img-00000-burst20191015121628604-cover-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015121628604-cover-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015121628604-cover-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_00000img-00000-burst20191015121628604-cover-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015121628604-cover-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "00000IMG_00000_BURST20191015121629033_COVER.jpg",
            "slug": "00000img-00000-burst20191015121629033-cover-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015121629033-cover-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_00000img-00000-burst20191015121629033-cover-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015121629033-cover-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015121629033-cover-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_00000img-00000-burst20191015121629033-cover-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015121629033-cover-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "00000IMG_00000_BURST20191015121630628_COVER.jpg",
            "slug": "00000img-00000-burst20191015121630628-cover-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015121630628-cover-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_00000img-00000-burst20191015121630628-cover-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015121630628-cover-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015121630628-cover-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_00000img-00000-burst20191015121630628-cover-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015121630628-cover-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "00000IMG_00000_BURST20191015182901683_COVER.jpg",
            "slug": "00000img-00000-burst20191015182901683-cover-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015182901683-cover-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_00000img-00000-burst20191015182901683-cover-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00000img-00000-burst20191015182901683-cover-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015182901683-cover-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_00000img-00000-burst20191015182901683-cover-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015182901683-cover-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "00100sPORTRAIT_00100_BURST20191015115620629_COVER.jpg",
            "slug": "00100sportrait-00100-burst20191015115620629-cover-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00100sportrait-00100-burst20191015115620629-cover-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_00100sportrait-00100-burst20191015115620629-cover-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00100sportrait-00100-burst20191015115620629-cover-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_00100sportrait-00100-burst20191015115620629-cover-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_00100sportrait-00100-burst20191015115620629-cover-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00100sportrait-00100-burst20191015115620629-cover-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "00100sPORTRAIT_00100_BURST20191015115624373_COVER.jpg",
            "slug": "00100sportrait-00100-burst20191015115624373-cover-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00100sportrait-00100-burst20191015115624373-cover-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_00100sportrait-00100-burst20191015115624373-cover-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_00100sportrait-00100-burst20191015115624373-cover-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_00100sportrait-00100-burst20191015115624373-cover-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_00100sportrait-00100-burst20191015115624373-cover-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00100sportrait-00100-burst20191015115624373-cover-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 1885,
            "name": "2019-10-14 Svalbard_MVIMG_20191017_105945.jpeg",
            "slug": "2019-10-14-svalbard-mvimg-20191017-105945-jpeg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1000_2019-10-14-svalbard-mvimg-20191017-105945-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x640_2019-10-14-svalbard-mvimg-20191017-105945-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1000_2019-10-14-svalbard-mvimg-20191017-105945-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x312_2019-10-14-svalbard-mvimg-20191017-105945-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x500_2019-10-14-svalbard-mvimg-20191017-105945-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x312_2019-10-14-svalbard-mvimg-20191017-105945-jpeg.jpeg",
            "width": 3016
         },
         {
            "faces": [],
            "height": 2944,
            "name": "DSCF3939.jpg",
            "slug": "dscf3939-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1066_dscf3939-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x682_dscf3939-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1066_dscf3939-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/499x333_dscf3939-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x533_dscf3939-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/499x333_dscf3939-jpg.jpg",
            "width": 4416
         },
         {
            "faces": [],
            "height": 4416,
            "name": "DSCF3992~2.jpg",
            "slug": "dscf3992-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2400_dscf3992-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1536_dscf3992-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2400_dscf3992-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x750_dscf3992-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1200_dscf3992-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x750_dscf3992-2-jpg.jpg",
            "width": 2944
         },
         {
            "faces": [],
            "height": 3024,
            "name": "IMG_20191014_173207.jpg",
            "slug": "img-20191014-173207-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_img-20191014-173207-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_img-20191014-173207-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_img-20191014-173207-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_img-20191014-173207-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_img-20191014-173207-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_img-20191014-173207-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "IMG_20191014_183923.jpg",
            "slug": "img-20191014-183923-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_img-20191014-183923-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_img-20191014-183923-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_img-20191014-183923-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_img-20191014-183923-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_img-20191014-183923-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_img-20191014-183923-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "IMG_20191014_183940.jpg",
            "slug": "img-20191014-183940-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_img-20191014-183940-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_img-20191014-183940-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_img-20191014-183940-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_img-20191014-183940-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_img-20191014-183940-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_img-20191014-183940-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "IMG_20191015_073723.jpg",
            "slug": "img-20191015-073723-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_img-20191015-073723-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_img-20191015-073723-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_img-20191015-073723-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_img-20191015-073723-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_img-20191015-073723-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_img-20191015-073723-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "IMG_20191015_123858.jpg",
            "slug": "img-20191015-123858-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_img-20191015-123858-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_img-20191015-123858-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_img-20191015-123858-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_img-20191015-123858-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_img-20191015-123858-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_img-20191015-123858-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "IMG_20191016_140953.jpg",
            "slug": "img-20191016-140953-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_img-20191016-140953-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_img-20191016-140953-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_img-20191016-140953-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_img-20191016-140953-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_img-20191016-140953-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_img-20191016-140953-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "IMG_20191016_142745.jpg",
            "slug": "img-20191016-142745-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_img-20191016-142745-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_img-20191016-142745-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_img-20191016-142745-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_img-20191016-142745-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_img-20191016-142745-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_img-20191016-142745-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "IMG_20191016_143442.jpg",
            "slug": "img-20191016-143442-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_img-20191016-143442-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_img-20191016-143442-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_img-20191016-143442-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_img-20191016-143442-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_img-20191016-143442-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_img-20191016-143442-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "IMG_20191017_105945_exported_2061682484971971182.jpg",
            "slug": "img-20191017-105945-exported-2061682484971971182-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_img-20191017-105945-exported-2061682484971971182-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_img-20191017-105945-exported-2061682484971971182-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_img-20191017-105945-exported-2061682484971971182-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_img-20191017-105945-exported-2061682484971971182-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_img-20191017-105945-exported-2061682484971971182-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_img-20191017-105945-exported-2061682484971971182-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191014_083529.jpg",
            "slug": "mvimg-20191014-083529-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-083529-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191014-083529-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-083529-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-083529-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191014-083529-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-083529-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191014_090119.jpg",
            "slug": "mvimg-20191014-090119-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-090119-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191014-090119-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-090119-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-090119-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191014-090119-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-090119-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191014_113428.jpg",
            "slug": "mvimg-20191014-113428-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-113428-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191014-113428-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-113428-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-113428-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191014-113428-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-113428-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191014_113437.jpg",
            "slug": "mvimg-20191014-113437-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-113437-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191014-113437-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-113437-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-113437-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191014-113437-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-113437-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191014_113606.jpg",
            "slug": "mvimg-20191014-113606-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-113606-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191014-113606-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-113606-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-113606-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191014-113606-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-113606-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191014_113613.jpg",
            "slug": "mvimg-20191014-113613-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-113613-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191014-113613-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-113613-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-113613-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191014-113613-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-113613-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191014_114850.jpg",
            "slug": "mvimg-20191014-114850-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-114850-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191014-114850-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-114850-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-114850-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191014-114850-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-114850-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191014_114955.jpg",
            "slug": "mvimg-20191014-114955-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-114955-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191014-114955-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-114955-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-114955-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191014-114955-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-114955-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191014_115639~2.jpg",
            "slug": "mvimg-20191014-115639-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-115639-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191014-115639-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-115639-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-115639-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191014-115639-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-115639-2-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191014_124921~2.jpg",
            "slug": "mvimg-20191014-124921-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-124921-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191014-124921-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-124921-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-124921-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191014-124921-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-124921-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191014_131034.jpg",
            "slug": "mvimg-20191014-131034-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-131034-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191014-131034-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-131034-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-131034-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191014-131034-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-131034-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191014_131043.jpg",
            "slug": "mvimg-20191014-131043-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-131043-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191014-131043-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-131043-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-131043-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191014-131043-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-131043-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191014_133538.jpg",
            "slug": "mvimg-20191014-133538-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-133538-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191014-133538-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-133538-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-133538-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191014-133538-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-133538-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191014_141253.jpg",
            "slug": "mvimg-20191014-141253-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-141253-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191014-141253-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-141253-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-141253-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191014-141253-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-141253-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191014_141256.jpg",
            "slug": "mvimg-20191014-141256-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-141256-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191014-141256-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191014-141256-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-141256-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191014-141256-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191014-141256-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191014_173139.jpg",
            "slug": "mvimg-20191014-173139-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-173139-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191014-173139-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-173139-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-173139-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191014-173139-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-173139-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191014_173143.jpg",
            "slug": "mvimg-20191014-173143-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-173143-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191014-173143-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-173143-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-173143-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191014-173143-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-173143-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191014_173145.jpg",
            "slug": "mvimg-20191014-173145-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-173145-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191014-173145-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-173145-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-173145-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191014-173145-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-173145-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191014_173431.jpg",
            "slug": "mvimg-20191014-173431-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-173431-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191014-173431-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-173431-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-173431-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191014-173431-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-173431-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191014_173433.jpg",
            "slug": "mvimg-20191014-173433-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-173433-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191014-173433-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191014-173433-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-173433-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191014-173433-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191014-173433-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191015_073658.jpg",
            "slug": "mvimg-20191015-073658-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-073658-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191015-073658-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-073658-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-073658-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191015-073658-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-073658-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_073753.jpg",
            "slug": "mvimg-20191015-073753-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-073753-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-073753-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-073753-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-073753-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-073753-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-073753-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191015_091406.jpg",
            "slug": "mvimg-20191015-091406-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-091406-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191015-091406-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-091406-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-091406-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191015-091406-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-091406-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191015_091408.jpg",
            "slug": "mvimg-20191015-091408-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-091408-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191015-091408-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-091408-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-091408-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191015-091408-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-091408-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_091417.jpg",
            "slug": "mvimg-20191015-091417-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-091417-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-091417-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-091417-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-091417-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-091417-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-091417-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_092254.jpg",
            "slug": "mvimg-20191015-092254-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-092254-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-092254-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-092254-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-092254-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-092254-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-092254-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_095433.jpg",
            "slug": "mvimg-20191015-095433-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-095433-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-095433-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-095433-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-095433-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-095433-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-095433-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_095433_1.jpg",
            "slug": "mvimg-20191015-095433-1-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-095433-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-095433-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-095433-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-095433-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-095433-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-095433-1-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_095926~2.jpg",
            "slug": "mvimg-20191015-095926-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-095926-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-095926-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-095926-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-095926-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-095926-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-095926-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_095927.jpg",
            "slug": "mvimg-20191015-095927-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-095927-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-095927-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-095927-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-095927-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-095927-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-095927-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_095928.jpg",
            "slug": "mvimg-20191015-095928-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-095928-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-095928-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-095928-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-095928-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-095928-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-095928-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_095928_1.jpg",
            "slug": "mvimg-20191015-095928-1-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-095928-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-095928-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-095928-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-095928-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-095928-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-095928-1-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_100234.jpg",
            "slug": "mvimg-20191015-100234-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100234-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-100234-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100234-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100234-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-100234-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100234-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_100234_1.jpg",
            "slug": "mvimg-20191015-100234-1-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100234-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-100234-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100234-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100234-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-100234-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100234-1-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_100236.jpg",
            "slug": "mvimg-20191015-100236-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100236-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-100236-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100236-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100236-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-100236-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100236-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_100237.jpg",
            "slug": "mvimg-20191015-100237-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100237-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-100237-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100237-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100237-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-100237-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100237-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_100237_1.jpg",
            "slug": "mvimg-20191015-100237-1-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100237-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-100237-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100237-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100237-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-100237-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100237-1-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_100238.jpg",
            "slug": "mvimg-20191015-100238-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100238-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-100238-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100238-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100238-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-100238-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100238-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_100239.jpg",
            "slug": "mvimg-20191015-100239-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100239-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-100239-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100239-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100239-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-100239-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100239-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_100239_1.jpg",
            "slug": "mvimg-20191015-100239-1-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100239-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-100239-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100239-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100239-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-100239-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100239-1-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_100241.jpg",
            "slug": "mvimg-20191015-100241-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100241-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-100241-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100241-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100241-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-100241-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100241-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_100243.jpg",
            "slug": "mvimg-20191015-100243-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100243-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-100243-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100243-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100243-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-100243-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100243-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_100244.jpg",
            "slug": "mvimg-20191015-100244-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100244-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-100244-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100244-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100244-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-100244-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100244-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_100245.jpg",
            "slug": "mvimg-20191015-100245-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100245-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-100245-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-100245-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100245-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-100245-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-100245-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191015_101908~2.jpg",
            "slug": "mvimg-20191015-101908-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-101908-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191015-101908-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-101908-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-101908-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191015-101908-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-101908-2-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191015_101953.jpg",
            "slug": "mvimg-20191015-101953-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-101953-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-101953-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-101953-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-101953-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-101953-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-101953-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191015_101955.jpg",
            "slug": "mvimg-20191015-101955-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-101955-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-101955-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-101955-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-101955-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-101955-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-101955-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 2448,
            "name": "MVIMG_20191015_102003.jpg",
            "slug": "mvimg-20191015-102003-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-102003-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191015-102003-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-102003-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-102003-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191015-102003-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-102003-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "MVIMG_20191015_102014.jpg",
            "slug": "mvimg-20191015-102014-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-102014-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191015-102014-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-102014-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-102014-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191015-102014-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-102014-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 2448,
            "name": "MVIMG_20191015_102015.jpg",
            "slug": "mvimg-20191015-102015-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-102015-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191015-102015-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-102015-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-102015-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191015-102015-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-102015-jpg.jpg",
            "width": 3264
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_104306.jpg",
            "slug": "mvimg-20191015-104306-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-104306-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-104306-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-104306-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-104306-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-104306-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-104306-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_104308.jpg",
            "slug": "mvimg-20191015-104308-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-104308-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-104308-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-104308-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-104308-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-104308-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-104308-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_104313.jpg",
            "slug": "mvimg-20191015-104313-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-104313-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-104313-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-104313-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-104313-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-104313-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-104313-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_113150.jpg",
            "slug": "mvimg-20191015-113150-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-113150-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-113150-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-113150-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-113150-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-113150-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-113150-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_113151.jpg",
            "slug": "mvimg-20191015-113151-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-113151-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-113151-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-113151-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-113151-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-113151-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-113151-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_115637.jpg",
            "slug": "mvimg-20191015-115637-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-115637-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-115637-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-115637-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-115637-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-115637-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-115637-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_115639.jpg",
            "slug": "mvimg-20191015-115639-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-115639-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-115639-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-115639-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-115639-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-115639-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-115639-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_115642.jpg",
            "slug": "mvimg-20191015-115642-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-115642-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-115642-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-115642-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-115642-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-115642-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-115642-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_115645.jpg",
            "slug": "mvimg-20191015-115645-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-115645-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-115645-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-115645-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-115645-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-115645-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-115645-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_115655.jpg",
            "slug": "mvimg-20191015-115655-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-115655-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-115655-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-115655-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-115655-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-115655-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-115655-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_115656.jpg",
            "slug": "mvimg-20191015-115656-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-115656-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-115656-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-115656-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-115656-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-115656-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-115656-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191015_120324.jpg",
            "slug": "mvimg-20191015-120324-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120324-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-120324-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120324-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120324-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-120324-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120324-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191015_120328.jpg",
            "slug": "mvimg-20191015-120328-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120328-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-120328-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120328-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120328-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-120328-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120328-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191015_120331.jpg",
            "slug": "mvimg-20191015-120331-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120331-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-120331-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120331-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120331-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-120331-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120331-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191015_120333.jpg",
            "slug": "mvimg-20191015-120333-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120333-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-120333-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120333-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120333-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-120333-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120333-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191015_120343.jpg",
            "slug": "mvimg-20191015-120343-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120343-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-120343-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120343-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120343-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-120343-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120343-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191015_120346.jpg",
            "slug": "mvimg-20191015-120346-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120346-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-120346-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120346-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120346-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-120346-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120346-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191015_120348.jpg",
            "slug": "mvimg-20191015-120348-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120348-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-120348-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120348-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120348-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-120348-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120348-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191015_120403.jpg",
            "slug": "mvimg-20191015-120403-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120403-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-120403-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120403-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120403-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-120403-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120403-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191015_120403_1.jpg",
            "slug": "mvimg-20191015-120403-1-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120403-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-120403-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120403-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120403-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-120403-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120403-1-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191015_120408.jpg",
            "slug": "mvimg-20191015-120408-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120408-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-120408-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-120408-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120408-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-120408-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-120408-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191015_135132.jpg",
            "slug": "mvimg-20191015-135132-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-135132-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191015-135132-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-135132-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-135132-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191015-135132-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-135132-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191015_135134.jpg",
            "slug": "mvimg-20191015-135134-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-135134-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191015-135134-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-135134-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-135134-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191015-135134-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-135134-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_135226.jpg",
            "slug": "mvimg-20191015-135226-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-135226-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-135226-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-135226-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-135226-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-135226-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-135226-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191015_170906.jpg",
            "slug": "mvimg-20191015-170906-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-170906-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191015-170906-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191015-170906-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-170906-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191015-170906-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191015-170906-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191015_182855.jpg",
            "slug": "mvimg-20191015-182855-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-182855-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191015-182855-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191015-182855-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-182855-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191015-182855-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191015-182855-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_091247.jpg",
            "slug": "mvimg-20191016-091247-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-091247-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-091247-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-091247-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-091247-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-091247-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-091247-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_091949.jpg",
            "slug": "mvimg-20191016-091949-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-091949-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-091949-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-091949-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-091949-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-091949-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-091949-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191016_131317~2.jpg",
            "slug": "mvimg-20191016-131317-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191016-131317-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191016-131317-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191016-131317-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191016-131317-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191016-131317-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191016-131317-2-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191016_140858.jpg",
            "slug": "mvimg-20191016-140858-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191016-140858-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191016-140858-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191016-140858-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191016-140858-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191016-140858-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191016-140858-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191016_140901.jpg",
            "slug": "mvimg-20191016-140901-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191016-140901-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191016-140901-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191016-140901-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191016-140901-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191016-140901-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191016-140901-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_140911.jpg",
            "slug": "mvimg-20191016-140911-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-140911-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-140911-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-140911-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-140911-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-140911-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-140911-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_140913.jpg",
            "slug": "mvimg-20191016-140913-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-140913-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-140913-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-140913-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-140913-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-140913-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-140913-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191016_142715.jpg",
            "slug": "mvimg-20191016-142715-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191016-142715-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191016-142715-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191016-142715-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191016-142715-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191016-142715-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191016-142715-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191016_142719.jpg",
            "slug": "mvimg-20191016-142719-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191016-142719-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191016-142719-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191016-142719-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191016-142719-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191016-142719-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191016-142719-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_142729.jpg",
            "slug": "mvimg-20191016-142729-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-142729-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-142729-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-142729-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-142729-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-142729-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-142729-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_142733.jpg",
            "slug": "mvimg-20191016-142733-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-142733-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-142733-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-142733-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-142733-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-142733-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-142733-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_142949.jpg",
            "slug": "mvimg-20191016-142949-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-142949-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-142949-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-142949-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-142949-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-142949-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-142949-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_145343.jpg",
            "slug": "mvimg-20191016-145343-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-145343-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-145343-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-145343-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-145343-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-145343-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-145343-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_145347.jpg",
            "slug": "mvimg-20191016-145347-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-145347-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-145347-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-145347-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-145347-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-145347-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-145347-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_145405.jpg",
            "slug": "mvimg-20191016-145405-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-145405-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-145405-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-145405-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-145405-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-145405-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-145405-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_182404.jpg",
            "slug": "mvimg-20191016-182404-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-182404-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-182404-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-182404-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-182404-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-182404-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-182404-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_182407.jpg",
            "slug": "mvimg-20191016-182407-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-182407-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-182407-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-182407-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-182407-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-182407-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-182407-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191016_182614.jpg",
            "slug": "mvimg-20191016-182614-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-182614-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191016-182614-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191016-182614-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-182614-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191016-182614-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191016-182614-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_080641.jpg",
            "slug": "mvimg-20191017-080641-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-080641-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-080641-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-080641-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-080641-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-080641-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-080641-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_090501.jpg",
            "slug": "mvimg-20191017-090501-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-090501-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-090501-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-090501-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-090501-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-090501-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-090501-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_091143.jpg",
            "slug": "mvimg-20191017-091143-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-091143-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-091143-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-091143-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-091143-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-091143-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-091143-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_092017.jpg",
            "slug": "mvimg-20191017-092017-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-092017-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-092017-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-092017-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-092017-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-092017-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-092017-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_105945.jpg",
            "slug": "mvimg-20191017-105945-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-105945-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-105945-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-105945-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-105945-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-105945-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-105945-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_123101~2.jpg",
            "slug": "mvimg-20191017-123101-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-123101-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-123101-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-123101-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-123101-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-123101-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-123101-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_124246~2.jpg",
            "slug": "mvimg-20191017-124246-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-124246-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-124246-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-124246-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-124246-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-124246-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-124246-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3264,
            "name": "MVIMG_20191017_124400.jpg",
            "slug": "mvimg-20191017-124400-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-124400-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-124400-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-124400-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-124400-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-124400-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-124400-jpg.jpg",
            "width": 2448
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_125002~2.jpg",
            "slug": "mvimg-20191017-125002-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125002-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-125002-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125002-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125002-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-125002-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125002-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_125741.jpg",
            "slug": "mvimg-20191017-125741-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125741-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-125741-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125741-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125741-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-125741-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125741-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_125742.jpg",
            "slug": "mvimg-20191017-125742-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125742-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-125742-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125742-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125742-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-125742-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125742-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_125742_1.jpg",
            "slug": "mvimg-20191017-125742-1-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125742-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-125742-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125742-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125742-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-125742-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125742-1-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_125742_2.jpg",
            "slug": "mvimg-20191017-125742-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125742-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-125742-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125742-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125742-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-125742-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125742-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_125742_3.jpg",
            "slug": "mvimg-20191017-125742-3-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125742-3-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-125742-3-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125742-3-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125742-3-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-125742-3-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125742-3-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_125743.jpg",
            "slug": "mvimg-20191017-125743-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125743-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-125743-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-125743-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125743-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-125743-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-125743-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_135333.jpg",
            "slug": "mvimg-20191017-135333-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-135333-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-135333-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-135333-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-135333-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-135333-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-135333-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_135805~2.jpg",
            "slug": "mvimg-20191017-135805-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-135805-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-135805-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-135805-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-135805-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-135805-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-135805-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_135811~2.jpg",
            "slug": "mvimg-20191017-135811-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-135811-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-135811-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-135811-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-135811-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-135811-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-135811-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_140449~2.jpg",
            "slug": "mvimg-20191017-140449-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-140449-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-140449-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-140449-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-140449-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-140449-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-140449-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_140929~2.jpg",
            "slug": "mvimg-20191017-140929-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-140929-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-140929-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-140929-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-140929-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-140929-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-140929-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191017_141911~2.jpg",
            "slug": "mvimg-20191017-141911-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191017-141911-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191017-141911-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191017-141911-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191017-141911-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191017-141911-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191017-141911-2-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_142839.jpg",
            "slug": "mvimg-20191017-142839-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-142839-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-142839-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-142839-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-142839-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-142839-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-142839-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_142844~2.jpg",
            "slug": "mvimg-20191017-142844-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-142844-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-142844-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-142844-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-142844-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-142844-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-142844-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_142853~2.jpg",
            "slug": "mvimg-20191017-142853-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-142853-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-142853-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-142853-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-142853-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-142853-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-142853-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_145307~2.jpg",
            "slug": "mvimg-20191017-145307-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-145307-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-145307-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-145307-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-145307-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-145307-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-145307-2-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_145312.jpg",
            "slug": "mvimg-20191017-145312-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-145312-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-145312-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-145312-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-145312-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-145312-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-145312-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_145315.jpg",
            "slug": "mvimg-20191017-145315-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-145315-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-145315-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-145315-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-145315-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-145315-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-145315-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_145807.jpg",
            "slug": "mvimg-20191017-145807-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-145807-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-145807-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-145807-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-145807-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-145807-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-145807-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_150511.jpg",
            "slug": "mvimg-20191017-150511-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-150511-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-150511-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-150511-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-150511-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-150511-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-150511-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191017_150519~2.jpg",
            "slug": "mvimg-20191017-150519-2-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191017-150519-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191017-150519-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191017-150519-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191017-150519-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191017-150519-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191017-150519-2-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_151242.jpg",
            "slug": "mvimg-20191017-151242-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-151242-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-151242-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-151242-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-151242-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-151242-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-151242-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_151245.jpg",
            "slug": "mvimg-20191017-151245-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-151245-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-151245-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-151245-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-151245-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-151245-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-151245-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_152050.jpg",
            "slug": "mvimg-20191017-152050-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-152050-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-152050-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-152050-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-152050-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-152050-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-152050-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_154826.jpg",
            "slug": "mvimg-20191017-154826-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-154826-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-154826-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-154826-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-154826-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-154826-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-154826-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_155546.jpg",
            "slug": "mvimg-20191017-155546-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-155546-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-155546-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-155546-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-155546-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-155546-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-155546-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191017_191736.jpg",
            "slug": "mvimg-20191017-191736-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191017-191736-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191017-191736-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191017-191736-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191017-191736-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191017-191736-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191017-191736-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191017_191739.jpg",
            "slug": "mvimg-20191017-191739-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191017-191739-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191017-191739-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191017-191739-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191017-191739-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191017-191739-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191017-191739-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_212219.jpg",
            "slug": "mvimg-20191017-212219-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-212219-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-212219-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-212219-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-212219-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-212219-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-212219-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191017_222654.jpg",
            "slug": "mvimg-20191017-222654-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-222654-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191017-222654-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191017-222654-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-222654-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191017-222654-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191017-222654-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191018_094134.jpg",
            "slug": "mvimg-20191018-094134-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191018-094134-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191018-094134-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191018-094134-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191018-094134-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191018-094134-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191018-094134-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191018_121151.jpg",
            "slug": "mvimg-20191018-121151-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191018-121151-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191018-121151-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191018-121151-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191018-121151-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191018-121151-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191018-121151-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191018_121201.jpg",
            "slug": "mvimg-20191018-121201-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191018-121201-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191018-121201-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191018-121201-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191018-121201-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191018-121201-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191018-121201-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191018_121652.jpg",
            "slug": "mvimg-20191018-121652-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191018-121652-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191018-121652-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191018-121652-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191018-121652-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191018-121652-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191018-121652-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "MVIMG_20191018_121657.jpg",
            "slug": "mvimg-20191018-121657-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191018-121657-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x768_mvimg-20191018-121657-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1200_mvimg-20191018-121657-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191018-121657-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x600_mvimg-20191018-121657-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x375_mvimg-20191018-121657-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 4032,
            "name": "MVIMG_20191018_121751.jpg",
            "slug": "mvimg-20191018-121751-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191018-121751-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x1365_mvimg-20191018-121751-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x2133_mvimg-20191018-121751-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191018-121751-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x1066_mvimg-20191018-121751-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x666_mvimg-20191018-121751-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 1605,
            "name": "PANO_20191014_124049.vr.jpg",
            "slug": "pano-20191014-124049-vr-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x618_pano-20191014-124049-vr-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x396_pano-20191014-124049-vr-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x618_pano-20191014-124049-vr-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/499x193_pano-20191014-124049-vr-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x309_pano-20191014-124049-vr-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/499x193_pano-20191014-124049-vr-jpg.jpg",
            "width": 4150
         },
         {
            "faces": [],
            "height": 1664,
            "name": "PANO_20191014_205951.vr.jpg",
            "slug": "pano-20191014-205951-vr-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x1007_pano-20191014-205951-vr-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x644_pano-20191014-205951-vr-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x1007_pano-20191014-205951-vr-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x314_pano-20191014-205951-vr-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x503_pano-20191014-205951-vr-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x314_pano-20191014-205951-vr-jpg.jpg",
            "width": 2642
         },
         {
            "faces": [],
            "height": 1675,
            "name": "PANO_20191015_154551.vr.jpg",
            "slug": "pano-20191015-154551-vr-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x566_pano-20191015-154551-vr-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x362_pano-20191015-154551-vr-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x566_pano-20191015-154551-vr-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x176_pano-20191015-154551-vr-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x283_pano-20191015-154551-vr-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x176_pano-20191015-154551-vr-jpg.jpg",
            "width": 4732
         },
         {
            "faces": [],
            "height": 4304,
            "name": "PANO_20191015_222918.jpg",
            "slug": "pano-20191015-222918-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x715_pano-20191015-222918-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x457_pano-20191015-222918-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x715_pano-20191015-222918-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/499x223_pano-20191015-222918-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x357_pano-20191015-222918-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/499x223_pano-20191015-222918-jpg.jpg",
            "width": 9630
         },
         {
            "faces": [],
            "height": 1658,
            "name": "PANO_20191016_120035.vr.jpg",
            "slug": "pano-20191016-120035-vr-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x541_pano-20191016-120035-vr-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x346_pano-20191016-120035-vr-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x541_pano-20191016-120035-vr-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x169_pano-20191016-120035-vr-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x270_pano-20191016-120035-vr-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x169_pano-20191016-120035-vr-jpg.jpg",
            "width": 4902
         },
         {
            "faces": [],
            "height": 3738,
            "name": "PANO_20191018_094003.jpg",
            "slug": "pano-20191018-094003-jpg",
            "src": "/static/_gallery/albums/2019-10-14-svalbard/1600x584_pano-20191018-094003-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1024x373_pano-20191018-094003-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/1600x584_pano-20191018-094003-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/500x182_pano-20191018-094003-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2019-10-14-svalbard/800x292_pano-20191018-094003-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/2019-10-14-svalbard/500x182_pano-20191018-094003-jpg.jpg",
            "width": 10240
         }
      ],
      "slug": "2019-10-14-svalbard",
      "src": "/static/_gallery/albums/2019-10-14-svalbard/500x375_00000img-00000-burst20191015113122480-cover-jpg.jpg"
   },
   "2021-07-02-seaford": {
      "name": "2021-07-02 Seaford",
      "photos": [
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0215.jpeg",
            "slug": "dscf0215-jpeg",
            "src": "/static/_gallery/albums/2021-07-02-seaford/1600x1201_dscf0215-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/1024x768_dscf0215-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/1600x1201_dscf0215-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0215-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/800x600_dscf0215-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0215-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0238.jpeg",
            "slug": "dscf0238-jpeg",
            "src": "/static/_gallery/albums/2021-07-02-seaford/1600x1201_dscf0238-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/1024x768_dscf0238-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/1600x1201_dscf0238-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0238-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/800x600_dscf0238-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0238-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0263.jpeg",
            "slug": "dscf0263-jpeg",
            "src": "/static/_gallery/albums/2021-07-02-seaford/1600x1201_dscf0263-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/1024x768_dscf0263-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/1600x1201_dscf0263-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0263-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/800x600_dscf0263-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0263-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0289.jpeg",
            "slug": "dscf0289-jpeg",
            "src": "/static/_gallery/albums/2021-07-02-seaford/1600x1201_dscf0289-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/1024x768_dscf0289-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/1600x1201_dscf0289-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0289-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/800x600_dscf0289-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0289-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0298.jpeg",
            "slug": "dscf0298-jpeg",
            "src": "/static/_gallery/albums/2021-07-02-seaford/1600x1201_dscf0298-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/1024x768_dscf0298-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/1600x1201_dscf0298-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0298-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/800x600_dscf0298-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0298-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0335.jpeg",
            "slug": "dscf0335-jpeg",
            "src": "/static/_gallery/albums/2021-07-02-seaford/1600x1201_dscf0335-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/1024x768_dscf0335-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/1600x1201_dscf0335-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0335-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-02-seaford/800x600_dscf0335-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0335-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2021-07-02-seaford",
      "src": "/static/_gallery/albums/2021-07-02-seaford/500x375_dscf0215-jpeg.jpeg"
   },
   "2021-07-05-arundel": {
      "name": "2021-07-05 Arundel",
      "photos": [
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0359.jpeg",
            "slug": "dscf0359-jpeg",
            "src": "/static/_gallery/albums/2021-07-05-arundel/1600x1201_dscf0359-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/1024x768_dscf0359-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/1600x1201_dscf0359-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/500x375_dscf0359-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/800x600_dscf0359-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-05-arundel/500x375_dscf0359-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0364.jpeg",
            "slug": "dscf0364-jpeg",
            "src": "/static/_gallery/albums/2021-07-05-arundel/1600x1201_dscf0364-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/1024x768_dscf0364-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/1600x1201_dscf0364-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/500x375_dscf0364-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/800x600_dscf0364-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-05-arundel/500x375_dscf0364-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0385.jpeg",
            "slug": "dscf0385-jpeg",
            "src": "/static/_gallery/albums/2021-07-05-arundel/1600x1201_dscf0385-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/1024x768_dscf0385-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/1600x1201_dscf0385-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/500x375_dscf0385-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/800x600_dscf0385-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-05-arundel/500x375_dscf0385-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0388.jpeg",
            "slug": "dscf0388-jpeg",
            "src": "/static/_gallery/albums/2021-07-05-arundel/1600x1201_dscf0388-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/1024x768_dscf0388-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/1600x1201_dscf0388-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/500x375_dscf0388-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/800x600_dscf0388-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-05-arundel/500x375_dscf0388-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0400.jpeg",
            "slug": "dscf0400-jpeg",
            "src": "/static/_gallery/albums/2021-07-05-arundel/1600x1201_dscf0400-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/1024x768_dscf0400-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/1600x1201_dscf0400-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/500x375_dscf0400-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-05-arundel/800x600_dscf0400-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-05-arundel/500x375_dscf0400-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2021-07-05-arundel",
      "src": "/static/_gallery/albums/2021-07-05-arundel/500x375_dscf0359-jpeg.jpeg"
   },
   "2021-07-08-birds": {
      "name": "2021-07-08 Birds",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF0497.jpeg",
            "slug": "dscf0497-jpeg",
            "src": "/static/_gallery/albums/2021-07-08-birds/1600x2000_dscf0497-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-08-birds/1024x1280_dscf0497-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-08-birds/1600x2000_dscf0497-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-08-birds/500x625_dscf0497-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-08-birds/800x1000_dscf0497-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-08-birds/500x625_dscf0497-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF0511.jpeg",
            "slug": "dscf0511-jpeg",
            "src": "/static/_gallery/albums/2021-07-08-birds/1600x2000_dscf0511-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-08-birds/1024x1280_dscf0511-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-08-birds/1600x2000_dscf0511-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-08-birds/500x625_dscf0511-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-08-birds/800x1000_dscf0511-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-08-birds/500x625_dscf0511-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 3199,
            "name": "DSCF0538.jpeg",
            "slug": "dscf0538-jpeg",
            "src": "/static/_gallery/albums/2021-07-08-birds/1600x1279_dscf0538-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-08-birds/1024x818_dscf0538-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-08-birds/1600x1279_dscf0538-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-08-birds/500x399_dscf0538-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-08-birds/800x639_dscf0538-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-08-birds/500x399_dscf0538-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1718.jpeg",
            "slug": "dscf1718-jpeg",
            "src": "/static/_gallery/albums/2021-07-08-birds/1600x2844_dscf1718-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-08-birds/1024x1820_dscf1718-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-08-birds/1600x2844_dscf1718-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-08-birds/500x888_dscf1718-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-08-birds/800x1422_dscf1718-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-08-birds/500x888_dscf1718-jpeg.jpeg",
            "width": 2250
         }
      ],
      "slug": "2021-07-08-birds",
      "src": "/static/_gallery/albums/2021-07-08-birds/500x625_dscf0497-jpeg.jpeg"
   },
   "2021-07-24-wcb-windsor": {
      "name": "2021-07-24 WCB Windsor",
      "photos": [
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0563.jpeg",
            "slug": "dscf0563-jpeg",
            "src": "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0563-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1024x768_dscf0563-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0563-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0563-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/800x600_dscf0563-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0563-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0577.jpeg",
            "slug": "dscf0577-jpeg",
            "src": "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0577-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1024x768_dscf0577-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0577-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0577-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/800x600_dscf0577-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0577-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF0581.jpeg",
            "slug": "dscf0581-jpeg",
            "src": "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x2131_dscf0581-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1024x1363_dscf0581-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x2131_dscf0581-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/500x666_dscf0581-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/800x1065_dscf0581-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-24-wcb-windsor/500x666_dscf0581-jpeg.jpeg",
            "width": 3003
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0584.jpeg",
            "slug": "dscf0584-jpeg",
            "src": "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0584-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1024x768_dscf0584-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0584-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0584-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/800x600_dscf0584-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0584-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0585.jpeg",
            "slug": "dscf0585-jpeg",
            "src": "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0585-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1024x768_dscf0585-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0585-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0585-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/800x600_dscf0585-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0585-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0588.jpeg",
            "slug": "dscf0588-jpeg",
            "src": "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0588-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1024x768_dscf0588-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0588-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0588-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/800x600_dscf0588-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0588-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0608.jpeg",
            "slug": "dscf0608-jpeg",
            "src": "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0608-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1024x768_dscf0608-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0608-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0608-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/800x600_dscf0608-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0608-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0609.jpeg",
            "slug": "dscf0609-jpeg",
            "src": "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0609-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1024x768_dscf0609-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0609-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0609-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/800x600_dscf0609-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0609-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0652.jpeg",
            "slug": "dscf0652-jpeg",
            "src": "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0652-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1024x768_dscf0652-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0652-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0652-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/800x600_dscf0652-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0652-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3003,
            "name": "DSCF0663.jpeg",
            "slug": "dscf0663-jpeg",
            "src": "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0663-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1024x768_dscf0663-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/1600x1201_dscf0663-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0663-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-07-24-wcb-windsor/800x600_dscf0663-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0663-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2021-07-24-wcb-windsor",
      "src": "/static/_gallery/albums/2021-07-24-wcb-windsor/500x375_dscf0563-jpeg.jpeg"
   },
   "2021-09-18-norfolk": {
      "name": "2021-09-18 Norfolk",
      "photos": [
         {
            "faces": [],
            "height": 5328,
            "name": "DSCF3179.jpeg",
            "slug": "dscf3179-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3179-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1363_dscf3179-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3179-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3179-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1065_dscf3179-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3179-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5328,
            "name": "DSCF3189.jpeg",
            "slug": "dscf3189-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3189-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1363_dscf3189-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3189-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3189-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1065_dscf3189-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3189-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3233.jpeg",
            "slug": "dscf3233-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x1201_dscf3233-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x768_dscf3233-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x1201_dscf3233-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x375_dscf3233-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x600_dscf3233-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x375_dscf3233-jpeg.jpeg",
            "width": 5328
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3281.jpeg",
            "slug": "dscf3281-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3281-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1363_dscf3281-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3281-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3281-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1065_dscf3281-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3281-jpeg.jpeg",
            "width": 3003
         },
         {
            "faces": [],
            "height": 5328,
            "name": "DSCF3315.jpeg",
            "slug": "dscf3315-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3315-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1363_dscf3315-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3315-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3315-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1065_dscf3315-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3315-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3337.jpeg",
            "slug": "dscf3337-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3337-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1363_dscf3337-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3337-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3337-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1065_dscf3337-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3337-jpeg.jpeg",
            "width": 3003
         },
         {
            "faces": [],
            "height": 5328,
            "name": "DSCF3355.jpeg",
            "slug": "dscf3355-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3355-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1363_dscf3355-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3355-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3355-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1065_dscf3355-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3355-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5328,
            "name": "DSCF3366.jpeg",
            "slug": "dscf3366-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3366-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1363_dscf3366-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3366-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3366-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1065_dscf3366-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3366-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5328,
            "name": "DSCF3389.3x2.jpeg",
            "slug": "dscf3389-3x2-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1599x2400_dscf3389-3x2-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1536_dscf3389-3x2-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1599x2400_dscf3389-3x2-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x750_dscf3389-3x2-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/799x1200_dscf3389-3x2-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x750_dscf3389-3x2-jpeg.jpeg",
            "width": 3552
         },
         {
            "faces": [],
            "height": 5328,
            "name": "DSCF3389.jpeg",
            "slug": "dscf3389-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3389-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1363_dscf3389-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3389-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3389-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1065_dscf3389-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3389-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3397.jpeg",
            "slug": "dscf3397-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x1201_dscf3397-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x768_dscf3397-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x1201_dscf3397-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x375_dscf3397-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x600_dscf3397-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x375_dscf3397-jpeg.jpeg",
            "width": 5328
         },
         {
            "faces": [],
            "height": 5328,
            "name": "DSCF3422.jpeg",
            "slug": "dscf3422-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3422-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1363_dscf3422-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3422-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3422-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1065_dscf3422-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3422-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5328,
            "name": "DSCF3485.jpeg",
            "slug": "dscf3485-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3485-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1363_dscf3485-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3485-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3485-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1065_dscf3485-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3485-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3716.jpeg",
            "slug": "dscf3716-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3716-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1363_dscf3716-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2131_dscf3716-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3716-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1065_dscf3716-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3716-jpeg.jpeg",
            "width": 3003
         },
         {
            "faces": [],
            "height": 2076,
            "name": "DSCF3726.jpeg",
            "slug": "dscf3726-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x899_dscf3726-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x575_dscf3726-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x899_dscf3726-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x281_dscf3726-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x449_dscf3726-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x281_dscf3726-jpeg.jpeg",
            "width": 3691
         },
         {
            "faces": [],
            "height": 2345,
            "name": "DSCF3745.jpeg",
            "slug": "dscf3745-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x899_dscf3745-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x575_dscf3745-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x899_dscf3745-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x281_dscf3745-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x449_dscf3745-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x281_dscf3745-jpeg.jpeg",
            "width": 4169
         },
         {
            "faces": [],
            "height": 3946,
            "name": "DSCF3812.jpeg",
            "slug": "dscf3812-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2399_dscf3812-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1535_dscf3812-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2399_dscf3812-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x749_dscf3812-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1199_dscf3812-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x749_dscf3812-jpeg.jpeg",
            "width": 2631
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4028.jpeg",
            "slug": "dscf4028-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x1600_dscf4028-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1024_dscf4028-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x1600_dscf4028-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x500_dscf4028-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x800_dscf4028-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x500_dscf4028-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF4043.jpeg",
            "slug": "dscf4043-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2400_dscf4043-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1536_dscf4043-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2400_dscf4043-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x750_dscf4043-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1200_dscf4043-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x750_dscf4043-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF4065.jpeg",
            "slug": "dscf4065-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2400_dscf4065-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1536_dscf4065-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2400_dscf4065-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x750_dscf4065-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1200_dscf4065-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x750_dscf4065-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4075.jpeg",
            "slug": "dscf4075-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2399_dscf4075-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1535_dscf4075-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2399_dscf4075-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x749_dscf4075-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1199_dscf4075-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x749_dscf4075-jpeg.jpeg",
            "width": 2667
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF4076.jpeg",
            "slug": "dscf4076-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2400_dscf4076-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1536_dscf4076-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2400_dscf4076-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x750_dscf4076-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1200_dscf4076-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x750_dscf4076-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4144.jpeg",
            "slug": "dscf4144-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x1066_dscf4144-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x682_dscf4144-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x1066_dscf4144-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x333_dscf4144-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x533_dscf4144-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x333_dscf4144-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4150.jpeg",
            "slug": "dscf4150-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x1066_dscf4150-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x682_dscf4150-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x1066_dscf4150-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x333_dscf4150-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x533_dscf4150-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x333_dscf4150-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF4169.jpeg",
            "slug": "dscf4169-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2400_dscf4169-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1536_dscf4169-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2400_dscf4169-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x750_dscf4169-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1200_dscf4169-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x750_dscf4169-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4192.jpeg",
            "slug": "dscf4192-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x1066_dscf4192-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x682_dscf4192-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x1066_dscf4192-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x333_dscf4192-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x533_dscf4192-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x333_dscf4192-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4213.jpeg",
            "slug": "dscf4213-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x1066_dscf4213-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x682_dscf4213-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x1066_dscf4213-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x333_dscf4213-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x533_dscf4213-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x333_dscf4213-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF4219.jpeg",
            "slug": "dscf4219-jpeg",
            "src": "/static/_gallery/albums/2021-09-18-norfolk/1600x2400_dscf4219-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1024x1536_dscf4219-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/1600x2400_dscf4219-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/500x750_dscf4219-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-09-18-norfolk/800x1200_dscf4219-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-09-18-norfolk/500x750_dscf4219-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2021-09-18-norfolk",
      "src": "/static/_gallery/albums/2021-09-18-norfolk/500x666_dscf3179-jpeg.jpeg"
   },
   "2021-10-22-react-advanced": {
      "name": "2021-10-22 React Advanced",
      "photos": [
         {
            "faces": [],
            "height": 2922,
            "name": "DSCF4373.jpeg",
            "slug": "dscf4373-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4373-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4373-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4373-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4373-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4373-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4373-jpeg.jpeg",
            "width": 4383
         },
         {
            "faces": [],
            "height": 3319,
            "name": "DSCF4376.jpeg",
            "slug": "dscf4376-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4376-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4376-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4376-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4376-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4376-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4376-jpeg.jpeg",
            "width": 4978
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4377.jpeg",
            "slug": "dscf4377-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4377-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4377-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4377-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4377-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4377-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4377-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4385.jpeg",
            "slug": "dscf4385-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4385-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4385-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4385-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4385-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4385-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4385-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 5280,
            "name": "DSCF4395.jpeg",
            "slug": "dscf4395-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1599x2400_dscf4395-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x1536_dscf4395-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1599x2400_dscf4395-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x750_dscf4395-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/799x1200_dscf4395-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x750_dscf4395-jpeg.jpeg",
            "width": 3520
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF4398.jpeg",
            "slug": "dscf4398-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x2400_dscf4398-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x1536_dscf4398-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x2400_dscf4398-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x750_dscf4398-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x1200_dscf4398-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x750_dscf4398-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3303,
            "name": "DSCF4413.jpeg",
            "slug": "dscf4413-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4413-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4413-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4413-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4413-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4413-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4413-jpeg.jpeg",
            "width": 4954
         },
         {
            "faces": [],
            "height": 3360,
            "name": "DSCF4431.jpeg",
            "slug": "dscf4431-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4431-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4431-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4431-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4431-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4431-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4431-jpeg.jpeg",
            "width": 5040
         },
         {
            "faces": [],
            "height": 5008,
            "name": "DSCF4435.jpeg",
            "slug": "dscf4435-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x2399_dscf4435-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x1535_dscf4435-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x2399_dscf4435-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x749_dscf4435-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x1199_dscf4435-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x749_dscf4435-jpeg.jpeg",
            "width": 3340
         },
         {
            "faces": [],
            "height": 3547,
            "name": "DSCF4442.jpeg",
            "slug": "dscf4442-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4442-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4442-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4442-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4442-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4442-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4442-jpeg.jpeg",
            "width": 5320
         },
         {
            "faces": [],
            "height": 2761,
            "name": "DSCF4448.jpeg",
            "slug": "dscf4448-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4448-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4448-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4448-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4448-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4448-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4448-jpeg.jpeg",
            "width": 4141
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4454.jpeg",
            "slug": "dscf4454-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4454-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4454-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4454-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4454-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4454-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4454-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 2735,
            "name": "DSCF4458.jpeg",
            "slug": "dscf4458-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4458-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4458-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4458-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4458-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4458-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4458-jpeg.jpeg",
            "width": 4104
         },
         {
            "faces": [],
            "height": 2873,
            "name": "DSCF4462.jpeg",
            "slug": "dscf4462-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4462-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4462-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4462-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4462-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4462-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4462-jpeg.jpeg",
            "width": 4310
         },
         {
            "faces": [],
            "height": 2634,
            "name": "DSCF4472.jpeg",
            "slug": "dscf4472-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4472-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4472-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4472-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4472-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4472-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4472-jpeg.jpeg",
            "width": 3951
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4482.jpeg",
            "slug": "dscf4482-jpeg",
            "src": "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4482-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1024x682_dscf4482-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/1600x1066_dscf4482-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4482-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-22-react-advanced/800x533_dscf4482-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4482-jpeg.jpeg",
            "width": 6000
         }
      ],
      "slug": "2021-10-22-react-advanced",
      "src": "/static/_gallery/albums/2021-10-22-react-advanced/500x333_dscf4373-jpeg.jpeg"
   },
   "2021-10-23-winkworth-arboretum": {
      "name": "2021-10-23 Winkworth Arboretum",
      "photos": [
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF4499.jpeg",
            "slug": "dscf4499-jpeg",
            "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x900_dscf4499-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1024x576_dscf4499-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x900_dscf4499-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x281_dscf4499-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/800x450_dscf4499-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x281_dscf4499-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4503.jpeg",
            "slug": "dscf4503-jpeg",
            "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x1200_dscf4503-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1024x768_dscf4503-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x1200_dscf4503-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x375_dscf4503-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/800x600_dscf4503-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x375_dscf4503-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 3175,
            "name": "DSCF4507.jpeg",
            "slug": "dscf4507-jpeg",
            "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x900_dscf4507-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1024x576_dscf4507-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x900_dscf4507-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x281_dscf4507-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/800x450_dscf4507-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x281_dscf4507-jpeg.jpeg",
            "width": 5644
         },
         {
            "faces": [],
            "height": 3322,
            "name": "DSCF4515.16x9.jpeg",
            "slug": "dscf4515-16x9-jpeg",
            "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x899_dscf4515-16x9-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1024x575_dscf4515-16x9-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x899_dscf4515-16x9-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x281_dscf4515-16x9-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/800x449_dscf4515-16x9-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x281_dscf4515-16x9-jpeg.jpeg",
            "width": 5906
         },
         {
            "faces": [],
            "height": 2529,
            "name": "DSCF4515.jpeg",
            "slug": "dscf4515-jpeg",
            "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x685_dscf4515-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1024x438_dscf4515-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x685_dscf4515-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x214_dscf4515-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/800x342_dscf4515-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x214_dscf4515-jpeg.jpeg",
            "width": 5902
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4515.original.jpeg",
            "slug": "dscf4515-original-jpeg",
            "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x1066_dscf4515-original-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1024x682_dscf4515-original-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x1066_dscf4515-original-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x333_dscf4515-original-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/800x533_dscf4515-original-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x333_dscf4515-original-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4518.jpeg",
            "slug": "dscf4518-jpeg",
            "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x1200_dscf4518-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1024x768_dscf4518-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x1200_dscf4518-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x375_dscf4518-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/800x600_dscf4518-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x375_dscf4518-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF4544.jpeg",
            "slug": "dscf4544-jpeg",
            "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x2133_dscf4544-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1024x1365_dscf4544-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x2133_dscf4544-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x666_dscf4544-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/800x1066_dscf4544-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x666_dscf4544-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4797,
            "name": "DSCF4577.jpeg",
            "slug": "dscf4577-jpeg",
            "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x2133_dscf4577-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1024x1365_dscf4577-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x2133_dscf4577-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x666_dscf4577-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/800x1066_dscf4577-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x666_dscf4577-jpeg.jpeg",
            "width": 3598
         },
         {
            "faces": [],
            "height": 1749,
            "name": "DSCF4584.jpeg",
            "slug": "dscf4584-jpeg",
            "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x900_dscf4584-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1024x576_dscf4584-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x900_dscf4584-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x281_dscf4584-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/800x450_dscf4584-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x281_dscf4584-jpeg.jpeg",
            "width": 3109
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4602.jpeg",
            "slug": "dscf4602-jpeg",
            "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x1066_dscf4602-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1024x682_dscf4602-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x1066_dscf4602-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x333_dscf4602-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/800x533_dscf4602-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x333_dscf4602-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4940,
            "name": "DSCF4618.jpeg",
            "slug": "dscf4618-jpeg",
            "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x2133_dscf4618-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1024x1365_dscf4618-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/1600x2133_dscf4618-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x666_dscf4618-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-23-winkworth-arboretum/800x1066_dscf4618-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x666_dscf4618-jpeg.jpeg",
            "width": 3705
         }
      ],
      "slug": "2021-10-23-winkworth-arboretum",
      "src": "/static/_gallery/albums/2021-10-23-winkworth-arboretum/500x281_dscf4499-jpeg.jpeg"
   },
   "2021-10-24-hawley-meadows": {
      "name": "2021-10-24 Hawley Meadows",
      "photos": [
         {
            "faces": [],
            "height": 1987,
            "name": "DSCF4647.jpeg",
            "slug": "dscf4647-jpeg",
            "src": "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1199_dscf4647-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1024x767_dscf4647-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1199_dscf4647-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/500x374_dscf4647-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/800x599_dscf4647-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-24-hawley-meadows/500x374_dscf4647-jpeg.jpeg",
            "width": 2651
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4666.jpeg",
            "slug": "dscf4666-jpeg",
            "src": "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x2133_dscf4666-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1024x1365_dscf4666-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x2133_dscf4666-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/500x666_dscf4666-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/800x1066_dscf4666-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-24-hawley-meadows/500x666_dscf4666-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4669.jpeg",
            "slug": "dscf4669-jpeg",
            "src": "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1200_dscf4669-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1024x768_dscf4669-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1200_dscf4669-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/500x375_dscf4669-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/800x600_dscf4669-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-24-hawley-meadows/500x375_dscf4669-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4675.jpeg",
            "slug": "dscf4675-jpeg",
            "src": "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1066_dscf4675-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1024x682_dscf4675-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1066_dscf4675-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/500x333_dscf4675-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/800x533_dscf4675-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-24-hawley-meadows/500x333_dscf4675-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4712.jpeg",
            "slug": "dscf4712-jpeg",
            "src": "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1066_dscf4712-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1024x682_dscf4712-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1066_dscf4712-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/500x333_dscf4712-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/800x533_dscf4712-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-24-hawley-meadows/500x333_dscf4712-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4731.jpeg",
            "slug": "dscf4731-jpeg",
            "src": "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x2133_dscf4731-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1024x1365_dscf4731-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x2133_dscf4731-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/500x666_dscf4731-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/800x1066_dscf4731-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-24-hawley-meadows/500x666_dscf4731-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 5066,
            "name": "DSCF4735.jpeg",
            "slug": "dscf4735-jpeg",
            "src": "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x2133_dscf4735-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1024x1365_dscf4735-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x2133_dscf4735-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/500x666_dscf4735-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/800x1066_dscf4735-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-24-hawley-meadows/500x666_dscf4735-jpeg.jpeg",
            "width": 3800
         },
         {
            "faces": [],
            "height": 3466,
            "name": "DSCF4746.jpeg",
            "slug": "dscf4746-jpeg",
            "src": "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1600_dscf4746-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1024x1024_dscf4746-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1600_dscf4746-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/500x500_dscf4746-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/800x800_dscf4746-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-24-hawley-meadows/500x500_dscf4746-jpeg.jpeg",
            "width": 3466
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4761.jpeg",
            "slug": "dscf4761-jpeg",
            "src": "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1066_dscf4761-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1024x682_dscf4761-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1066_dscf4761-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/500x333_dscf4761-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/800x533_dscf4761-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-24-hawley-meadows/500x333_dscf4761-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4777.jpeg",
            "slug": "dscf4777-jpeg",
            "src": "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1066_dscf4777-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1024x682_dscf4777-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1066_dscf4777-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/500x333_dscf4777-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/800x533_dscf4777-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-24-hawley-meadows/500x333_dscf4777-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4788.jpeg",
            "slug": "dscf4788-jpeg",
            "src": "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1066_dscf4788-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1024x682_dscf4788-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/1600x1066_dscf4788-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/500x333_dscf4788-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-10-24-hawley-meadows/800x533_dscf4788-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-10-24-hawley-meadows/500x333_dscf4788-jpeg.jpeg",
            "width": 6000
         }
      ],
      "slug": "2021-10-24-hawley-meadows",
      "src": "/static/_gallery/albums/2021-10-24-hawley-meadows/500x374_dscf4647-jpeg.jpeg"
   },
   "2021-11-06-thames-path": {
      "name": "2021-11-06 Thames Path",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4824.jpeg",
            "slug": "dscf4824-jpeg",
            "src": "/static/_gallery/albums/2021-11-06-thames-path/1600x1066_dscf4824-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1024x682_dscf4824-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1600x1066_dscf4824-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/500x333_dscf4824-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/800x533_dscf4824-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-06-thames-path/500x333_dscf4824-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 2571,
            "name": "DSCF4856.jpeg",
            "slug": "dscf4856-jpeg",
            "src": "/static/_gallery/albums/2021-11-06-thames-path/1600x685_dscf4856-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1024x438_dscf4856-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1600x685_dscf4856-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/500x214_dscf4856-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/800x342_dscf4856-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-06-thames-path/500x214_dscf4856-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4874.jpeg",
            "slug": "dscf4874-jpeg",
            "src": "/static/_gallery/albums/2021-11-06-thames-path/1600x1066_dscf4874-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1024x682_dscf4874-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1600x1066_dscf4874-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/500x333_dscf4874-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/800x533_dscf4874-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-06-thames-path/500x333_dscf4874-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF4889.jpeg",
            "slug": "dscf4889-jpeg",
            "src": "/static/_gallery/albums/2021-11-06-thames-path/1600x2400_dscf4889-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1024x1536_dscf4889-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1600x2400_dscf4889-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/500x750_dscf4889-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/800x1200_dscf4889-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-06-thames-path/500x750_dscf4889-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF4905.jpeg",
            "slug": "dscf4905-jpeg",
            "src": "/static/_gallery/albums/2021-11-06-thames-path/1600x2400_dscf4905-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1024x1536_dscf4905-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1600x2400_dscf4905-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/500x750_dscf4905-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/800x1200_dscf4905-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-06-thames-path/500x750_dscf4905-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF4912.jpeg",
            "slug": "dscf4912-jpeg",
            "src": "/static/_gallery/albums/2021-11-06-thames-path/1600x2133_dscf4912-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1024x1365_dscf4912-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1600x2133_dscf4912-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/500x666_dscf4912-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/800x1066_dscf4912-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-06-thames-path/500x666_dscf4912-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5209,
            "name": "DSCF4925.jpeg",
            "slug": "dscf4925-jpeg",
            "src": "/static/_gallery/albums/2021-11-06-thames-path/1600x2133_dscf4925-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1024x1365_dscf4925-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1600x2133_dscf4925-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/500x666_dscf4925-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/800x1066_dscf4925-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-06-thames-path/500x666_dscf4925-jpeg.jpeg",
            "width": 3907
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4931.jpeg",
            "slug": "dscf4931-jpeg",
            "src": "/static/_gallery/albums/2021-11-06-thames-path/1600x1200_dscf4931-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1024x768_dscf4931-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1600x1200_dscf4931-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/500x375_dscf4931-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/800x600_dscf4931-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-06-thames-path/500x375_dscf4931-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 3318,
            "name": "DSCF4934.jpeg",
            "slug": "dscf4934-jpeg",
            "src": "/static/_gallery/albums/2021-11-06-thames-path/1600x900_dscf4934-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1024x576_dscf4934-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1600x900_dscf4934-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/500x281_dscf4934-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/800x450_dscf4934-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-06-thames-path/500x281_dscf4934-jpeg.jpeg",
            "width": 5898
         },
         {
            "faces": [],
            "height": 4599,
            "name": "DSCF4938.jpeg",
            "slug": "dscf4938-jpeg",
            "src": "/static/_gallery/albums/2021-11-06-thames-path/1600x2133_dscf4938-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1024x1365_dscf4938-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1600x2133_dscf4938-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/500x666_dscf4938-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/800x1066_dscf4938-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-06-thames-path/500x666_dscf4938-jpeg.jpeg",
            "width": 3449
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF4986.jpeg",
            "slug": "dscf4986-jpeg",
            "src": "/static/_gallery/albums/2021-11-06-thames-path/1600x2400_dscf4986-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1024x1536_dscf4986-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1600x2400_dscf4986-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/500x750_dscf4986-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/800x1200_dscf4986-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-06-thames-path/500x750_dscf4986-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF4995.jpeg",
            "slug": "dscf4995-jpeg",
            "src": "/static/_gallery/albums/2021-11-06-thames-path/1600x2400_dscf4995-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1024x1536_dscf4995-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/1600x2400_dscf4995-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/500x750_dscf4995-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-06-thames-path/800x1200_dscf4995-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-06-thames-path/500x750_dscf4995-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2021-11-06-thames-path",
      "src": "/static/_gallery/albums/2021-11-06-thames-path/500x333_dscf4824-jpeg.jpeg"
   },
   "2021-11-15-goldfinches": {
      "name": "2021-11-15 Goldfinches",
      "photos": [
         {
            "faces": [],
            "height": 3864,
            "name": "DSCF5014.jpeg",
            "slug": "dscf5014-jpeg",
            "src": "/static/_gallery/albums/2021-11-15-goldfinches/1600x2133_dscf5014-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-15-goldfinches/1024x1365_dscf5014-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-15-goldfinches/1600x2133_dscf5014-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-15-goldfinches/500x666_dscf5014-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-15-goldfinches/800x1066_dscf5014-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-15-goldfinches/500x666_dscf5014-jpeg.jpeg",
            "width": 2898
         }
      ],
      "slug": "2021-11-15-goldfinches",
      "src": "/static/_gallery/albums/2021-11-15-goldfinches/500x666_dscf5014-jpeg.jpeg"
   },
   "2021-11-20-discover-dogs": {
      "name": "2021-11-20 Discover Dogs",
      "photos": [
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF5040.jpeg",
            "slug": "dscf5040-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5040-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1536_dscf5040-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5040-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5040-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1200_dscf5040-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5040-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF5053.jpeg",
            "slug": "dscf5053-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5053-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1536_dscf5053-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5053-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5053-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1200_dscf5053-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5053-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF5087.jpeg",
            "slug": "dscf5087-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5087-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1536_dscf5087-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5087-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5087-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1200_dscf5087-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5087-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5105.jpeg",
            "slug": "dscf5105-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5105-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x682_dscf5105-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5105-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5105-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x533_dscf5105-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5105-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 2247,
            "name": "DSCF5227.jpeg",
            "slug": "dscf5227-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x685_dscf5227-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x438_dscf5227-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x685_dscf5227-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/499x214_dscf5227-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x342_dscf5227-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/499x214_dscf5227-jpeg.jpeg",
            "width": 5243
         },
         {
            "faces": [],
            "height": 4694,
            "name": "DSCF5251.jpeg",
            "slug": "dscf5251-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2133_dscf5251-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1365_dscf5251-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2133_dscf5251-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x666_dscf5251-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1066_dscf5251-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x666_dscf5251-jpeg.jpeg",
            "width": 3521
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF5283.jpeg",
            "slug": "dscf5283-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5283-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1536_dscf5283-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5283-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5283-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1200_dscf5283-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5283-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5289.jpeg",
            "slug": "dscf5289-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2133_dscf5289-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1365_dscf5289-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2133_dscf5289-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x666_dscf5289-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1066_dscf5289-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x666_dscf5289-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5296.jpeg",
            "slug": "dscf5296-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5296-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x682_dscf5296-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5296-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5296-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x533_dscf5296-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5296-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5346.jpeg",
            "slug": "dscf5346-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5346-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x682_dscf5346-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5346-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5346-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x533_dscf5346-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5346-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 3440,
            "name": "DSCF5399.jpeg",
            "slug": "dscf5399-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1200_dscf5399-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x768_dscf5399-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1200_dscf5399-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/499x375_dscf5399-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x600_dscf5399-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/499x375_dscf5399-jpeg.jpeg",
            "width": 4586
         },
         {
            "faces": [],
            "height": 3530,
            "name": "DSCF5407.jpeg",
            "slug": "dscf5407-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1200_dscf5407-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x768_dscf5407-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1200_dscf5407-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/499x375_dscf5407-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x600_dscf5407-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/499x375_dscf5407-jpeg.jpeg",
            "width": 4706
         },
         {
            "faces": [],
            "height": 2927,
            "name": "DSCF5429.jpeg",
            "slug": "dscf5429-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1599x1200_dscf5429-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x768_dscf5429-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1599x1200_dscf5429-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x375_dscf5429-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/799x600_dscf5429-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x375_dscf5429-jpeg.jpeg",
            "width": 3902
         },
         {
            "faces": [],
            "height": 3462,
            "name": "DSCF5433.jpeg",
            "slug": "dscf5433-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1200_dscf5433-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x768_dscf5433-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1200_dscf5433-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x375_dscf5433-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x600_dscf5433-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x375_dscf5433-jpeg.jpeg",
            "width": 4616
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5456.jpeg",
            "slug": "dscf5456-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5456-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x682_dscf5456-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5456-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5456-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x533_dscf5456-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5456-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF5482.jpeg",
            "slug": "dscf5482-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5482-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1536_dscf5482-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5482-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5482-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1200_dscf5482-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5482-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF5506.jpeg",
            "slug": "dscf5506-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2133_dscf5506-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1365_dscf5506-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2133_dscf5506-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x666_dscf5506-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1066_dscf5506-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x666_dscf5506-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF5532.jpeg",
            "slug": "dscf5532-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5532-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1536_dscf5532-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5532-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5532-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1200_dscf5532-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5532-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5561.jpeg",
            "slug": "dscf5561-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5561-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x682_dscf5561-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5561-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5561-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x533_dscf5561-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5561-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5582.jpeg",
            "slug": "dscf5582-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5582-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x682_dscf5582-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5582-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5582-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x533_dscf5582-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5582-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF5609.jpeg",
            "slug": "dscf5609-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5609-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1536_dscf5609-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5609-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5609-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1200_dscf5609-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5609-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF5645.jpeg",
            "slug": "dscf5645-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5645-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1536_dscf5645-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2400_dscf5645-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5645-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1200_dscf5645-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5645-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4608,
            "name": "DSCF5655.jpeg",
            "slug": "dscf5655-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2133_dscf5655-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1365_dscf5655-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2133_dscf5655-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x666_dscf5655-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1066_dscf5655-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x666_dscf5655-jpeg.jpeg",
            "width": 3456
         },
         {
            "faces": [],
            "height": 5177,
            "name": "DSCF5704.jpeg",
            "slug": "dscf5704-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2133_dscf5704-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x1365_dscf5704-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x2133_dscf5704-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x666_dscf5704-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x1066_dscf5704-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x666_dscf5704-jpeg.jpeg",
            "width": 3883
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5855.jpeg",
            "slug": "dscf5855-jpeg",
            "src": "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5855-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1024x682_dscf5855-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/1600x1066_dscf5855-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5855-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-11-20-discover-dogs/800x533_dscf5855-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-11-20-discover-dogs/500x333_dscf5855-jpeg.jpeg",
            "width": 6000
         }
      ],
      "slug": "2021-11-20-discover-dogs",
      "src": "/static/_gallery/albums/2021-11-20-discover-dogs/500x750_dscf5040-jpeg.jpeg"
   },
   "2021-12-05-dinton-pastures-with-katy": {
      "name": "2021-12-05 Dinton Pastures with Katy",
      "photos": [
         {
            "faces": [],
            "height": 2911,
            "name": "DSCF5936.jpeg",
            "slug": "dscf5936-jpeg",
            "src": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x1200_dscf5936-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1024x768_dscf5936-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x1200_dscf5936-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x375_dscf5936-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/800x600_dscf5936-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x375_dscf5936-jpeg.jpeg",
            "width": 3881
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5948.jpeg",
            "slug": "dscf5948-jpeg",
            "src": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x1066_dscf5948-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1024x682_dscf5948-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x1066_dscf5948-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x333_dscf5948-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/800x533_dscf5948-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x333_dscf5948-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 5077,
            "name": "DSCF5954.jpeg",
            "slug": "dscf5954-jpeg",
            "src": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x2133_dscf5954-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1024x1365_dscf5954-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x2133_dscf5954-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x666_dscf5954-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/800x1066_dscf5954-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x666_dscf5954-jpeg.jpeg",
            "width": 3808
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5959.jpeg",
            "slug": "dscf5959-jpeg",
            "src": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x1066_dscf5959-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1024x682_dscf5959-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x1066_dscf5959-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x333_dscf5959-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/800x533_dscf5959-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x333_dscf5959-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF5974.jpeg",
            "slug": "dscf5974-jpeg",
            "src": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x900_dscf5974-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1024x576_dscf5974-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x900_dscf5974-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x281_dscf5974-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/800x450_dscf5974-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x281_dscf5974-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF6002.jpeg",
            "slug": "dscf6002-jpeg",
            "src": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x2133_dscf6002-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1024x1365_dscf6002-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x2133_dscf6002-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x666_dscf6002-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/800x1066_dscf6002-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x666_dscf6002-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3478,
            "name": "DSCF6030.jpeg",
            "slug": "dscf6030-jpeg",
            "src": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x1200_dscf6030-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1024x768_dscf6030-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x1200_dscf6030-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x375_dscf6030-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/800x600_dscf6030-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x375_dscf6030-jpeg.jpeg",
            "width": 4637
         },
         {
            "faces": [],
            "height": 2571,
            "name": "DSCF6064.jpeg",
            "slug": "dscf6064-jpeg",
            "src": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x685_dscf6064-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1024x438_dscf6064-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x685_dscf6064-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x214_dscf6064-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/800x342_dscf6064-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x214_dscf6064-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF6079.jpeg",
            "slug": "dscf6079-jpeg",
            "src": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x900_dscf6079-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1024x576_dscf6079-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x900_dscf6079-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x281_dscf6079-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/800x450_dscf6079-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x281_dscf6079-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF6086.jpeg",
            "slug": "dscf6086-jpeg",
            "src": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x900_dscf6086-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1024x576_dscf6086-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x900_dscf6086-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x281_dscf6086-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/800x450_dscf6086-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x281_dscf6086-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6126.jpeg",
            "slug": "dscf6126-jpeg",
            "src": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x1066_dscf6126-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1024x682_dscf6126-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/1600x1066_dscf6126-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x333_dscf6126-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/800x533_dscf6126-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x333_dscf6126-jpeg.jpeg",
            "width": 6000
         }
      ],
      "slug": "2021-12-05-dinton-pastures-with-katy",
      "src": "/static/_gallery/albums/2021-12-05-dinton-pastures-with-katy/500x375_dscf5936-jpeg.jpeg"
   },
   "2021-12-10-town": {
      "name": "2021-12-10 Town",
      "photos": [
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF6138.jpeg",
            "slug": "dscf6138-jpeg",
            "src": "/static/_gallery/albums/2021-12-10-town/1600x2133_dscf6138-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-10-town/1024x1365_dscf6138-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-10-town/1600x2133_dscf6138-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-10-town/500x666_dscf6138-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-10-town/800x1066_dscf6138-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-10-town/500x666_dscf6138-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2021-12-10-town",
      "src": "/static/_gallery/albums/2021-12-10-town/500x666_dscf6138-jpeg.jpeg"
   },
   "2021-12-19-edinburgh": {
      "name": "2021-12-19 Edinburgh",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6149.jpeg",
            "slug": "dscf6149-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6149-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6149-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6149-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6149-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6149-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6149-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4740,
            "name": "DSCF6157.jpeg",
            "slug": "dscf6157-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x2133_dscf6157-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x1365_dscf6157-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x2133_dscf6157-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x666_dscf6157-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x1066_dscf6157-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x666_dscf6157-jpeg.jpeg",
            "width": 3555
         },
         {
            "faces": [],
            "height": 3362,
            "name": "DSCF6159.jpeg",
            "slug": "dscf6159-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1200_dscf6159-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x768_dscf6159-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1200_dscf6159-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x375_dscf6159-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x600_dscf6159-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x375_dscf6159-jpeg.jpeg",
            "width": 4482
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6234.jpeg",
            "slug": "dscf6234-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6234-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6234-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6234-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6234-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6234-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6234-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4889,
            "name": "DSCF6246.jpeg",
            "slug": "dscf6246-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x2133_dscf6246-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x1365_dscf6246-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x2133_dscf6246-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x666_dscf6246-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x1066_dscf6246-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x666_dscf6246-jpeg.jpeg",
            "width": 3667
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6251.jpeg",
            "slug": "dscf6251-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6251-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6251-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6251-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6251-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6251-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6251-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6265.jpeg",
            "slug": "dscf6265-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6265-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6265-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6265-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6265-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6265-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6265-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6274.jpeg",
            "slug": "dscf6274-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6274-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6274-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6274-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6274-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6274-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6274-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF6295.jpeg",
            "slug": "dscf6295-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x2133_dscf6295-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x1365_dscf6295-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x2133_dscf6295-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x666_dscf6295-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x1066_dscf6295-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x666_dscf6295-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF6303.jpeg",
            "slug": "dscf6303-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x2133_dscf6303-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x1365_dscf6303-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x2133_dscf6303-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x666_dscf6303-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x1066_dscf6303-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x666_dscf6303-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6314.jpeg",
            "slug": "dscf6314-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6314-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6314-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6314-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6314-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6314-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6314-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6318.jpeg",
            "slug": "dscf6318-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x2400_dscf6318-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x1536_dscf6318-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x2400_dscf6318-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x750_dscf6318-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x1200_dscf6318-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x750_dscf6318-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6331.jpeg",
            "slug": "dscf6331-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6331-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6331-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6331-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6331-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6331-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6331-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6342.jpeg",
            "slug": "dscf6342-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6342-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6342-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6342-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6342-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6342-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6342-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6353.jpeg",
            "slug": "dscf6353-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6353-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6353-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6353-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6353-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6353-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6353-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF6363.jpeg",
            "slug": "dscf6363-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x900_dscf6363-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x576_dscf6363-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x900_dscf6363-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x281_dscf6363-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x450_dscf6363-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x281_dscf6363-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6368.jpeg",
            "slug": "dscf6368-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6368-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6368-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6368-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6368-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6368-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6368-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6372.jpeg",
            "slug": "dscf6372-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x2400_dscf6372-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x1536_dscf6372-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x2400_dscf6372-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x750_dscf6372-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x1200_dscf6372-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x750_dscf6372-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6377.jpeg",
            "slug": "dscf6377-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1200_dscf6377-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x768_dscf6377-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1200_dscf6377-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x375_dscf6377-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x600_dscf6377-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x375_dscf6377-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6389.jpeg",
            "slug": "dscf6389-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6389-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6389-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6389-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6389-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6389-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6389-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF6410.jpeg",
            "slug": "dscf6410-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x900_dscf6410-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x576_dscf6410-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x900_dscf6410-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x281_dscf6410-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x450_dscf6410-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x281_dscf6410-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6433 copy.jpeg",
            "slug": "dscf6433-copy-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x2000_dscf6433-copy-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x1280_dscf6433-copy-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x2000_dscf6433-copy-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x625_dscf6433-copy-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x1000_dscf6433-copy-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x625_dscf6433-copy-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6433.jpeg",
            "slug": "dscf6433-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6433-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6433-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6433-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6433-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6433-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6433-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF6450.jpeg",
            "slug": "dscf6450-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x2133_dscf6450-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x1365_dscf6450-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x2133_dscf6450-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x666_dscf6450-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x1066_dscf6450-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x666_dscf6450-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF6453.jpeg",
            "slug": "dscf6453-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x2133_dscf6453-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x1365_dscf6453-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x2133_dscf6453-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x666_dscf6453-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x1066_dscf6453-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x666_dscf6453-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6482.jpeg",
            "slug": "dscf6482-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6482-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6482-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6482-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6482-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6482-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6482-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6531.jpeg",
            "slug": "dscf6531-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6531-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6531-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6531-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6531-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6531-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6531-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6540.jpeg",
            "slug": "dscf6540-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1200_dscf6540-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x768_dscf6540-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1200_dscf6540-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x375_dscf6540-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x600_dscf6540-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x375_dscf6540-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6551.jpeg",
            "slug": "dscf6551-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x2400_dscf6551-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x1536_dscf6551-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x2400_dscf6551-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x750_dscf6551-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x1200_dscf6551-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x750_dscf6551-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6556.jpeg",
            "slug": "dscf6556-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6556-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6556-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6556-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6556-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6556-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6556-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6571.jpeg",
            "slug": "dscf6571-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x2400_dscf6571-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x1536_dscf6571-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x2400_dscf6571-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x750_dscf6571-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x1200_dscf6571-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x750_dscf6571-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6587.jpeg",
            "slug": "dscf6587-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x2400_dscf6587-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x1536_dscf6587-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x2400_dscf6587-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x750_dscf6587-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x1200_dscf6587-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x750_dscf6587-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6594.jpeg",
            "slug": "dscf6594-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6594-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6594-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6594-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6594-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6594-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6594-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6614.jpeg",
            "slug": "dscf6614-jpeg",
            "src": "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6614-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1024x682_dscf6614-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/1600x1066_dscf6614-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6614-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-19-edinburgh/800x533_dscf6614-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6614-jpeg.jpeg",
            "width": 6000
         }
      ],
      "slug": "2021-12-19-edinburgh",
      "src": "/static/_gallery/albums/2021-12-19-edinburgh/500x333_dscf6149-jpeg.jpeg"
   },
   "2021-12-22-birds": {
      "name": "2021-12-22 Birds",
      "photos": [
         {
            "faces": [],
            "height": 4186,
            "name": "DSCF6733.jpeg",
            "slug": "dscf6733-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-birds/1600x2132_dscf6733-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-birds/1024x1365_dscf6733-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-birds/1600x2132_dscf6733-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-birds/500x666_dscf6733-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-birds/800x1066_dscf6733-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-birds/500x666_dscf6733-jpeg.jpeg",
            "width": 3140
         },
         {
            "faces": [],
            "height": 3778,
            "name": "DSCF6744.jpeg",
            "slug": "dscf6744-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-birds/1600x2132_dscf6744-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-birds/1024x1365_dscf6744-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-birds/1600x2132_dscf6744-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-birds/500x666_dscf6744-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-birds/800x1066_dscf6744-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-birds/500x666_dscf6744-jpeg.jpeg",
            "width": 2834
         },
         {
            "faces": [],
            "height": 4086,
            "name": "DSCF6746.jpeg",
            "slug": "dscf6746-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-birds/1600x2132_dscf6746-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-birds/1024x1365_dscf6746-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-birds/1600x2132_dscf6746-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-birds/500x666_dscf6746-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-birds/800x1066_dscf6746-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-birds/500x666_dscf6746-jpeg.jpeg",
            "width": 3065
         },
         {
            "faces": [],
            "height": 3581,
            "name": "DSCF6750.jpeg",
            "slug": "dscf6750-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-birds/1600x1200_dscf6750-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-birds/1024x768_dscf6750-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-birds/1600x1200_dscf6750-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-birds/500x375_dscf6750-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-birds/800x600_dscf6750-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-birds/500x375_dscf6750-jpeg.jpeg",
            "width": 4774
         }
      ],
      "slug": "2021-12-22-birds",
      "src": "/static/_gallery/albums/2021-12-22-birds/500x666_dscf6733-jpeg.jpeg"
   },
   "2021-12-22-california-country-park": {
      "name": "2021-12-22 California Country Park",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6664.jpeg",
            "slug": "dscf6664-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-california-country-park/1600x1200_dscf6664-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1024x768_dscf6664-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1600x1200_dscf6664-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/500x375_dscf6664-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/800x600_dscf6664-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-california-country-park/500x375_dscf6664-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 4976,
            "name": "DSCF6670.jpeg",
            "slug": "dscf6670-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-california-country-park/1600x2133_dscf6670-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1024x1365_dscf6670-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1600x2133_dscf6670-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/500x666_dscf6670-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/800x1066_dscf6670-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-california-country-park/500x666_dscf6670-jpeg.jpeg",
            "width": 3732
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF6681.jpeg",
            "slug": "dscf6681-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-california-country-park/1600x900_dscf6681-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1024x576_dscf6681-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1600x900_dscf6681-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/500x281_dscf6681-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/800x450_dscf6681-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-california-country-park/500x281_dscf6681-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6683.jpeg",
            "slug": "dscf6683-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-california-country-park/1600x1200_dscf6683-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1024x768_dscf6683-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1600x1200_dscf6683-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/500x375_dscf6683-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/800x600_dscf6683-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-california-country-park/500x375_dscf6683-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6697.jpeg",
            "slug": "dscf6697-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-california-country-park/1600x2400_dscf6697-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1024x1536_dscf6697-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1600x2400_dscf6697-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/500x750_dscf6697-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/800x1200_dscf6697-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-california-country-park/500x750_dscf6697-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF6702.jpeg",
            "slug": "dscf6702-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-california-country-park/1600x2133_dscf6702-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1024x1365_dscf6702-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1600x2133_dscf6702-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/500x666_dscf6702-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/800x1066_dscf6702-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-california-country-park/500x666_dscf6702-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4709,
            "name": "DSCF6709.jpeg",
            "slug": "dscf6709-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-california-country-park/1600x2133_dscf6709-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1024x1365_dscf6709-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1600x2133_dscf6709-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/500x666_dscf6709-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/800x1066_dscf6709-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-california-country-park/500x666_dscf6709-jpeg.jpeg",
            "width": 3532
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6710.jpeg",
            "slug": "dscf6710-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-california-country-park/1600x1200_dscf6710-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1024x768_dscf6710-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1600x1200_dscf6710-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/500x375_dscf6710-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/800x600_dscf6710-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-california-country-park/500x375_dscf6710-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6712.jpeg",
            "slug": "dscf6712-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-california-country-park/1600x1200_dscf6712-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1024x768_dscf6712-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1600x1200_dscf6712-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/500x375_dscf6712-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/800x600_dscf6712-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-california-country-park/500x375_dscf6712-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF6717.jpeg",
            "slug": "dscf6717-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-california-country-park/1600x2133_dscf6717-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1024x1365_dscf6717-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1600x2133_dscf6717-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/500x666_dscf6717-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/800x1066_dscf6717-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-california-country-park/500x666_dscf6717-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2637,
            "name": "DSCF6723.jpeg",
            "slug": "dscf6723-jpeg",
            "src": "/static/_gallery/albums/2021-12-22-california-country-park/1600x900_dscf6723-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1024x576_dscf6723-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/1600x900_dscf6723-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/500x281_dscf6723-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-22-california-country-park/800x450_dscf6723-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-22-california-country-park/500x281_dscf6723-jpeg.jpeg",
            "width": 4688
         }
      ],
      "slug": "2021-12-22-california-country-park",
      "src": "/static/_gallery/albums/2021-12-22-california-country-park/500x375_dscf6664-jpeg.jpeg"
   },
   "2021-12-23-maidensgrove": {
      "name": "2021-12-23 Maidensgrove",
      "photos": [
         {
            "faces": [],
            "height": 4852,
            "name": "DSCF6765.jpeg",
            "slug": "dscf6765-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2133_dscf6765-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x1365_dscf6765-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2133_dscf6765-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x666_dscf6765-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x1066_dscf6765-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x666_dscf6765-jpeg.jpeg",
            "width": 3639
         },
         {
            "faces": [],
            "height": 3088,
            "name": "DSCF6775.jpeg",
            "slug": "dscf6775-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x899_dscf6775-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x575_dscf6775-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x899_dscf6775-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x281_dscf6775-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x449_dscf6775-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x281_dscf6775-jpeg.jpeg",
            "width": 5490
         },
         {
            "faces": [],
            "height": 2882,
            "name": "DSCF6785.jpeg",
            "slug": "dscf6785-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x1200_dscf6785-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x768_dscf6785-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x1200_dscf6785-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x375_dscf6785-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x600_dscf6785-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x375_dscf6785-jpeg.jpeg",
            "width": 3842
         },
         {
            "faces": [],
            "height": 4394,
            "name": "DSCF6789.jpeg",
            "slug": "dscf6789-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2133_dscf6789-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x1365_dscf6789-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2133_dscf6789-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x666_dscf6789-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x1066_dscf6789-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x666_dscf6789-jpeg.jpeg",
            "width": 3296
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6797.jpeg",
            "slug": "dscf6797-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x1200_dscf6797-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x768_dscf6797-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x1200_dscf6797-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x375_dscf6797-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x600_dscf6797-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x375_dscf6797-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6807.jpeg",
            "slug": "dscf6807-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x1200_dscf6807-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x768_dscf6807-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x1200_dscf6807-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x375_dscf6807-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x600_dscf6807-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x375_dscf6807-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6808.jpeg",
            "slug": "dscf6808-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2400_dscf6808-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x1536_dscf6808-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2400_dscf6808-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x750_dscf6808-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x1200_dscf6808-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x750_dscf6808-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF6833.jpeg",
            "slug": "dscf6833-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x900_dscf6833-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x576_dscf6833-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x900_dscf6833-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x281_dscf6833-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x450_dscf6833-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x281_dscf6833-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6834.jpeg",
            "slug": "dscf6834-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2400_dscf6834-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x1536_dscf6834-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2400_dscf6834-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x750_dscf6834-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x1200_dscf6834-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x750_dscf6834-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6845.jpeg",
            "slug": "dscf6845-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2400_dscf6845-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x1536_dscf6845-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2400_dscf6845-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x750_dscf6845-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x1200_dscf6845-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x750_dscf6845-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6853.jpeg",
            "slug": "dscf6853-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x1600_dscf6853-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x1024_dscf6853-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x1600_dscf6853-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x500_dscf6853-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x800_dscf6853-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x500_dscf6853-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4608,
            "name": "DSCF6860.jpeg",
            "slug": "dscf6860-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2133_dscf6860-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x1365_dscf6860-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2133_dscf6860-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x666_dscf6860-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x1066_dscf6860-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x666_dscf6860-jpeg.jpeg",
            "width": 3456
         },
         {
            "faces": [],
            "height": 4723,
            "name": "DSCF6864.jpeg",
            "slug": "dscf6864-jpeg",
            "src": "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2133_dscf6864-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1024x1365_dscf6864-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/1600x2133_dscf6864-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/500x666_dscf6864-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-23-maidensgrove/800x1066_dscf6864-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-23-maidensgrove/500x666_dscf6864-jpeg.jpeg",
            "width": 3542
         }
      ],
      "slug": "2021-12-23-maidensgrove",
      "src": "/static/_gallery/albums/2021-12-23-maidensgrove/500x666_dscf6765-jpeg.jpeg"
   },
   "2021-12-26-goring-heath": {
      "name": "2021-12-26 Goring Heath",
      "photos": [
         {
            "faces": [],
            "height": 3601,
            "name": "DSCF6878.jpeg",
            "slug": "dscf6878-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x1200_dscf6878-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x768_dscf6878-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x1200_dscf6878-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x375_dscf6878-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x600_dscf6878-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x375_dscf6878-jpeg.jpeg",
            "width": 4801
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF6883.jpeg",
            "slug": "dscf6883-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x900_dscf6883-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x576_dscf6883-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x900_dscf6883-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x281_dscf6883-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x450_dscf6883-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x281_dscf6883-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 3665,
            "name": "DSCF6893.jpeg",
            "slug": "dscf6893-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x1199_dscf6893-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x767_dscf6893-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x1199_dscf6893-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x374_dscf6893-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x599_dscf6893-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x374_dscf6893-jpeg.jpeg",
            "width": 4887
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6914.jpeg",
            "slug": "dscf6914-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x1066_dscf6914-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x682_dscf6914-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x1066_dscf6914-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x333_dscf6914-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x533_dscf6914-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x333_dscf6914-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6926.jpeg",
            "slug": "dscf6926-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x2400_dscf6926-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x1536_dscf6926-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x2400_dscf6926-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x750_dscf6926-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x1200_dscf6926-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x750_dscf6926-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5073,
            "name": "DSCF6935.jpeg",
            "slug": "dscf6935-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x2133_dscf6935-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x1365_dscf6935-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x2133_dscf6935-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x666_dscf6935-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x1066_dscf6935-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x666_dscf6935-jpeg.jpeg",
            "width": 3805
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6938.jpeg",
            "slug": "dscf6938-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x2400_dscf6938-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x1536_dscf6938-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x2400_dscf6938-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x750_dscf6938-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x1200_dscf6938-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x750_dscf6938-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4645,
            "name": "DSCF6941.jpeg",
            "slug": "dscf6941-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1599x2133_dscf6941-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x1365_dscf6941-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1599x2133_dscf6941-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x666_dscf6941-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/799x1066_dscf6941-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x666_dscf6941-jpeg.jpeg",
            "width": 3484
         },
         {
            "faces": [],
            "height": 3966,
            "name": "DSCF6943.jpeg",
            "slug": "dscf6943-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x2132_dscf6943-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x1365_dscf6943-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x2132_dscf6943-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x666_dscf6943-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x1066_dscf6943-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x666_dscf6943-jpeg.jpeg",
            "width": 2975
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6955.jpeg",
            "slug": "dscf6955-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x2400_dscf6955-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x1536_dscf6955-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x2400_dscf6955-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x750_dscf6955-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x1200_dscf6955-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x750_dscf6955-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3902,
            "name": "DSCF6977.jpeg",
            "slug": "dscf6977-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x1599_dscf6977-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x1023_dscf6977-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x1599_dscf6977-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x499_dscf6977-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x799_dscf6977-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x499_dscf6977-jpeg.jpeg",
            "width": 3903
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF6983.jpeg",
            "slug": "dscf6983-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x2400_dscf6983-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x1536_dscf6983-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x2400_dscf6983-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x750_dscf6983-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x1200_dscf6983-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x750_dscf6983-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6991.jpeg",
            "slug": "dscf6991-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x1066_dscf6991-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x682_dscf6991-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x1066_dscf6991-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x333_dscf6991-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x533_dscf6991-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x333_dscf6991-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 2940,
            "name": "DSCF6994.jpeg",
            "slug": "dscf6994-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x900_dscf6994-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x576_dscf6994-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x900_dscf6994-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x281_dscf6994-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x450_dscf6994-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x281_dscf6994-jpeg.jpeg",
            "width": 5226
         },
         {
            "faces": [],
            "height": 3332,
            "name": "DSCF6995.jpeg",
            "slug": "dscf6995-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x899_dscf6995-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x575_dscf6995-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x899_dscf6995-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/499x281_dscf6995-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x449_dscf6995-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/499x281_dscf6995-jpeg.jpeg",
            "width": 5924
         },
         {
            "faces": [],
            "height": 4613,
            "name": "DSCF7004.jpeg",
            "slug": "dscf7004-jpeg",
            "src": "/static/_gallery/albums/2021-12-26-goring-heath/1600x2133_dscf7004-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1024x1365_dscf7004-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/1600x2133_dscf7004-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/500x666_dscf7004-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-26-goring-heath/800x1066_dscf7004-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-26-goring-heath/500x666_dscf7004-jpeg.jpeg",
            "width": 3460
         }
      ],
      "slug": "2021-12-26-goring-heath",
      "src": "/static/_gallery/albums/2021-12-26-goring-heath/500x375_dscf6878-jpeg.jpeg"
   },
   "2021-12-27-horseshoe-lake": {
      "name": "2021-12-27 Horseshoe Lake",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7086.jpeg",
            "slug": "dscf7086-jpeg",
            "src": "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7086-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1024x682_dscf7086-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7086-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7086-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/800x533_dscf7086-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7086-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7096.jpeg",
            "slug": "dscf7096-jpeg",
            "src": "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7096-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1024x682_dscf7096-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7096-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7096-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/800x533_dscf7096-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7096-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7124.jpeg",
            "slug": "dscf7124-jpeg",
            "src": "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7124-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1024x682_dscf7124-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7124-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7124-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/800x533_dscf7124-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7124-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 3760,
            "name": "DSCF7132.jpeg",
            "slug": "dscf7132-jpeg",
            "src": "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1200_dscf7132-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1024x768_dscf7132-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1200_dscf7132-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x375_dscf7132-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/800x600_dscf7132-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x375_dscf7132-jpeg.jpeg",
            "width": 5013
         },
         {
            "faces": [],
            "height": 3677,
            "name": "DSCF7165.jpeg",
            "slug": "dscf7165-jpeg",
            "src": "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1200_dscf7165-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1024x768_dscf7165-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1200_dscf7165-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x375_dscf7165-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/800x600_dscf7165-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x375_dscf7165-jpeg.jpeg",
            "width": 4902
         },
         {
            "faces": [],
            "height": 4743,
            "name": "DSCF7177.jpeg",
            "slug": "dscf7177-jpeg",
            "src": "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x2133_dscf7177-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1024x1365_dscf7177-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x2133_dscf7177-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x666_dscf7177-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/800x1066_dscf7177-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x666_dscf7177-jpeg.jpeg",
            "width": 3557
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7187.jpeg",
            "slug": "dscf7187-jpeg",
            "src": "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7187-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1024x682_dscf7187-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7187-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7187-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/800x533_dscf7187-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7187-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7199.jpeg",
            "slug": "dscf7199-jpeg",
            "src": "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7199-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1024x682_dscf7199-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7199-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7199-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/800x533_dscf7199-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7199-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7216.jpeg",
            "slug": "dscf7216-jpeg",
            "src": "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7216-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1024x682_dscf7216-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7216-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7216-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/800x533_dscf7216-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7216-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7243.jpeg",
            "slug": "dscf7243-jpeg",
            "src": "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7243-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1024x682_dscf7243-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x1066_dscf7243-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7243-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/800x533_dscf7243-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7243-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4843,
            "name": "DSCF7264.jpeg",
            "slug": "dscf7264-jpeg",
            "src": "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x2133_dscf7264-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1024x1365_dscf7264-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/1600x2133_dscf7264-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x666_dscf7264-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-27-horseshoe-lake/800x1066_dscf7264-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x666_dscf7264-jpeg.jpeg",
            "width": 3632
         }
      ],
      "slug": "2021-12-27-horseshoe-lake",
      "src": "/static/_gallery/albums/2021-12-27-horseshoe-lake/500x333_dscf7086-jpeg.jpeg"
   },
   "2021-12-28-caversham-with-kirsty": {
      "name": "2021-12-28 Caversham with Kirsty",
      "photos": [
         {
            "faces": [],
            "height": 3608,
            "name": "DSCF7358.jpeg",
            "slug": "dscf7358-jpeg",
            "src": "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1600x1200_dscf7358-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1024x768_dscf7358-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1600x1200_dscf7358-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/500x375_dscf7358-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/800x600_dscf7358-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/500x375_dscf7358-jpeg.jpeg",
            "width": 4810
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF7377.jpeg",
            "slug": "dscf7377-jpeg",
            "src": "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1600x2400_dscf7377-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1024x1536_dscf7377-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1600x2400_dscf7377-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/500x750_dscf7377-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/800x1200_dscf7377-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/500x750_dscf7377-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5006,
            "name": "DSCF7384.jpeg",
            "slug": "dscf7384-jpeg",
            "src": "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1600x2133_dscf7384-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1024x1365_dscf7384-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1600x2133_dscf7384-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/500x666_dscf7384-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/800x1066_dscf7384-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/500x666_dscf7384-jpeg.jpeg",
            "width": 3755
         },
         {
            "faces": [],
            "height": 3034,
            "name": "DSCF7393.jpeg",
            "slug": "dscf7393-jpeg",
            "src": "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1600x899_dscf7393-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1024x575_dscf7393-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1600x899_dscf7393-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/500x281_dscf7393-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/800x449_dscf7393-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/500x281_dscf7393-jpeg.jpeg",
            "width": 5394
         },
         {
            "faces": [],
            "height": 3032,
            "name": "DSCF7396.jpeg",
            "slug": "dscf7396-jpeg",
            "src": "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1600x900_dscf7396-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1024x576_dscf7396-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/1600x900_dscf7396-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/500x281_dscf7396-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/800x450_dscf7396-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/500x281_dscf7396-jpeg.jpeg",
            "width": 5390
         }
      ],
      "slug": "2021-12-28-caversham-with-kirsty",
      "src": "/static/_gallery/albums/2021-12-28-caversham-with-kirsty/500x375_dscf7358-jpeg.jpeg"
   },
   "2022-01-02-sonning-eye": {
      "name": "2022-01-02 Sonning Eye",
      "photos": [
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF7435.jpeg",
            "slug": "dscf7435-jpeg",
            "src": "/static/_gallery/albums/2022-01-02-sonning-eye/1600x2400_dscf7435-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1024x1536_dscf7435-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1600x2400_dscf7435-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/500x750_dscf7435-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/800x1200_dscf7435-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-02-sonning-eye/500x750_dscf7435-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF7449.jpeg",
            "slug": "dscf7449-jpeg",
            "src": "/static/_gallery/albums/2022-01-02-sonning-eye/1600x2400_dscf7449-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1024x1536_dscf7449-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1600x2400_dscf7449-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/500x750_dscf7449-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/800x1200_dscf7449-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-02-sonning-eye/500x750_dscf7449-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF7461.jpeg",
            "slug": "dscf7461-jpeg",
            "src": "/static/_gallery/albums/2022-01-02-sonning-eye/1600x2400_dscf7461-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1024x1536_dscf7461-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1600x2400_dscf7461-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/500x750_dscf7461-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/800x1200_dscf7461-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-02-sonning-eye/500x750_dscf7461-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF7484.jpeg",
            "slug": "dscf7484-jpeg",
            "src": "/static/_gallery/albums/2022-01-02-sonning-eye/1600x2400_dscf7484-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1024x1536_dscf7484-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1600x2400_dscf7484-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/500x750_dscf7484-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/800x1200_dscf7484-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-02-sonning-eye/500x750_dscf7484-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7488.jpeg",
            "slug": "dscf7488-jpeg",
            "src": "/static/_gallery/albums/2022-01-02-sonning-eye/1600x1066_dscf7488-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1024x682_dscf7488-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1600x1066_dscf7488-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/500x333_dscf7488-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/800x533_dscf7488-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-02-sonning-eye/500x333_dscf7488-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7494.jpeg",
            "slug": "dscf7494-jpeg",
            "src": "/static/_gallery/albums/2022-01-02-sonning-eye/1600x1066_dscf7494-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1024x682_dscf7494-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1600x1066_dscf7494-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/500x333_dscf7494-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/800x533_dscf7494-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-02-sonning-eye/500x333_dscf7494-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF7505.jpeg",
            "slug": "dscf7505-jpeg",
            "src": "/static/_gallery/albums/2022-01-02-sonning-eye/1600x2400_dscf7505-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1024x1536_dscf7505-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/1600x2400_dscf7505-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/500x750_dscf7505-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-02-sonning-eye/800x1200_dscf7505-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-02-sonning-eye/500x750_dscf7505-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-01-02-sonning-eye",
      "src": "/static/_gallery/albums/2022-01-02-sonning-eye/500x750_dscf7435-jpeg.jpeg"
   },
   "2022-01-15-california-country-park": {
      "name": "2022-01-15 California Country Park",
      "photos": [
         {
            "faces": [],
            "height": 3088,
            "name": "DSCF7568.jpeg",
            "slug": "dscf7568-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x899_dscf7568-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x575_dscf7568-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x899_dscf7568-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x281_dscf7568-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x449_dscf7568-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x281_dscf7568-jpeg.jpeg",
            "width": 5490
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7571.jpeg",
            "slug": "dscf7571-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7571-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x1365_dscf7571-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7571-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7571-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x1066_dscf7571-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7571-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF7586.jpeg",
            "slug": "dscf7586-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x900_dscf7586-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x576_dscf7586-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x900_dscf7586-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x281_dscf7586-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x450_dscf7586-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x281_dscf7586-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4834,
            "name": "DSCF7590.jpeg",
            "slug": "dscf7590-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7590-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x1365_dscf7590-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7590-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7590-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x1066_dscf7590-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7590-jpeg.jpeg",
            "width": 3626
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7597.jpeg",
            "slug": "dscf7597-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x1066_dscf7597-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x682_dscf7597-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x1066_dscf7597-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x333_dscf7597-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x533_dscf7597-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x333_dscf7597-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 3744,
            "name": "DSCF7603.jpeg",
            "slug": "dscf7603-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x1066_dscf7603-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x682_dscf7603-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x1066_dscf7603-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x333_dscf7603-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x533_dscf7603-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x333_dscf7603-jpeg.jpeg",
            "width": 5616
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7612.jpeg",
            "slug": "dscf7612-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7612-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x1365_dscf7612-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7612-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7612-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x1066_dscf7612-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7612-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF7621.jpeg",
            "slug": "dscf7621-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x2400_dscf7621-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x1536_dscf7621-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x2400_dscf7621-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x750_dscf7621-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x1200_dscf7621-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x750_dscf7621-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7623.jpeg",
            "slug": "dscf7623-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7623-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x1365_dscf7623-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7623-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7623-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x1066_dscf7623-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7623-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7625.jpeg",
            "slug": "dscf7625-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x1200_dscf7625-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x768_dscf7625-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x1200_dscf7625-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x375_dscf7625-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x600_dscf7625-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x375_dscf7625-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 3828,
            "name": "DSCF7626.jpeg",
            "slug": "dscf7626-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7626-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x1365_dscf7626-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7626-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7626-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x1066_dscf7626-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7626-jpeg.jpeg",
            "width": 2871
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF7634.jpeg",
            "slug": "dscf7634-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x900_dscf7634-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x576_dscf7634-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x900_dscf7634-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x281_dscf7634-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x450_dscf7634-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x281_dscf7634-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF7636.jpeg",
            "slug": "dscf7636-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x900_dscf7636-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x576_dscf7636-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x900_dscf7636-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x281_dscf7636-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x450_dscf7636-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x281_dscf7636-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 3947,
            "name": "DSCF7643.jpeg",
            "slug": "dscf7643-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7643-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x1365_dscf7643-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7643-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7643-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x1066_dscf7643-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7643-jpeg.jpeg",
            "width": 2960
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF7660.jpeg",
            "slug": "dscf7660-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x900_dscf7660-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x576_dscf7660-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x900_dscf7660-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x281_dscf7660-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x450_dscf7660-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x281_dscf7660-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7676.jpeg",
            "slug": "dscf7676-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x1600_dscf7676-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x1024_dscf7676-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x1600_dscf7676-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x500_dscf7676-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x800_dscf7676-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x500_dscf7676-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3505,
            "name": "DSCF7703.jpeg",
            "slug": "dscf7703-jpeg",
            "src": "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7703-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1024x1365_dscf7703-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/1600x2133_dscf7703-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7703-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-15-california-country-park/800x1066_dscf7703-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-15-california-country-park/500x666_dscf7703-jpeg.jpeg",
            "width": 2629
         }
      ],
      "slug": "2022-01-15-california-country-park",
      "src": "/static/_gallery/albums/2022-01-15-california-country-park/500x281_dscf7568-jpeg.jpeg"
   },
   "2022-01-17-wittenham-clumps": {
      "name": "2022-01-17 Wittenham Clumps",
      "photos": [
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF7709.jpeg",
            "slug": "dscf7709-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2400_dscf7709-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1536_dscf7709-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2400_dscf7709-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x750_dscf7709-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1200_dscf7709-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x750_dscf7709-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF7719.jpeg",
            "slug": "dscf7719-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7719-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x576_dscf7719-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7719-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7719-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x450_dscf7719-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7719-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4948,
            "name": "DSCF7724.jpeg",
            "slug": "dscf7724-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7724-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1365_dscf7724-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7724-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7724-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1066_dscf7724-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7724-jpeg.jpeg",
            "width": 3711
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF7732.jpeg",
            "slug": "dscf7732-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7732-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x576_dscf7732-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7732-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7732-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x450_dscf7732-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7732-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF7748.jpeg",
            "slug": "dscf7748-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7748-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x576_dscf7748-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7748-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7748-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x450_dscf7748-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7748-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 3286,
            "name": "DSCF7752.jpeg",
            "slug": "dscf7752-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x899_dscf7752-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x575_dscf7752-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x899_dscf7752-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7752-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x449_dscf7752-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7752-jpeg.jpeg",
            "width": 5842
         },
         {
            "faces": [],
            "height": 5151,
            "name": "DSCF7754.jpeg",
            "slug": "dscf7754-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7754-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1365_dscf7754-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7754-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7754-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1066_dscf7754-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7754-jpeg.jpeg",
            "width": 3863
         },
         {
            "faces": [],
            "height": 5283,
            "name": "DSCF7758.jpeg",
            "slug": "dscf7758-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7758-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1365_dscf7758-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7758-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7758-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1066_dscf7758-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7758-jpeg.jpeg",
            "width": 3962
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7759.jpeg",
            "slug": "dscf7759-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7759-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1365_dscf7759-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7759-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7759-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1066_dscf7759-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7759-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF7765.jpeg",
            "slug": "dscf7765-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7765-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x576_dscf7765-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7765-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7765-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x450_dscf7765-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7765-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7768.jpeg",
            "slug": "dscf7768-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7768-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1365_dscf7768-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7768-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7768-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1066_dscf7768-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7768-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF7773.jpeg",
            "slug": "dscf7773-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7773-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x576_dscf7773-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7773-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7773-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x450_dscf7773-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7773-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 2796,
            "name": "DSCF7777.jpeg",
            "slug": "dscf7777-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x899_dscf7777-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x575_dscf7777-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x899_dscf7777-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7777-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x449_dscf7777-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7777-jpeg.jpeg",
            "width": 4971
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7781.jpeg",
            "slug": "dscf7781-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7781-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1365_dscf7781-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7781-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7781-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1066_dscf7781-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7781-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7793.jpeg",
            "slug": "dscf7793-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7793-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1365_dscf7793-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7793-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7793-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1066_dscf7793-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7793-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF7794.jpeg",
            "slug": "dscf7794-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7794-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x576_dscf7794-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7794-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7794-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x450_dscf7794-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7794-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4824,
            "name": "DSCF7800.jpeg",
            "slug": "dscf7800-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7800-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1365_dscf7800-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7800-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7800-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1066_dscf7800-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7800-jpeg.jpeg",
            "width": 3618
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7812.jpeg",
            "slug": "dscf7812-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7812-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1365_dscf7812-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7812-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7812-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1066_dscf7812-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7812-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7818.jpeg",
            "slug": "dscf7818-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7818-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1365_dscf7818-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7818-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7818-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1066_dscf7818-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7818-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5174,
            "name": "DSCF7822.jpeg",
            "slug": "dscf7822-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7822-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1365_dscf7822-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2133_dscf7822-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7822-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1066_dscf7822-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7822-jpeg.jpeg",
            "width": 3881
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF7839.jpeg",
            "slug": "dscf7839-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2400_dscf7839-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1536_dscf7839-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x2400_dscf7839-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x750_dscf7839-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x1200_dscf7839-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x750_dscf7839-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF7843.jpeg",
            "slug": "dscf7843-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7843-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x576_dscf7843-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1600x900_dscf7843-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7843-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/800x450_dscf7843-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x281_dscf7843-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 5236,
            "name": "DSCF7850.jpeg",
            "slug": "dscf7850-jpeg",
            "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/1599x2133_dscf7850-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1024x1365_dscf7850-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/1599x2133_dscf7850-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7850-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-17-wittenham-clumps/799x1066_dscf7850-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x666_dscf7850-jpeg.jpeg",
            "width": 3927
         }
      ],
      "slug": "2022-01-17-wittenham-clumps",
      "src": "/static/_gallery/albums/2022-01-17-wittenham-clumps/500x750_dscf7709-jpeg.jpeg"
   },
   "2022-01-30-swan-lake": {
      "name": "2022-01-30 Swan Lake",
      "photos": [
         {
            "faces": [],
            "height": 4593,
            "name": "DSCF7894.jpeg",
            "slug": "dscf7894-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1599x2133_dscf7894-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1365_dscf7894-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1599x2133_dscf7894-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7894-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/799x1066_dscf7894-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7894-jpeg.jpeg",
            "width": 3445
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7898.jpeg",
            "slug": "dscf7898-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x1200_dscf7898-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x768_dscf7898-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x1200_dscf7898-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x375_dscf7898-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x600_dscf7898-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x375_dscf7898-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7950.jpeg",
            "slug": "dscf7950-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf7950-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1365_dscf7950-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf7950-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7950-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1066_dscf7950-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7950-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7966.jpeg",
            "slug": "dscf7966-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf7966-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1365_dscf7966-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf7966-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7966-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1066_dscf7966-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7966-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7973.jpeg",
            "slug": "dscf7973-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf7973-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1365_dscf7973-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf7973-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7973-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1066_dscf7973-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7973-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7984.jpeg",
            "slug": "dscf7984-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf7984-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1365_dscf7984-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf7984-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7984-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1066_dscf7984-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7984-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF7987.jpeg",
            "slug": "dscf7987-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x900_dscf7987-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x576_dscf7987-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x900_dscf7987-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x281_dscf7987-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x450_dscf7987-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x281_dscf7987-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF7994.jpeg",
            "slug": "dscf7994-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf7994-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1365_dscf7994-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf7994-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7994-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1066_dscf7994-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7994-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF8002.jpeg",
            "slug": "dscf8002-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf8002-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1365_dscf8002-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf8002-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf8002-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1066_dscf8002-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf8002-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF8004.jpeg",
            "slug": "dscf8004-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x900_dscf8004-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x576_dscf8004-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x900_dscf8004-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x281_dscf8004-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x450_dscf8004-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x281_dscf8004-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF8009.jpeg",
            "slug": "dscf8009-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf8009-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1365_dscf8009-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf8009-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf8009-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1066_dscf8009-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf8009-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8011.jpeg",
            "slug": "dscf8011-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2844_dscf8011-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1820_dscf8011-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2844_dscf8011-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x888_dscf8011-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1422_dscf8011-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x888_dscf8011-jpeg.jpeg",
            "width": 3375
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF8020.jpeg",
            "slug": "dscf8020-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf8020-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1365_dscf8020-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf8020-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf8020-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1066_dscf8020-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf8020-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF8028.jpeg",
            "slug": "dscf8028-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf8028-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1365_dscf8028-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf8028-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf8028-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1066_dscf8028-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf8028-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF8033.jpeg",
            "slug": "dscf8033-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x900_dscf8033-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x576_dscf8033-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x900_dscf8033-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x281_dscf8033-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x450_dscf8033-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x281_dscf8033-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF8040.jpeg",
            "slug": "dscf8040-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf8040-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1365_dscf8040-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf8040-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf8040-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1066_dscf8040-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf8040-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8041.jpeg",
            "slug": "dscf8041-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2400_dscf8041-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1536_dscf8041-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2400_dscf8041-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x750_dscf8041-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1200_dscf8041-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x750_dscf8041-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3375,
            "name": "DSCF8046.jpeg",
            "slug": "dscf8046-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x900_dscf8046-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x576_dscf8046-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x900_dscf8046-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x281_dscf8046-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x450_dscf8046-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x281_dscf8046-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8047.jpeg",
            "slug": "dscf8047-jpeg",
            "src": "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf8047-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1024x1365_dscf8047-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/1600x2133_dscf8047-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf8047-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-01-30-swan-lake/800x1066_dscf8047-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf8047-jpeg.jpeg",
            "width": 3000
         }
      ],
      "slug": "2022-01-30-swan-lake",
      "src": "/static/_gallery/albums/2022-01-30-swan-lake/500x666_dscf7894-jpeg.jpeg"
   },
   "2022-02-18-pack-goals-with-toby": {
      "name": "2022-02-18 Pack Goals with Toby",
      "photos": [
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8069.jpeg",
            "slug": "dscf8069-jpeg",
            "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8069-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1024x1536_dscf8069-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8069-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8069-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/800x1200_dscf8069-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8069-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8070.jpeg",
            "slug": "dscf8070-jpeg",
            "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8070-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1024x1536_dscf8070-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8070-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8070-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/800x1200_dscf8070-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8070-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8083.jpeg",
            "slug": "dscf8083-jpeg",
            "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8083-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1024x1536_dscf8083-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8083-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8083-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/800x1200_dscf8083-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8083-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8088.jpeg",
            "slug": "dscf8088-jpeg",
            "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8088-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1024x1536_dscf8088-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8088-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8088-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/800x1200_dscf8088-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8088-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8095.jpeg",
            "slug": "dscf8095-jpeg",
            "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8095-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1024x1536_dscf8095-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8095-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8095-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/800x1200_dscf8095-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8095-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8104.jpeg",
            "slug": "dscf8104-jpeg",
            "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8104-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1024x1536_dscf8104-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8104-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8104-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/800x1200_dscf8104-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8104-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8110.jpeg",
            "slug": "dscf8110-jpeg",
            "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8110-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1024x1536_dscf8110-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8110-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8110-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/800x1200_dscf8110-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8110-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8117.jpeg",
            "slug": "dscf8117-jpeg",
            "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x1066_dscf8117-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1024x682_dscf8117-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x1066_dscf8117-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x333_dscf8117-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/800x533_dscf8117-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x333_dscf8117-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8122.jpeg",
            "slug": "dscf8122-jpeg",
            "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x1066_dscf8122-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1024x682_dscf8122-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x1066_dscf8122-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x333_dscf8122-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/800x533_dscf8122-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x333_dscf8122-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8142.jpeg",
            "slug": "dscf8142-jpeg",
            "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8142-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1024x1536_dscf8142-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8142-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8142-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/800x1200_dscf8142-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8142-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8164.jpeg",
            "slug": "dscf8164-jpeg",
            "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8164-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1024x1536_dscf8164-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8164-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8164-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/800x1200_dscf8164-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8164-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8174.jpeg",
            "slug": "dscf8174-jpeg",
            "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8174-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1024x1536_dscf8174-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/1600x2400_dscf8174-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8174-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/800x1200_dscf8174-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8174-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-02-18-pack-goals-with-toby",
      "src": "/static/_gallery/albums/2022-02-18-pack-goals-with-toby/500x750_dscf8069-jpeg.jpeg"
   },
   "2022-02-19-toby": {
      "name": "2022-02-19 Toby",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8208.jpeg",
            "slug": "dscf8208-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-toby/1600x1600_dscf8208-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-toby/1024x1024_dscf8208-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-toby/1600x1600_dscf8208-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-toby/500x500_dscf8208-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-toby/800x800_dscf8208-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-toby/500x500_dscf8208-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8233.jpeg",
            "slug": "dscf8233-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-toby/1600x1600_dscf8233-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-toby/1024x1024_dscf8233-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-toby/1600x1600_dscf8233-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-toby/500x500_dscf8233-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-toby/800x800_dscf8233-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-toby/500x500_dscf8233-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-02-19-toby",
      "src": "/static/_gallery/albums/2022-02-19-toby/500x500_dscf8208-jpeg.jpeg"
   },
   "2022-02-19-window-mural": {
      "name": "2022-02-19 Window mural",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8240.jpeg",
            "slug": "dscf8240-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8240-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x682_dscf8240-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8240-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8240-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x533_dscf8240-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8240-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8253.jpeg",
            "slug": "dscf8253-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1159_dscf8253-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x742_dscf8253-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1159_dscf8253-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x362_dscf8253-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x579_dscf8253-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x362_dscf8253-jpeg.jpeg",
            "width": 5520
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8255.jpeg",
            "slug": "dscf8255-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1109_dscf8255-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x709_dscf8255-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1109_dscf8255-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x346_dscf8255-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x554_dscf8255-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x346_dscf8255-jpeg.jpeg",
            "width": 5770
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8262.jpeg",
            "slug": "dscf8262-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8262-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x682_dscf8262-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8262-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8262-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x533_dscf8262-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8262-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8264.jpeg",
            "slug": "dscf8264-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8264-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x682_dscf8264-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8264-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8264-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x533_dscf8264-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8264-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8266.jpeg",
            "slug": "dscf8266-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8266-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x682_dscf8266-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8266-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8266-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x533_dscf8266-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8266-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8271.jpeg",
            "slug": "dscf8271-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8271-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x682_dscf8271-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8271-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8271-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x533_dscf8271-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8271-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8272.jpeg",
            "slug": "dscf8272-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8272-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x682_dscf8272-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8272-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8272-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x533_dscf8272-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8272-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8275.jpeg",
            "slug": "dscf8275-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8275-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x682_dscf8275-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8275-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8275-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x533_dscf8275-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8275-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8277.jpeg",
            "slug": "dscf8277-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8277-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x682_dscf8277-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8277-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8277-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x533_dscf8277-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8277-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8279.jpeg",
            "slug": "dscf8279-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8279-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x682_dscf8279-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8279-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8279-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x533_dscf8279-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8279-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8280.jpeg",
            "slug": "dscf8280-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8280-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x682_dscf8280-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8280-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8280-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x533_dscf8280-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8280-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8282.jpeg",
            "slug": "dscf8282-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8282-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x682_dscf8282-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8282-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8282-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x533_dscf8282-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8282-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8285.jpeg",
            "slug": "dscf8285-jpeg",
            "src": "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8285-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1024x682_dscf8285-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/1600x1066_dscf8285-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8285-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-02-19-window-mural/800x533_dscf8285-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8285-jpeg.jpeg",
            "width": 6000
         }
      ],
      "slug": "2022-02-19-window-mural",
      "src": "/static/_gallery/albums/2022-02-19-window-mural/500x333_dscf8240-jpeg.jpeg"
   },
   "2022-03-02-dunalastair-water": {
      "name": "2022-03-02 Dunalastair Water",
      "photos": [
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF8296.jpeg",
            "slug": "dscf8296-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x900_dscf8296-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x576_dscf8296-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x900_dscf8296-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8296-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x450_dscf8296-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8296-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8301.jpeg",
            "slug": "dscf8301-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2000_dscf8301-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1280_dscf8301-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2000_dscf8301-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x625_dscf8301-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1000_dscf8301-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x625_dscf8301-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8302.jpeg",
            "slug": "dscf8302-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2000_dscf8302-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1280_dscf8302-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2000_dscf8302-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x625_dscf8302-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1000_dscf8302-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x625_dscf8302-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8303.jpeg",
            "slug": "dscf8303-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2000_dscf8303-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1280_dscf8303-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2000_dscf8303-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x625_dscf8303-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1000_dscf8303-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x625_dscf8303-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8306.jpeg",
            "slug": "dscf8306-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2000_dscf8306-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1280_dscf8306-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2000_dscf8306-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x625_dscf8306-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1000_dscf8306-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x625_dscf8306-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8307.jpeg",
            "slug": "dscf8307-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8307-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1536_dscf8307-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8307-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8307-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1200_dscf8307-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8307-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8312.jpeg",
            "slug": "dscf8312-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8312-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1536_dscf8312-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8312-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8312-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1200_dscf8312-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8312-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF8314.jpeg",
            "slug": "dscf8314-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x900_dscf8314-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x576_dscf8314-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x900_dscf8314-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8314-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x450_dscf8314-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8314-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8331.jpeg",
            "slug": "dscf8331-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2000_dscf8331-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1280_dscf8331-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2000_dscf8331-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x625_dscf8331-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1000_dscf8331-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x625_dscf8331-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8340.jpeg",
            "slug": "dscf8340-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8340-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1536_dscf8340-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8340-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8340-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1200_dscf8340-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8340-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF8345.jpeg",
            "slug": "dscf8345-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x900_dscf8345-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x576_dscf8345-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x900_dscf8345-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8345-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x450_dscf8345-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8345-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8357.jpeg",
            "slug": "dscf8357-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8357-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1536_dscf8357-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8357-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8357-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1200_dscf8357-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8357-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8371.jpeg",
            "slug": "dscf8371-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8371-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1536_dscf8371-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8371-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8371-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1200_dscf8371-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8371-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8382.jpeg",
            "slug": "dscf8382-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8382-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1536_dscf8382-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8382-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8382-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1200_dscf8382-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8382-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF8390.jpeg",
            "slug": "dscf8390-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x900_dscf8390-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x576_dscf8390-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x900_dscf8390-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8390-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x450_dscf8390-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8390-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8391.jpeg",
            "slug": "dscf8391-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x1600_dscf8391-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1024_dscf8391-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x1600_dscf8391-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x500_dscf8391-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x800_dscf8391-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x500_dscf8391-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8414.jpeg",
            "slug": "dscf8414-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8414-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8414-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8414-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8414-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8414-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8414-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 1714,
            "name": "DSCF8419.jpeg",
            "slug": "dscf8419-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x685_dscf8419-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x438_dscf8419-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x685_dscf8419-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x214_dscf8419-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x342_dscf8419-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x214_dscf8419-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF8429.jpeg",
            "slug": "dscf8429-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x900_dscf8429-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x576_dscf8429-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x900_dscf8429-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8429-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x450_dscf8429-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8429-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8442.jpeg",
            "slug": "dscf8442-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8442-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8442-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8442-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8442-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8442-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8442-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8460.jpeg",
            "slug": "dscf8460-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8460-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8460-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8460-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8460-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8460-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8460-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8476.jpeg",
            "slug": "dscf8476-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8476-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8476-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8476-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8476-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8476-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8476-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 1714,
            "name": "DSCF8496.jpeg",
            "slug": "dscf8496-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x685_dscf8496-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x438_dscf8496-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x685_dscf8496-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x214_dscf8496-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x342_dscf8496-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x214_dscf8496-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8501.jpeg",
            "slug": "dscf8501-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8501-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8501-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8501-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8501-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8501-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8501-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8520.jpeg",
            "slug": "dscf8520-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8520-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8520-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8520-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8520-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8520-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8520-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8532.jpeg",
            "slug": "dscf8532-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8532-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8532-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8532-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8532-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8532-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8532-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8545.jpeg",
            "slug": "dscf8545-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8545-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8545-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8545-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8545-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8545-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8545-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8550.jpeg",
            "slug": "dscf8550-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8550-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8550-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8550-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8550-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8550-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8550-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8551.jpeg",
            "slug": "dscf8551-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8551-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8551-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8551-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8551-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8551-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8551-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8571.jpeg",
            "slug": "dscf8571-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8571-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1536_dscf8571-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8571-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8571-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1200_dscf8571-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8571-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8575.jpeg",
            "slug": "dscf8575-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8575-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1536_dscf8575-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8575-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8575-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1200_dscf8575-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8575-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8586.jpeg",
            "slug": "dscf8586-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8586-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8586-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8586-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8586-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8586-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8586-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8588.jpeg",
            "slug": "dscf8588-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8588-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8588-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8588-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8588-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8588-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8588-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 1714,
            "name": "DSCF8590.jpeg",
            "slug": "dscf8590-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x685_dscf8590-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x438_dscf8590-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x685_dscf8590-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x214_dscf8590-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x342_dscf8590-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x214_dscf8590-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 1714,
            "name": "DSCF8592.jpeg",
            "slug": "dscf8592-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x685_dscf8592-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x438_dscf8592-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x685_dscf8592-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x214_dscf8592-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x342_dscf8592-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x214_dscf8592-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8616.jpeg",
            "slug": "dscf8616-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8616-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8616-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8616-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8616-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8616-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8616-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8646.jpeg",
            "slug": "dscf8646-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8646-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8646-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8646-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8646-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8646-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8646-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8657.jpeg",
            "slug": "dscf8657-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8657-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8657-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8657-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8657-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8657-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8657-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8663.jpeg",
            "slug": "dscf8663-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8663-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8663-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8663-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8663-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8663-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8663-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8670.jpeg",
            "slug": "dscf8670-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8670-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8670-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8670-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8670-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8670-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8670-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8675.jpeg",
            "slug": "dscf8675-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8675-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8675-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8675-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8675-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8675-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8675-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8679.jpeg",
            "slug": "dscf8679-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8679-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8679-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8679-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8679-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8679-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8679-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8680.jpeg",
            "slug": "dscf8680-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8680-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1536_dscf8680-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8680-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8680-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1200_dscf8680-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8680-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8682.jpeg",
            "slug": "dscf8682-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8682-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1536_dscf8682-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2400_dscf8682-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8682-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1200_dscf8682-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x750_dscf8682-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8685.jpeg",
            "slug": "dscf8685-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8685-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8685-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8685-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8685-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8685-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8685-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8689.jpeg",
            "slug": "dscf8689-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8689-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8689-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8689-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8689-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8689-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8689-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8695.jpeg",
            "slug": "dscf8695-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8695-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8695-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8695-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8695-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8695-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8695-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8700.jpeg",
            "slug": "dscf8700-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8700-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8700-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8700-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8700-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8700-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8700-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF8702.jpeg",
            "slug": "dscf8702-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x900_dscf8702-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x576_dscf8702-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x900_dscf8702-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8702-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x450_dscf8702-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8702-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF8705.jpeg",
            "slug": "dscf8705-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x1066_dscf8705-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x682_dscf8705-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x1066_dscf8705-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x333_dscf8705-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x533_dscf8705-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x333_dscf8705-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF8714.jpeg",
            "slug": "dscf8714-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x1066_dscf8714-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x682_dscf8714-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x1066_dscf8714-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x333_dscf8714-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x533_dscf8714-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x333_dscf8714-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8717.jpeg",
            "slug": "dscf8717-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2134_dscf8717-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8717-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2134_dscf8717-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8717-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1067_dscf8717-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8717-jpeg.jpeg",
            "width": 2999
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF8754.jpeg",
            "slug": "dscf8754-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x1066_dscf8754-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x682_dscf8754-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x1066_dscf8754-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x333_dscf8754-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x533_dscf8754-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x333_dscf8754-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8775.jpeg",
            "slug": "dscf8775-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8775-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8775-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8775-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8775-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8775-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8775-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8776.jpeg",
            "slug": "dscf8776-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8776-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8776-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8776-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8776-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8776-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8776-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8790.jpeg",
            "slug": "dscf8790-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8790-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8790-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8790-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8790-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8790-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8790-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "DSCF8822.jpeg",
            "slug": "dscf8822-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x1200_dscf8822-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x768_dscf8822-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x1200_dscf8822-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x375_dscf8822-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x600_dscf8822-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x375_dscf8822-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8868.jpeg",
            "slug": "dscf8868-jpeg",
            "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8868-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1024x1365_dscf8868-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/1600x2133_dscf8868-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8868-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-03-02-dunalastair-water/800x1066_dscf8868-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x666_dscf8868-jpeg.jpeg",
            "width": 3000
         }
      ],
      "slug": "2022-03-02-dunalastair-water",
      "src": "/static/_gallery/albums/2022-03-02-dunalastair-water/500x281_dscf8296-jpeg.jpeg"
   },
   "2022-04-14-toby": {
      "name": "2022-04-14 Toby",
      "photos": [
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8877.jpeg",
            "slug": "dscf8877-jpeg",
            "src": "/static/_gallery/albums/2022-04-14-toby/1600x2400_dscf8877-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-14-toby/1024x1536_dscf8877-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-14-toby/1600x2400_dscf8877-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-14-toby/500x750_dscf8877-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-14-toby/800x1200_dscf8877-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-14-toby/500x750_dscf8877-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8879.jpeg",
            "slug": "dscf8879-jpeg",
            "src": "/static/_gallery/albums/2022-04-14-toby/1600x2400_dscf8879-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-14-toby/1024x1536_dscf8879-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-14-toby/1600x2400_dscf8879-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-14-toby/500x750_dscf8879-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-14-toby/800x1200_dscf8879-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-14-toby/500x750_dscf8879-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8887.jpeg",
            "slug": "dscf8887-jpeg",
            "src": "/static/_gallery/albums/2022-04-14-toby/1600x1066_dscf8887-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-14-toby/1024x682_dscf8887-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-14-toby/1600x1066_dscf8887-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-14-toby/500x333_dscf8887-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-14-toby/800x533_dscf8887-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-14-toby/500x333_dscf8887-jpeg.jpeg",
            "width": 6000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF8906.jpeg",
            "slug": "dscf8906-jpeg",
            "src": "/static/_gallery/albums/2022-04-14-toby/1600x2400_dscf8906-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-14-toby/1024x1536_dscf8906-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-14-toby/1600x2400_dscf8906-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-14-toby/500x750_dscf8906-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-14-toby/800x1200_dscf8906-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-14-toby/500x750_dscf8906-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-04-14-toby",
      "src": "/static/_gallery/albums/2022-04-14-toby/500x750_dscf8877-jpeg.jpeg"
   },
   "2022-04-16-easter-with-katie": {
      "name": "2022-04-16 Easter with Katie",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8938.jpeg",
            "slug": "dscf8938-jpeg",
            "src": "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x1600_dscf8938-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1024x1024_dscf8938-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x1600_dscf8938-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/500x500_dscf8938-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/800x800_dscf8938-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-16-easter-with-katie/500x500_dscf8938-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF8957.jpeg",
            "slug": "dscf8957-jpeg",
            "src": "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf8957-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1024x1365_dscf8957-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf8957-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf8957-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/800x1066_dscf8957-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf8957-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8985.jpeg",
            "slug": "dscf8985-jpeg",
            "src": "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf8985-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1024x1365_dscf8985-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf8985-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf8985-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/800x1066_dscf8985-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf8985-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF8996.jpeg",
            "slug": "dscf8996-jpeg",
            "src": "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf8996-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1024x1365_dscf8996-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf8996-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf8996-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/800x1066_dscf8996-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf8996-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF8998.jpeg",
            "slug": "dscf8998-jpeg",
            "src": "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x1200_dscf8998-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1024x768_dscf8998-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x1200_dscf8998-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/500x375_dscf8998-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/800x600_dscf8998-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-16-easter-with-katie/500x375_dscf8998-jpeg.jpeg",
            "width": 5333
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF9005.jpeg",
            "slug": "dscf9005-jpeg",
            "src": "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf9005-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1024x1365_dscf9005-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf9005-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf9005-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/800x1066_dscf9005-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf9005-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF9040.jpeg",
            "slug": "dscf9040-jpeg",
            "src": "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf9040-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1024x1365_dscf9040-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf9040-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf9040-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/800x1066_dscf9040-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf9040-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF9046.jpeg",
            "slug": "dscf9046-jpeg",
            "src": "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf9046-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1024x1365_dscf9046-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf9046-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf9046-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/800x1066_dscf9046-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf9046-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2320,
            "name": "DSCF9059.jpeg",
            "slug": "dscf9059-jpeg",
            "src": "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x1199_dscf9059-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1024x767_dscf9059-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x1199_dscf9059-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/500x374_dscf9059-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/800x599_dscf9059-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-16-easter-with-katie/500x374_dscf9059-jpeg.jpeg",
            "width": 3094
         },
         {
            "faces": [],
            "height": 5333,
            "name": "DSCF9107.jpeg",
            "slug": "dscf9107-jpeg",
            "src": "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf9107-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1024x1365_dscf9107-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/1600x2133_dscf9107-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf9107-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-04-16-easter-with-katie/800x1066_dscf9107-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-04-16-easter-with-katie/500x666_dscf9107-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-04-16-easter-with-katie",
      "src": "/static/_gallery/albums/2022-04-16-easter-with-katie/500x500_dscf8938-jpeg.jpeg"
   },
   "2022-05-03-toby": {
      "name": "2022-05-03 Toby",
      "photos": [
         {
            "faces": [],
            "height": 4160,
            "name": "20220503-0004.jpeg",
            "slug": "20220503-0004-jpeg",
            "src": "/static/_gallery/albums/2022-05-03-toby/1600x1066_20220503-0004-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-03-toby/1024x682_20220503-0004-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-03-toby/1600x1066_20220503-0004-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-03-toby/500x333_20220503-0004-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-03-toby/800x533_20220503-0004-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-03-toby/500x333_20220503-0004-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 4160,
            "name": "20220503-0015.jpeg",
            "slug": "20220503-0015-jpeg",
            "src": "/static/_gallery/albums/2022-05-03-toby/1600x1066_20220503-0015-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-03-toby/1024x682_20220503-0015-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-03-toby/1600x1066_20220503-0015-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-03-toby/500x333_20220503-0015-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-03-toby/800x533_20220503-0015-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-03-toby/500x333_20220503-0015-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 4160,
            "name": "20220503-0023.jpeg",
            "slug": "20220503-0023-jpeg",
            "src": "/static/_gallery/albums/2022-05-03-toby/1600x1066_20220503-0023-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-03-toby/1024x682_20220503-0023-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-03-toby/1600x1066_20220503-0023-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-03-toby/500x333_20220503-0023-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-03-toby/800x533_20220503-0023-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-03-toby/500x333_20220503-0023-jpeg.jpeg",
            "width": 6240
         }
      ],
      "slug": "2022-05-03-toby",
      "src": "/static/_gallery/albums/2022-05-03-toby/500x333_20220503-0004-jpeg.jpeg"
   },
   "2022-05-06-toby": {
      "name": "2022-05-06 Toby",
      "photos": [
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF9115.jpeg",
            "slug": "dscf9115-jpeg",
            "src": "/static/_gallery/albums/2022-05-06-toby/1600x2400_dscf9115-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-06-toby/1024x1536_dscf9115-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-06-toby/1600x2400_dscf9115-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-06-toby/500x750_dscf9115-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-06-toby/800x1200_dscf9115-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-06-toby/500x750_dscf9115-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4075,
            "name": "DSCF9118.jpeg",
            "slug": "dscf9118-jpeg",
            "src": "/static/_gallery/albums/2022-05-06-toby/1600x2000_dscf9118-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-06-toby/1024x1280_dscf9118-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-06-toby/1600x2000_dscf9118-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-06-toby/500x625_dscf9118-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-06-toby/800x1000_dscf9118-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-06-toby/500x625_dscf9118-jpeg.jpeg",
            "width": 3260
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF9130.jpeg",
            "slug": "dscf9130-jpeg",
            "src": "/static/_gallery/albums/2022-05-06-toby/1600x2400_dscf9130-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-06-toby/1024x1536_dscf9130-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-06-toby/1600x2400_dscf9130-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-06-toby/500x750_dscf9130-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-06-toby/800x1200_dscf9130-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-06-toby/500x750_dscf9130-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF9142.jpeg",
            "slug": "dscf9142-jpeg",
            "src": "/static/_gallery/albums/2022-05-06-toby/1600x2400_dscf9142-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-06-toby/1024x1536_dscf9142-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-06-toby/1600x2400_dscf9142-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-06-toby/500x750_dscf9142-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-06-toby/800x1200_dscf9142-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-06-toby/500x750_dscf9142-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF9143.jpeg",
            "slug": "dscf9143-jpeg",
            "src": "/static/_gallery/albums/2022-05-06-toby/1600x2400_dscf9143-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-06-toby/1024x1536_dscf9143-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-06-toby/1600x2400_dscf9143-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-06-toby/500x750_dscf9143-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-06-toby/800x1200_dscf9143-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-06-toby/500x750_dscf9143-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-05-06-toby",
      "src": "/static/_gallery/albums/2022-05-06-toby/500x750_dscf9115-jpeg.jpeg"
   },
   "2022-05-08-katie-s": {
      "name": "2022-05-08 Katie's",
      "photos": [
         {
            "faces": [],
            "height": 5000,
            "name": "DSCF9149.jpeg",
            "slug": "dscf9149-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x2000_dscf9149-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x1280_dscf9149-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x2000_dscf9149-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x625_dscf9149-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x1000_dscf9149-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x625_dscf9149-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 6000,
            "name": "DSCF9149.large.jpeg",
            "slug": "dscf9149-large-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x2400_dscf9149-large-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x1536_dscf9149-large-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x2400_dscf9149-large-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x750_dscf9149-large-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x1200_dscf9149-large-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x750_dscf9149-large-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 5000,
            "name": "DSCF9179.jpeg",
            "slug": "dscf9179-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x2000_dscf9179-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x1280_dscf9179-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x2000_dscf9179-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x625_dscf9179-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x1000_dscf9179-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x625_dscf9179-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4453,
            "name": "DSCF9190.jpeg",
            "slug": "dscf9190-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1599x1999_dscf9190-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x1279_dscf9190-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1599x1999_dscf9190-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x624_dscf9190-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/799x999_dscf9190-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x624_dscf9190-jpeg.jpeg",
            "width": 3563
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF9198.jpeg",
            "slug": "dscf9198-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x1280_dscf9198-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x819_dscf9198-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x1280_dscf9198-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x400_dscf9198-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x640_dscf9198-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x400_dscf9198-jpeg.jpeg",
            "width": 5000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF9198bw.jpeg",
            "slug": "dscf9198bw-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x2133_dscf9198bw-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x1365_dscf9198bw-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x2133_dscf9198bw-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x666_dscf9198bw-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x1066_dscf9198bw-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x666_dscf9198bw-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF9216.jpeg",
            "slug": "dscf9216-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x1280_dscf9216-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x819_dscf9216-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x1280_dscf9216-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x400_dscf9216-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x640_dscf9216-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x400_dscf9216-jpeg.jpeg",
            "width": 5000
         },
         {
            "faces": [],
            "height": 5000,
            "name": "DSCF9218.jpeg",
            "slug": "dscf9218-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x2000_dscf9218-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x1280_dscf9218-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x2000_dscf9218-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x625_dscf9218-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x1000_dscf9218-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x625_dscf9218-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF9315.jpeg",
            "slug": "dscf9315-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x1280_dscf9315-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x819_dscf9315-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x1280_dscf9315-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x400_dscf9315-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x640_dscf9315-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x400_dscf9315-jpeg.jpeg",
            "width": 5000
         },
         {
            "faces": [],
            "height": 4343,
            "name": "DSCF9353.jpeg",
            "slug": "dscf9353-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x2000_dscf9353-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x1280_dscf9353-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x2000_dscf9353-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/499x625_dscf9353-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x1000_dscf9353-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/499x625_dscf9353-jpeg.jpeg",
            "width": 3474
         },
         {
            "faces": [],
            "height": 3482,
            "name": "DSCF9362.jpeg",
            "slug": "dscf9362-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x1279_dscf9362-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x819_dscf9362-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x1279_dscf9362-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x399_dscf9362-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x639_dscf9362-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x399_dscf9362-jpeg.jpeg",
            "width": 4353
         },
         {
            "faces": [],
            "height": 2822,
            "name": "DSCF9379.jpeg",
            "slug": "dscf9379-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1599x1280_dscf9379-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x819_dscf9379-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1599x1280_dscf9379-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x400_dscf9379-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/799x640_dscf9379-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x400_dscf9379-jpeg.jpeg",
            "width": 3527
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF9396.jpeg",
            "slug": "dscf9396-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x1280_dscf9396-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x819_dscf9396-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x1280_dscf9396-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x400_dscf9396-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x640_dscf9396-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x400_dscf9396-jpeg.jpeg",
            "width": 5000
         },
         {
            "faces": [],
            "height": 3939,
            "name": "DSCF9412.jpeg",
            "slug": "dscf9412-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x2000_dscf9412-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x1280_dscf9412-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x2000_dscf9412-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/500x625_dscf9412-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x1000_dscf9412-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/500x625_dscf9412-jpeg.jpeg",
            "width": 3151
         },
         {
            "faces": [],
            "height": 3826,
            "name": "DSCF9431.jpeg",
            "slug": "dscf9431-jpeg",
            "src": "/static/_gallery/albums/2022-05-08-katie-s/1600x1279_dscf9431-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1024x819_dscf9431-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/1600x1279_dscf9431-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/499x399_dscf9431-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-08-katie-s/800x639_dscf9431-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-08-katie-s/499x399_dscf9431-jpeg.jpeg",
            "width": 4783
         }
      ],
      "slug": "2022-05-08-katie-s",
      "src": "/static/_gallery/albums/2022-05-08-katie-s/500x625_dscf9149-jpeg.jpeg"
   },
   "2022-05-27-new-camera-test-shots": {
      "name": "2022-05-27 New camera test shots",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF0007.jpeg",
            "slug": "dscf0007-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x2400_dscf0007-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1024x1536_dscf0007-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x2400_dscf0007-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0007-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/800x1200_dscf0007-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0007-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF0014.jpeg",
            "slug": "dscf0014-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x2400_dscf0014-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1024x1536_dscf0014-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x2400_dscf0014-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0014-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/800x1200_dscf0014-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0014-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF0020.jpeg",
            "slug": "dscf0020-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x1600_dscf0020-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1024x1024_dscf0020-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x1600_dscf0020-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x500_dscf0020-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/800x800_dscf0020-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x500_dscf0020-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF0026.jpeg",
            "slug": "dscf0026-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x2402_dscf0026-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1024x1537_dscf0026-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x2402_dscf0026-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0026-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/800x1201_dscf0026-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0026-jpeg.jpeg",
            "width": 2664
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF0033.jpeg",
            "slug": "dscf0033-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x2400_dscf0033-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1024x1536_dscf0033-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x2400_dscf0033-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0033-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/800x1200_dscf0033-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0033-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF0035.jpeg",
            "slug": "dscf0035-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x900_dscf0035-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1024x576_dscf0035-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x900_dscf0035-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x281_dscf0035-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/800x450_dscf0035-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x281_dscf0035-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF0039.jpeg",
            "slug": "dscf0039-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x2400_dscf0039-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1024x1536_dscf0039-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x2400_dscf0039-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0039-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/800x1200_dscf0039-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0039-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF0048.jpeg",
            "slug": "dscf0048-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x2400_dscf0048-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1024x1536_dscf0048-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/1600x2400_dscf0048-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0048-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-new-camera-test-shots/800x1200_dscf0048-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0048-jpeg.jpeg",
            "width": 2666
         }
      ],
      "slug": "2022-05-27-new-camera-test-shots",
      "src": "/static/_gallery/albums/2022-05-27-new-camera-test-shots/500x750_dscf0007-jpeg.jpeg"
   },
   "2022-05-27-walk-with-polina-and-matt": {
      "name": "2022-05-27 Walk with Polina and Matt",
      "photos": [
         {
            "faces": [],
            "height": 5547,
            "name": "DSCF0077.jpeg",
            "slug": "dscf0077-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1600x2133_dscf0077-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1024x1365_dscf0077-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1600x2133_dscf0077-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0077-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/800x1066_dscf0077-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0077-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 4065,
            "name": "DSCF0092.jpeg",
            "slug": "dscf0092-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1600x2132_dscf0092-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1024x1364_dscf0092-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1600x2132_dscf0092-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0092-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/800x1066_dscf0092-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0092-jpeg.jpeg",
            "width": 3050
         },
         {
            "faces": [],
            "height": 5547,
            "name": "DSCF0139.jpeg",
            "slug": "dscf0139-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1600x2133_dscf0139-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1024x1365_dscf0139-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1600x2133_dscf0139-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0139-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/800x1066_dscf0139-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0139-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 5547,
            "name": "DSCF0159.jpeg",
            "slug": "dscf0159-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1600x2133_dscf0159-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1024x1365_dscf0159-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1600x2133_dscf0159-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0159-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/800x1066_dscf0159-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0159-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 3828,
            "name": "DSCF0167.jpeg",
            "slug": "dscf0167-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1600x2133_dscf0167-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1024x1365_dscf0167-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1600x2133_dscf0167-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0167-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/800x1066_dscf0167-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0167-jpeg.jpeg",
            "width": 2871
         },
         {
            "faces": [],
            "height": 5404,
            "name": "DSCF0181.jpeg",
            "slug": "dscf0181-jpeg",
            "src": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1599x2133_dscf0181-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1024x1365_dscf0181-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/1599x2133_dscf0181-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0181-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/799x1066_dscf0181-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0181-jpeg.jpeg",
            "width": 4052
         }
      ],
      "slug": "2022-05-27-walk-with-polina-and-matt",
      "src": "/static/_gallery/albums/2022-05-27-walk-with-polina-and-matt/500x666_dscf0077-jpeg.jpeg"
   },
   "2022-05-28-paul-s-engagement": {
      "name": "2022-05-28 Paul's engagement",
      "photos": [
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0184.jpeg",
            "slug": "dscf0184-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0184-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x1536_dscf0184-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0184-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0184-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x1200_dscf0184-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0184-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF0193.jpeg",
            "slug": "dscf0193-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2133_dscf0193-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x1365_dscf0193-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2133_dscf0193-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x666_dscf0193-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x1066_dscf0193-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x666_dscf0193-jpeg.jpeg",
            "width": 3120
         },
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF0200.jpeg",
            "slug": "dscf0200-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x1066_dscf0200-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x682_dscf0200-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x1066_dscf0200-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x333_dscf0200-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x533_dscf0200-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x333_dscf0200-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0214.jpeg",
            "slug": "dscf0214-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0214-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x1536_dscf0214-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0214-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0214-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x1200_dscf0214-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0214-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0236.jpeg",
            "slug": "dscf0236-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0236-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x1536_dscf0236-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0236-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0236-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x1200_dscf0236-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0236-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF0291.jpeg",
            "slug": "dscf0291-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x1066_dscf0291-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x682_dscf0291-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x1066_dscf0291-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x333_dscf0291-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x533_dscf0291-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x333_dscf0291-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 3251,
            "name": "DSCF0298.jpeg",
            "slug": "dscf0298-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x899_dscf0298-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x575_dscf0298-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x899_dscf0298-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x281_dscf0298-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x449_dscf0298-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x281_dscf0298-jpeg.jpeg",
            "width": 5780
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0307.jpeg",
            "slug": "dscf0307-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0307-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x1536_dscf0307-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0307-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0307-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x1200_dscf0307-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0307-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0324.jpeg",
            "slug": "dscf0324-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0324-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x1536_dscf0324-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0324-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0324-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x1200_dscf0324-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0324-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0331.jpeg",
            "slug": "dscf0331-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0331-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x1536_dscf0331-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0331-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0331-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x1200_dscf0331-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0331-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 4159,
            "name": "DSCF0336.jpeg",
            "slug": "dscf0336-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2133_dscf0336-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x1365_dscf0336-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2133_dscf0336-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/499x666_dscf0336-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x1066_dscf0336-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/499x666_dscf0336-jpeg.jpeg",
            "width": 3119
         },
         {
            "faces": [],
            "height": 5547,
            "name": "DSCF0341.jpeg",
            "slug": "dscf0341-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2133_dscf0341-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x1365_dscf0341-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2133_dscf0341-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x666_dscf0341-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x1066_dscf0341-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x666_dscf0341-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0377.jpeg",
            "slug": "dscf0377-jpeg",
            "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0377-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1024x1536_dscf0377-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/1600x2400_dscf0377-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0377-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-05-28-paul-s-engagement/800x1200_dscf0377-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0377-jpeg.jpeg",
            "width": 4160
         }
      ],
      "slug": "2022-05-28-paul-s-engagement",
      "src": "/static/_gallery/albums/2022-05-28-paul-s-engagement/500x750_dscf0184-jpeg.jpeg"
   },
   "2022-06-03-polina-and-zeus": {
      "name": "2022-06-03 Polina and Zeus",
      "photos": [
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0418.jpeg",
            "slug": "dscf0418-jpeg",
            "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0418-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1024x1536_dscf0418-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0418-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0418-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/800x1200_dscf0418-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0418-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF0437.jpeg",
            "slug": "dscf0437-jpeg",
            "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x1066_dscf0437-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1024x682_dscf0437-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x1066_dscf0437-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x333_dscf0437-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/800x533_dscf0437-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x333_dscf0437-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0447.jpeg",
            "slug": "dscf0447-jpeg",
            "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0447-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1024x1536_dscf0447-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0447-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0447-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/800x1200_dscf0447-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0447-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0455.jpeg",
            "slug": "dscf0455-jpeg",
            "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0455-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1024x1536_dscf0455-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0455-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0455-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/800x1200_dscf0455-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0455-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 3114,
            "name": "DSCF0483.jpeg",
            "slug": "dscf0483-jpeg",
            "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2133_dscf0483-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1024x1365_dscf0483-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2133_dscf0483-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x666_dscf0483-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/800x1066_dscf0483-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x666_dscf0483-jpeg.jpeg",
            "width": 2335
         },
         {
            "faces": [],
            "height": 3838,
            "name": "DSCF0489.jpeg",
            "slug": "dscf0489-jpeg",
            "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0489-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1024x1536_dscf0489-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0489-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0489-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/800x1200_dscf0489-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0489-jpeg.jpeg",
            "width": 2558
         },
         {
            "faces": [],
            "height": 2329,
            "name": "DSCF0512.jpeg",
            "slug": "dscf0512-jpeg",
            "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x900_dscf0512-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1024x576_dscf0512-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x900_dscf0512-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x281_dscf0512-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/800x450_dscf0512-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x281_dscf0512-jpeg.jpeg",
            "width": 4140
         },
         {
            "faces": [],
            "height": 4031,
            "name": "DSCF0542.jpeg",
            "slug": "dscf0542-jpeg",
            "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0542-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1024x1536_dscf0542-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0542-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0542-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/800x1200_dscf0542-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0542-jpeg.jpeg",
            "width": 2687
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0563.jpeg",
            "slug": "dscf0563-jpeg",
            "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0563-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1024x1536_dscf0563-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0563-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0563-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/800x1200_dscf0563-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0563-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0593.jpeg",
            "slug": "dscf0593-jpeg",
            "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0593-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1024x1536_dscf0593-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0593-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0593-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/800x1200_dscf0593-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0593-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 2407,
            "name": "DSCF0620.jpeg",
            "slug": "dscf0620-jpeg",
            "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x1066_dscf0620-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1024x682_dscf0620-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x1066_dscf0620-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x333_dscf0620-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/800x533_dscf0620-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x333_dscf0620-jpeg.jpeg",
            "width": 3610
         },
         {
            "faces": [],
            "height": 5220,
            "name": "DSCF0641.jpeg",
            "slug": "dscf0641-jpeg",
            "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0641-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1024x1536_dscf0641-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/1600x2400_dscf0641-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0641-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-03-polina-and-zeus/800x1200_dscf0641-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0641-jpeg.jpeg",
            "width": 3480
         }
      ],
      "slug": "2022-06-03-polina-and-zeus",
      "src": "/static/_gallery/albums/2022-06-03-polina-and-zeus/500x750_dscf0418-jpeg.jpeg"
   },
   "2022-06-04-london-with-tylers": {
      "name": "2022-06-04 London with Tylers",
      "photos": [
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF0681.jpeg",
            "slug": "dscf0681-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0681-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x682_dscf0681-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0681-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0681-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x533_dscf0681-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0681-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF0696.jpeg",
            "slug": "dscf0696-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0696-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x682_dscf0696-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0696-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0696-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x533_dscf0696-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0696-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0703.jpeg",
            "slug": "dscf0703-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0703-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x1536_dscf0703-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0703-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0703-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x1200_dscf0703-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0703-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 3405,
            "name": "DSCF0757.jpeg",
            "slug": "dscf0757-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0757-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x682_dscf0757-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0757-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0757-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x533_dscf0757-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0757-jpeg.jpeg",
            "width": 5108
         },
         {
            "faces": [],
            "height": 4331,
            "name": "DSCF0766.jpeg",
            "slug": "dscf0766-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0766-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x1536_dscf0766-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0766-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0766-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x1200_dscf0766-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0766-jpeg.jpeg",
            "width": 2887
         },
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF0772.jpeg",
            "slug": "dscf0772-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0772-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x682_dscf0772-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0772-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0772-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x533_dscf0772-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0772-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF0811.jpeg",
            "slug": "dscf0811-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0811-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x682_dscf0811-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0811-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0811-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x533_dscf0811-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0811-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF0846.jpeg",
            "slug": "dscf0846-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0846-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x682_dscf0846-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0846-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0846-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x533_dscf0846-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0846-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0847.jpeg",
            "slug": "dscf0847-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0847-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x1536_dscf0847-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0847-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0847-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x1200_dscf0847-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0847-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 5004,
            "name": "DSCF0866.jpeg",
            "slug": "dscf0866-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2391_dscf0866-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x1530_dscf0866-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2391_dscf0866-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x747_dscf0866-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x1195_dscf0866-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x747_dscf0866-jpeg.jpeg",
            "width": 3348
         },
         {
            "faces": [],
            "height": 5004,
            "name": "DSCF0870.jpeg",
            "slug": "dscf0870-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2391_dscf0870-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x1530_dscf0870-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2391_dscf0870-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x747_dscf0870-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x1195_dscf0870-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x747_dscf0870-jpeg.jpeg",
            "width": 3348
         },
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF0885.jpeg",
            "slug": "dscf0885-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0885-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x682_dscf0885-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0885-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0885-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x533_dscf0885-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0885-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 5512,
            "name": "DSCF0909.jpeg",
            "slug": "dscf0909-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1599x2133_dscf0909-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x1365_dscf0909-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1599x2133_dscf0909-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/499x666_dscf0909-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/799x1066_dscf0909-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/499x666_dscf0909-jpeg.jpeg",
            "width": 4134
         },
         {
            "faces": [],
            "height": 3474,
            "name": "DSCF0923.jpeg",
            "slug": "dscf0923-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x900_dscf0923-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x576_dscf0923-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x900_dscf0923-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/499x281_dscf0923-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x450_dscf0923-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/499x281_dscf0923-jpeg.jpeg",
            "width": 6174
         },
         {
            "faces": [],
            "height": 5807,
            "name": "DSCF0935.jpeg",
            "slug": "dscf0935-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0935-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x1536_dscf0935-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0935-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0935-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x1200_dscf0935-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0935-jpeg.jpeg",
            "width": 3871
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0947.jpeg",
            "slug": "dscf0947-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0947-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x1536_dscf0947-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0947-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0947-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x1200_dscf0947-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0947-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 3433,
            "name": "DSCF0956.jpeg",
            "slug": "dscf0956-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x900_dscf0956-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x576_dscf0956-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x900_dscf0956-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x281_dscf0956-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x450_dscf0956-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x281_dscf0956-jpeg.jpeg",
            "width": 6102
         },
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF0971.jpeg",
            "slug": "dscf0971-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0971-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x682_dscf0971-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0971-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0971-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x533_dscf0971-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0971-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0977.jpeg",
            "slug": "dscf0977-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0977-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x1536_dscf0977-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0977-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0977-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x1200_dscf0977-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0977-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 6240,
            "name": "DSCF0987.jpeg",
            "slug": "dscf0987-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0987-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x1536_dscf0987-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x2400_dscf0987-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0987-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x1200_dscf0987-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x750_dscf0987-jpeg.jpeg",
            "width": 4160
         },
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF0992.jpeg",
            "slug": "dscf0992-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0992-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x682_dscf0992-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf0992-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0992-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x533_dscf0992-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0992-jpeg.jpeg",
            "width": 6240
         },
         {
            "faces": [],
            "height": 4160,
            "name": "DSCF1010.jpeg",
            "slug": "dscf1010-jpeg",
            "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf1010-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1024x682_dscf1010-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/1600x1066_dscf1010-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf1010-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-06-04-london-with-tylers/800x533_dscf1010-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf1010-jpeg.jpeg",
            "width": 6240
         }
      ],
      "slug": "2022-06-04-london-with-tylers",
      "src": "/static/_gallery/albums/2022-06-04-london-with-tylers/500x333_dscf0681-jpeg.jpeg"
   },
   "2022-07-06-legoland": {
      "name": "2022-07-06 Legoland",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1142.jpeg",
            "slug": "dscf1142-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1142-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x1536_dscf1142-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1142-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1142-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x1200_dscf1142-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1142-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1148.jpeg",
            "slug": "dscf1148-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1148-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x682_dscf1148-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1148-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1148-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x533_dscf1148-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1148-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1165.jpeg",
            "slug": "dscf1165-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1165-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x1536_dscf1165-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1165-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1165-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x1200_dscf1165-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1165-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1170.jpeg",
            "slug": "dscf1170-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1170-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x682_dscf1170-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1170-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1170-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x533_dscf1170-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1170-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1180.jpeg",
            "slug": "dscf1180-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1180-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x1536_dscf1180-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1180-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1180-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x1200_dscf1180-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1180-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1181.jpeg",
            "slug": "dscf1181-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1181-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x1536_dscf1181-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1181-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1181-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x1200_dscf1181-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1181-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1188.jpeg",
            "slug": "dscf1188-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1188-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x682_dscf1188-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1188-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1188-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x533_dscf1188-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1188-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1191.jpeg",
            "slug": "dscf1191-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1191-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x1536_dscf1191-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1191-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1191-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x1200_dscf1191-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1191-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1194.jpeg",
            "slug": "dscf1194-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1194-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x1536_dscf1194-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1194-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1194-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x1200_dscf1194-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1194-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1198.jpeg",
            "slug": "dscf1198-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1198-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x682_dscf1198-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1198-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1198-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x533_dscf1198-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1198-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1199.jpeg",
            "slug": "dscf1199-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1199-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x1536_dscf1199-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1199-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1199-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x1200_dscf1199-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1199-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1208.jpeg",
            "slug": "dscf1208-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1208-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x682_dscf1208-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1208-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1208-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x533_dscf1208-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1208-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1210.jpeg",
            "slug": "dscf1210-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1210-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x1536_dscf1210-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1210-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1210-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x1200_dscf1210-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1210-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1222.jpeg",
            "slug": "dscf1222-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1222-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x1536_dscf1222-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1222-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1222-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x1200_dscf1222-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1222-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1226.jpeg",
            "slug": "dscf1226-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1226-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x682_dscf1226-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1226-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1226-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x533_dscf1226-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1226-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1235.jpeg",
            "slug": "dscf1235-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1235-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x682_dscf1235-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1235-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1235-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x533_dscf1235-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1235-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1255.jpeg",
            "slug": "dscf1255-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1255-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x1536_dscf1255-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1255-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1255-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x1200_dscf1255-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1255-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1264.jpeg",
            "slug": "dscf1264-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1264-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x682_dscf1264-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1264-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1264-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x533_dscf1264-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1264-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1268.jpeg",
            "slug": "dscf1268-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1268-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x1536_dscf1268-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1268-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1268-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x1200_dscf1268-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1268-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1269.jpeg",
            "slug": "dscf1269-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1269-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x682_dscf1269-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1269-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1269-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x533_dscf1269-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1269-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1279.jpeg",
            "slug": "dscf1279-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1279-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x682_dscf1279-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1279-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1279-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x533_dscf1279-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1279-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1281.jpeg",
            "slug": "dscf1281-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1281-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x682_dscf1281-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1281-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1281-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x533_dscf1281-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1281-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1283.jpeg",
            "slug": "dscf1283-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1283-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x682_dscf1283-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x1066_dscf1283-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1283-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x533_dscf1283-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x333_dscf1283-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1287.jpeg",
            "slug": "dscf1287-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1287-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1024x1536_dscf1287-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/1600x2400_dscf1287-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1287-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-legoland/800x1200_dscf1287-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1287-jpeg.jpeg",
            "width": 2666
         }
      ],
      "slug": "2022-07-06-legoland",
      "src": "/static/_gallery/albums/2022-07-06-legoland/500x750_dscf1142-jpeg.jpeg"
   },
   "2022-07-06-photos-with-katie": {
      "name": "2022-07-06 Photos with Katie",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1343.jpeg",
            "slug": "dscf1343-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-photos-with-katie/1600x2400_dscf1343-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/1024x1536_dscf1343-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/1600x2400_dscf1343-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/500x750_dscf1343-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/800x1200_dscf1343-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-photos-with-katie/500x750_dscf1343-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1355.jpeg",
            "slug": "dscf1355-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-photos-with-katie/1600x2400_dscf1355-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/1024x1536_dscf1355-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/1600x2400_dscf1355-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/500x750_dscf1355-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/800x1200_dscf1355-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-photos-with-katie/500x750_dscf1355-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1395.jpeg",
            "slug": "dscf1395-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-photos-with-katie/1600x2400_dscf1395-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/1024x1536_dscf1395-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/1600x2400_dscf1395-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/500x750_dscf1395-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/800x1200_dscf1395-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-photos-with-katie/500x750_dscf1395-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1419.jpeg",
            "slug": "dscf1419-jpeg",
            "src": "/static/_gallery/albums/2022-07-06-photos-with-katie/1600x2400_dscf1419-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/1024x1536_dscf1419-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/1600x2400_dscf1419-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/500x750_dscf1419-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-06-photos-with-katie/800x1200_dscf1419-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-06-photos-with-katie/500x750_dscf1419-jpeg.jpeg",
            "width": 2666
         }
      ],
      "slug": "2022-07-06-photos-with-katie",
      "src": "/static/_gallery/albums/2022-07-06-photos-with-katie/500x750_dscf1343-jpeg.jpeg"
   },
   "2022-07-19-king-s-meadow": {
      "name": "2022-07-19 King's Meadow",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1430.jpeg",
            "slug": "dscf1430-jpeg",
            "src": "/static/_gallery/albums/2022-07-19-king-s-meadow/1600x2000_dscf1430-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/1024x1280_dscf1430-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/1600x2000_dscf1430-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/500x625_dscf1430-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/800x1000_dscf1430-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-19-king-s-meadow/500x625_dscf1430-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1437.jpeg",
            "slug": "dscf1437-jpeg",
            "src": "/static/_gallery/albums/2022-07-19-king-s-meadow/1600x2000_dscf1437-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/1024x1280_dscf1437-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/1600x2000_dscf1437-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/500x625_dscf1437-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/800x1000_dscf1437-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-19-king-s-meadow/500x625_dscf1437-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1450.jpeg",
            "slug": "dscf1450-jpeg",
            "src": "/static/_gallery/albums/2022-07-19-king-s-meadow/1600x2000_dscf1450-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/1024x1280_dscf1450-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/1600x2000_dscf1450-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/500x625_dscf1450-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/800x1000_dscf1450-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-19-king-s-meadow/500x625_dscf1450-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1459.jpeg",
            "slug": "dscf1459-jpeg",
            "src": "/static/_gallery/albums/2022-07-19-king-s-meadow/1600x2000_dscf1459-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/1024x1280_dscf1459-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/1600x2000_dscf1459-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/500x625_dscf1459-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-19-king-s-meadow/800x1000_dscf1459-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-19-king-s-meadow/500x625_dscf1459-jpeg.jpeg",
            "width": 3200
         }
      ],
      "slug": "2022-07-19-king-s-meadow",
      "src": "/static/_gallery/albums/2022-07-19-king-s-meadow/500x625_dscf1430-jpeg.jpeg"
   },
   "2022-07-24-katie-s": {
      "name": "2022-07-24 Katie's",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1485.jpeg",
            "slug": "dscf1485-jpeg",
            "src": "/static/_gallery/albums/2022-07-24-katie-s/1600x2400_dscf1485-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1024x1536_dscf1485-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1600x2400_dscf1485-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1485-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/800x1200_dscf1485-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1485-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1491.jpeg",
            "slug": "dscf1491-jpeg",
            "src": "/static/_gallery/albums/2022-07-24-katie-s/1600x1066_dscf1491-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1024x682_dscf1491-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1600x1066_dscf1491-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/500x333_dscf1491-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/800x533_dscf1491-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-24-katie-s/500x333_dscf1491-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1497.jpeg",
            "slug": "dscf1497-jpeg",
            "src": "/static/_gallery/albums/2022-07-24-katie-s/1600x2000_dscf1497-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1024x1280_dscf1497-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1600x2000_dscf1497-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/500x625_dscf1497-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/800x1000_dscf1497-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-24-katie-s/500x625_dscf1497-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1499.jpeg",
            "slug": "dscf1499-jpeg",
            "src": "/static/_gallery/albums/2022-07-24-katie-s/1600x1066_dscf1499-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1024x682_dscf1499-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1600x1066_dscf1499-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/500x333_dscf1499-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/800x533_dscf1499-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-24-katie-s/500x333_dscf1499-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1510.jpeg",
            "slug": "dscf1510-jpeg",
            "src": "/static/_gallery/albums/2022-07-24-katie-s/1600x2400_dscf1510-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1024x1536_dscf1510-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1600x2400_dscf1510-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1510-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/800x1200_dscf1510-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1510-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1526.jpeg",
            "slug": "dscf1526-jpeg",
            "src": "/static/_gallery/albums/2022-07-24-katie-s/1600x2400_dscf1526-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1024x1536_dscf1526-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1600x2400_dscf1526-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1526-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/800x1200_dscf1526-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1526-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1530.jpeg",
            "slug": "dscf1530-jpeg",
            "src": "/static/_gallery/albums/2022-07-24-katie-s/1600x2400_dscf1530-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1024x1536_dscf1530-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1600x2400_dscf1530-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1530-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/800x1200_dscf1530-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1530-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1535.jpeg",
            "slug": "dscf1535-jpeg",
            "src": "/static/_gallery/albums/2022-07-24-katie-s/1600x2400_dscf1535-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1024x1536_dscf1535-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1600x2400_dscf1535-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1535-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/800x1200_dscf1535-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1535-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1542.jpeg",
            "slug": "dscf1542-jpeg",
            "src": "/static/_gallery/albums/2022-07-24-katie-s/1600x1066_dscf1542-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1024x682_dscf1542-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1600x1066_dscf1542-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/500x333_dscf1542-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/800x533_dscf1542-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-24-katie-s/500x333_dscf1542-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1551.jpeg",
            "slug": "dscf1551-jpeg",
            "src": "/static/_gallery/albums/2022-07-24-katie-s/1600x2400_dscf1551-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1024x1536_dscf1551-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/1600x2400_dscf1551-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1551-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-24-katie-s/800x1200_dscf1551-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1551-jpeg.jpeg",
            "width": 2666
         }
      ],
      "slug": "2022-07-24-katie-s",
      "src": "/static/_gallery/albums/2022-07-24-katie-s/500x750_dscf1485-jpeg.jpeg"
   },
   "2022-07-31-henley-wcb": {
      "name": "2022-07-31 Henley WCB",
      "photos": [
         {
            "faces": [],
            "height": 1714,
            "name": "DSCF1588.jpeg",
            "slug": "dscf1588-jpeg",
            "src": "/static/_gallery/albums/2022-07-31-henley-wcb/1600x685_dscf1588-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/1024x438_dscf1588-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/1600x685_dscf1588-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/500x214_dscf1588-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/800x342_dscf1588-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-31-henley-wcb/500x214_dscf1588-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 1714,
            "name": "DSCF1590.jpeg",
            "slug": "dscf1590-jpeg",
            "src": "/static/_gallery/albums/2022-07-31-henley-wcb/1600x685_dscf1590-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/1024x438_dscf1590-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/1600x685_dscf1590-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/500x214_dscf1590-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/800x342_dscf1590-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-31-henley-wcb/500x214_dscf1590-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 1714,
            "name": "DSCF1605.jpeg",
            "slug": "dscf1605-jpeg",
            "src": "/static/_gallery/albums/2022-07-31-henley-wcb/1600x685_dscf1605-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/1024x438_dscf1605-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/1600x685_dscf1605-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/500x214_dscf1605-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/800x342_dscf1605-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-31-henley-wcb/500x214_dscf1605-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 1714,
            "name": "DSCF1621.jpeg",
            "slug": "dscf1621-jpeg",
            "src": "/static/_gallery/albums/2022-07-31-henley-wcb/1600x685_dscf1621-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/1024x438_dscf1621-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/1600x685_dscf1621-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/500x214_dscf1621-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/800x342_dscf1621-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-31-henley-wcb/500x214_dscf1621-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1633.jpeg",
            "slug": "dscf1633-jpeg",
            "src": "/static/_gallery/albums/2022-07-31-henley-wcb/1600x2133_dscf1633-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/1024x1365_dscf1633-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/1600x2133_dscf1633-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/500x666_dscf1633-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-07-31-henley-wcb/800x1066_dscf1633-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-07-31-henley-wcb/500x666_dscf1633-jpeg.jpeg",
            "width": 3000
         }
      ],
      "slug": "2022-07-31-henley-wcb",
      "src": "/static/_gallery/albums/2022-07-31-henley-wcb/500x214_dscf1588-jpeg.jpeg"
   },
   "2022-08-06-bones-wood": {
      "name": "2022-08-06 Bones Wood",
      "photos": [
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1660.jpeg",
            "slug": "dscf1660-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x1066_dscf1660-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x682_dscf1660-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x1066_dscf1660-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x333_dscf1660-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x533_dscf1660-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x333_dscf1660-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1667.jpeg",
            "slug": "dscf1667-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x1066_dscf1667-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x682_dscf1667-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x1066_dscf1667-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x333_dscf1667-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x533_dscf1667-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x333_dscf1667-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1687.jpeg",
            "slug": "dscf1687-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2000_dscf1687-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1280_dscf1687-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2000_dscf1687-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x625_dscf1687-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1000_dscf1687-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x625_dscf1687-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1696.jpeg",
            "slug": "dscf1696-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1696-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1536_dscf1696-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1696-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1696-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1200_dscf1696-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1696-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1700.jpeg",
            "slug": "dscf1700-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2000_dscf1700-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1280_dscf1700-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2000_dscf1700-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x625_dscf1700-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1000_dscf1700-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x625_dscf1700-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1704.jpeg",
            "slug": "dscf1704-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1704-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1536_dscf1704-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1704-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1704-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1200_dscf1704-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1704-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1705.jpeg",
            "slug": "dscf1705-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x1066_dscf1705-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x682_dscf1705-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x1066_dscf1705-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x333_dscf1705-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x533_dscf1705-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x333_dscf1705-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1707.jpeg",
            "slug": "dscf1707-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x1066_dscf1707-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x682_dscf1707-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x1066_dscf1707-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x333_dscf1707-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x533_dscf1707-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x333_dscf1707-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1719.jpeg",
            "slug": "dscf1719-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1719-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1536_dscf1719-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1719-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1719-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1200_dscf1719-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1719-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1723.jpeg",
            "slug": "dscf1723-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1723-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1536_dscf1723-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1723-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1723-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1200_dscf1723-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1723-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1729.jpeg",
            "slug": "dscf1729-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1729-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1536_dscf1729-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1729-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1729-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1200_dscf1729-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1729-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1733.jpeg",
            "slug": "dscf1733-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2000_dscf1733-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1280_dscf1733-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2000_dscf1733-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x625_dscf1733-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1000_dscf1733-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x625_dscf1733-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1736.jpeg",
            "slug": "dscf1736-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1736-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1536_dscf1736-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1736-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1736-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1200_dscf1736-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1736-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1740.jpeg",
            "slug": "dscf1740-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1740-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1536_dscf1740-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1740-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1740-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1200_dscf1740-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1740-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1745.jpeg",
            "slug": "dscf1745-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1745-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1536_dscf1745-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1745-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1745-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1200_dscf1745-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1745-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1750.jpeg",
            "slug": "dscf1750-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1750-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1536_dscf1750-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2400_dscf1750-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1750-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1200_dscf1750-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x750_dscf1750-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1762.jpeg",
            "slug": "dscf1762-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x2000_dscf1762-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x1280_dscf1762-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x2000_dscf1762-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x625_dscf1762-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x1000_dscf1762-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x625_dscf1762-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF1781.jpeg",
            "slug": "dscf1781-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x1280_dscf1781-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x819_dscf1781-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x1280_dscf1781-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x400_dscf1781-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x640_dscf1781-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x400_dscf1781-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 1714,
            "name": "DSCF1784.jpeg",
            "slug": "dscf1784-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x685_dscf1784-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x438_dscf1784-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x685_dscf1784-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x214_dscf1784-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x342_dscf1784-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x214_dscf1784-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 1713,
            "name": "DSCF1786.jpeg",
            "slug": "dscf1786-jpeg",
            "src": "/static/_gallery/albums/2022-08-06-bones-wood/1600x685_dscf1786-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1024x438_dscf1786-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/1600x685_dscf1786-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/500x214_dscf1786-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-06-bones-wood/800x342_dscf1786-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-06-bones-wood/500x214_dscf1786-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-08-06-bones-wood",
      "src": "/static/_gallery/albums/2022-08-06-bones-wood/500x333_dscf1660-jpeg.jpeg"
   },
   "2022-08-15-king-s-meadow": {
      "name": "2022-08-15 King's Meadow",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1817.jpeg",
            "slug": "dscf1817-jpeg",
            "src": "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x2400_dscf1817-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1024x1536_dscf1817-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x2400_dscf1817-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/500x750_dscf1817-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/800x1200_dscf1817-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-15-king-s-meadow/500x750_dscf1817-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1829.jpeg",
            "slug": "dscf1829-jpeg",
            "src": "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x2133_dscf1829-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1024x1365_dscf1829-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x2133_dscf1829-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/500x666_dscf1829-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/800x1066_dscf1829-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-15-king-s-meadow/500x666_dscf1829-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "DSCF1833.jpeg",
            "slug": "dscf1833-jpeg",
            "src": "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x1200_dscf1833-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1024x768_dscf1833-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x1200_dscf1833-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/500x375_dscf1833-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/800x600_dscf1833-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-15-king-s-meadow/500x375_dscf1833-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1844.jpeg",
            "slug": "dscf1844-jpeg",
            "src": "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x2134_dscf1844-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1024x1365_dscf1844-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x2134_dscf1844-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/500x666_dscf1844-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/800x1067_dscf1844-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-15-king-s-meadow/500x666_dscf1844-jpeg.jpeg",
            "width": 2999
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1852.jpeg",
            "slug": "dscf1852-jpeg",
            "src": "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x2134_dscf1852-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1024x1365_dscf1852-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x2134_dscf1852-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/500x666_dscf1852-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/800x1067_dscf1852-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-15-king-s-meadow/500x666_dscf1852-jpeg.jpeg",
            "width": 2999
         },
         {
            "faces": [],
            "height": 3000,
            "name": "DSCF1859.jpeg",
            "slug": "dscf1859-jpeg",
            "src": "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x1200_dscf1859-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1024x768_dscf1859-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x1200_dscf1859-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/500x375_dscf1859-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/800x600_dscf1859-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-15-king-s-meadow/500x375_dscf1859-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1865.jpeg",
            "slug": "dscf1865-jpeg",
            "src": "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x2133_dscf1865-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1024x1365_dscf1865-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/1600x2133_dscf1865-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/500x666_dscf1865-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-15-king-s-meadow/800x1066_dscf1865-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-15-king-s-meadow/500x666_dscf1865-jpeg.jpeg",
            "width": 3000
         }
      ],
      "slug": "2022-08-15-king-s-meadow",
      "src": "/static/_gallery/albums/2022-08-15-king-s-meadow/500x750_dscf1817-jpeg.jpeg"
   },
   "2022-08-19-king-s-meadow": {
      "name": "2022-08-19 King's Meadow",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1911.jpeg",
            "slug": "dscf1911-jpeg",
            "src": "/static/_gallery/albums/2022-08-19-king-s-meadow/1600x2400_dscf1911-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-19-king-s-meadow/1024x1536_dscf1911-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-19-king-s-meadow/1600x2400_dscf1911-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-19-king-s-meadow/500x750_dscf1911-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-19-king-s-meadow/800x1200_dscf1911-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-19-king-s-meadow/500x750_dscf1911-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1913.jpeg",
            "slug": "dscf1913-jpeg",
            "src": "/static/_gallery/albums/2022-08-19-king-s-meadow/1600x2400_dscf1913-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-19-king-s-meadow/1024x1536_dscf1913-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-19-king-s-meadow/1600x2400_dscf1913-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-19-king-s-meadow/500x750_dscf1913-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-19-king-s-meadow/800x1200_dscf1913-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-19-king-s-meadow/500x750_dscf1913-jpeg.jpeg",
            "width": 2666
         }
      ],
      "slug": "2022-08-19-king-s-meadow",
      "src": "/static/_gallery/albums/2022-08-19-king-s-meadow/500x750_dscf1911-jpeg.jpeg"
   },
   "2022-08-20-katie-s": {
      "name": "2022-08-20 Katie's",
      "photos": [
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF1942.jpeg",
            "slug": "dscf1942-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x1199_dscf1942-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x767_dscf1942-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x1199_dscf1942-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x374_dscf1942-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x599_dscf1942-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x374_dscf1942-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF1954.jpeg",
            "slug": "dscf1954-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x1199_dscf1954-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x767_dscf1954-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x1199_dscf1954-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x374_dscf1954-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x599_dscf1954-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x374_dscf1954-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1962.jpeg",
            "slug": "dscf1962-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x2133_dscf1962-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x1365_dscf1962-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x2133_dscf1962-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x666_dscf1962-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x1066_dscf1962-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x666_dscf1962-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF1985.jpeg",
            "slug": "dscf1985-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x1066_dscf1985-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x682_dscf1985-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x1066_dscf1985-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x333_dscf1985-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x533_dscf1985-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x333_dscf1985-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF1993.jpeg",
            "slug": "dscf1993-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x2400_dscf1993-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x1536_dscf1993-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x2400_dscf1993-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x750_dscf1993-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x1200_dscf1993-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x750_dscf1993-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2015.jpeg",
            "slug": "dscf2015-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x1066_dscf2015-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x682_dscf2015-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x1066_dscf2015-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x333_dscf2015-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x533_dscf2015-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x333_dscf2015-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF2035.jpeg",
            "slug": "dscf2035-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x900_dscf2035-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x576_dscf2035-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x900_dscf2035-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x281_dscf2035-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x450_dscf2035-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x281_dscf2035-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2055.jpeg",
            "slug": "dscf2055-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x1066_dscf2055-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x682_dscf2055-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x1066_dscf2055-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x333_dscf2055-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x533_dscf2055-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x333_dscf2055-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "DSCF2074.jpeg",
            "slug": "dscf2074-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x1200_dscf2074-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x768_dscf2074-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x1200_dscf2074-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x375_dscf2074-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x600_dscf2074-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x375_dscf2074-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF2098.jpeg",
            "slug": "dscf2098-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x2133_dscf2098-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x1365_dscf2098-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x2133_dscf2098-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x666_dscf2098-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x1066_dscf2098-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x666_dscf2098-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "DSCF2128.jpeg",
            "slug": "dscf2128-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x1200_dscf2128-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x768_dscf2128-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x1200_dscf2128-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x375_dscf2128-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x600_dscf2128-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x375_dscf2128-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "DSCF2138.jpeg",
            "slug": "dscf2138-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x1200_dscf2138-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x768_dscf2138-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x1200_dscf2138-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x375_dscf2138-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x600_dscf2138-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x375_dscf2138-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2249,
            "name": "DSCF2159.jpeg",
            "slug": "dscf2159-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x899_dscf2159-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x575_dscf2159-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x899_dscf2159-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x281_dscf2159-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x449_dscf2159-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x281_dscf2159-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF2173.jpeg",
            "slug": "dscf2173-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x2133_dscf2173-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x1365_dscf2173-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x2133_dscf2173-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x666_dscf2173-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x1066_dscf2173-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x666_dscf2173-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF2179.jpeg",
            "slug": "dscf2179-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x1199_dscf2179-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x767_dscf2179-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x1199_dscf2179-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x374_dscf2179-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x599_dscf2179-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x374_dscf2179-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2181.jpeg",
            "slug": "dscf2181-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x1066_dscf2181-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x682_dscf2181-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x1066_dscf2181-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x333_dscf2181-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x533_dscf2181-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x333_dscf2181-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2196.jpeg",
            "slug": "dscf2196-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x1066_dscf2196-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x682_dscf2196-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x1066_dscf2196-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x333_dscf2196-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x533_dscf2196-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x333_dscf2196-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF2204.jpeg",
            "slug": "dscf2204-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x2400_dscf2204-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x1536_dscf2204-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x2400_dscf2204-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x750_dscf2204-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x1200_dscf2204-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x750_dscf2204-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 1713,
            "name": "DSCF2227.jpeg",
            "slug": "dscf2227-jpeg",
            "src": "/static/_gallery/albums/2022-08-20-katie-s/1600x685_dscf2227-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1024x438_dscf2227-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/1600x685_dscf2227-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/500x214_dscf2227-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-20-katie-s/800x342_dscf2227-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-20-katie-s/500x214_dscf2227-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-08-20-katie-s",
      "src": "/static/_gallery/albums/2022-08-20-katie-s/500x374_dscf1942-jpeg.jpeg"
   },
   "2022-08-28-frensham-pond": {
      "name": "2022-08-28 Frensham Pond",
      "photos": [
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2517.jpeg",
            "slug": "dscf2517-jpeg",
            "src": "/static/_gallery/albums/2022-08-28-frensham-pond/1600x1066_dscf2517-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/1024x682_dscf2517-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/1600x1066_dscf2517-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/500x333_dscf2517-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/800x533_dscf2517-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-28-frensham-pond/500x333_dscf2517-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2521.jpeg",
            "slug": "dscf2521-jpeg",
            "src": "/static/_gallery/albums/2022-08-28-frensham-pond/1600x1066_dscf2521-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/1024x682_dscf2521-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/1600x1066_dscf2521-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/500x333_dscf2521-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/800x533_dscf2521-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-28-frensham-pond/500x333_dscf2521-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2536.jpeg",
            "slug": "dscf2536-jpeg",
            "src": "/static/_gallery/albums/2022-08-28-frensham-pond/1600x1066_dscf2536-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/1024x682_dscf2536-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/1600x1066_dscf2536-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/500x333_dscf2536-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/800x533_dscf2536-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-28-frensham-pond/500x333_dscf2536-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2543.jpeg",
            "slug": "dscf2543-jpeg",
            "src": "/static/_gallery/albums/2022-08-28-frensham-pond/1600x1066_dscf2543-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/1024x682_dscf2543-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/1600x1066_dscf2543-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/500x333_dscf2543-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/800x533_dscf2543-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-28-frensham-pond/500x333_dscf2543-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2552.jpeg",
            "slug": "dscf2552-jpeg",
            "src": "/static/_gallery/albums/2022-08-28-frensham-pond/1600x1066_dscf2552-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/1024x682_dscf2552-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/1600x1066_dscf2552-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/500x333_dscf2552-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-08-28-frensham-pond/800x533_dscf2552-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-08-28-frensham-pond/500x333_dscf2552-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-08-28-frensham-pond",
      "src": "/static/_gallery/albums/2022-08-28-frensham-pond/500x333_dscf2517-jpeg.jpeg"
   },
   "2022-09-03-annie-s-wedding": {
      "name": "2022-09-03 Annie's wedding",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF2643.jpeg",
            "slug": "dscf2643-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x2133_dscf2643-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x1365_dscf2643-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x2133_dscf2643-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x666_dscf2643-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x1066_dscf2643-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x666_dscf2643-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2665.jpeg",
            "slug": "dscf2665-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1066_dscf2665-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x682_dscf2665-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1066_dscf2665-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x333_dscf2665-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x533_dscf2665-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x333_dscf2665-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF2677.jpeg",
            "slug": "dscf2677-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x2133_dscf2677-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x1365_dscf2677-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x2133_dscf2677-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x666_dscf2677-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x1066_dscf2677-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x666_dscf2677-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF2697.jpeg",
            "slug": "dscf2697-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1199_dscf2697-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x767_dscf2697-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1199_dscf2697-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x374_dscf2697-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x599_dscf2697-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x374_dscf2697-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF2705.jpeg",
            "slug": "dscf2705-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x2400_dscf2705-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x1536_dscf2705-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x2400_dscf2705-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x750_dscf2705-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x1200_dscf2705-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x750_dscf2705-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF2712.jpeg",
            "slug": "dscf2712-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x2134_dscf2712-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x1365_dscf2712-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x2134_dscf2712-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x666_dscf2712-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x1067_dscf2712-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x666_dscf2712-jpeg.jpeg",
            "width": 2999
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF2724.jpeg",
            "slug": "dscf2724-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x2133_dscf2724-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x1365_dscf2724-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x2133_dscf2724-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x666_dscf2724-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x1066_dscf2724-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x666_dscf2724-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF2751.jpeg",
            "slug": "dscf2751-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1199_dscf2751-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x767_dscf2751-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1199_dscf2751-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x374_dscf2751-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x599_dscf2751-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x374_dscf2751-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2757.jpeg",
            "slug": "dscf2757-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1066_dscf2757-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x682_dscf2757-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1066_dscf2757-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x333_dscf2757-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x533_dscf2757-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x333_dscf2757-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF2783.jpeg",
            "slug": "dscf2783-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1199_dscf2783-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x767_dscf2783-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1199_dscf2783-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x374_dscf2783-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x599_dscf2783-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x374_dscf2783-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF2799.jpeg",
            "slug": "dscf2799-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x2134_dscf2799-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x1365_dscf2799-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x2134_dscf2799-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x666_dscf2799-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x1067_dscf2799-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x666_dscf2799-jpeg.jpeg",
            "width": 2999
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2806.jpeg",
            "slug": "dscf2806-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1066_dscf2806-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x682_dscf2806-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1066_dscf2806-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x333_dscf2806-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x533_dscf2806-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x333_dscf2806-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2816.jpeg",
            "slug": "dscf2816-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1066_dscf2816-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x682_dscf2816-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1066_dscf2816-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x333_dscf2816-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x533_dscf2816-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x333_dscf2816-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF2824.jpeg",
            "slug": "dscf2824-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1199_dscf2824-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x767_dscf2824-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1199_dscf2824-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x374_dscf2824-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x599_dscf2824-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x374_dscf2824-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF2844.jpeg",
            "slug": "dscf2844-jpeg",
            "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1600_dscf2844-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1024x1024_dscf2844-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/1600x1600_dscf2844-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x500_dscf2844-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-03-annie-s-wedding/800x800_dscf2844-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x500_dscf2844-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-09-03-annie-s-wedding",
      "src": "/static/_gallery/albums/2022-09-03-annie-s-wedding/500x666_dscf2643-jpeg.jpeg"
   },
   "2022-09-09-wwt-arundel": {
      "name": "2022-09-09 WWT Arundel",
      "photos": [
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF2954.jpeg",
            "slug": "dscf2954-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf2954-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf2954-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf2954-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf2954-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf2954-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf2954-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3014.jpeg",
            "slug": "dscf3014-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3014-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3014-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3014-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3014-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3014-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3014-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3101.jpeg",
            "slug": "dscf3101-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2134_dscf3101-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1365_dscf3101-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2134_dscf3101-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x666_dscf3101-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1067_dscf3101-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x666_dscf3101-jpeg.jpeg",
            "width": 2999
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3124.jpeg",
            "slug": "dscf3124-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3124-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1536_dscf3124-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3124-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3124-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1200_dscf3124-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3124-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3137.jpeg",
            "slug": "dscf3137-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3137-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1536_dscf3137-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3137-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3137-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1200_dscf3137-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3137-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3145.jpeg",
            "slug": "dscf3145-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3145-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1536_dscf3145-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3145-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3145-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1200_dscf3145-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3145-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3149.jpeg",
            "slug": "dscf3149-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3149-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3149-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3149-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3149-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3149-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3149-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3150.jpeg",
            "slug": "dscf3150-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3150-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1536_dscf3150-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3150-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3150-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1200_dscf3150-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3150-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3151.jpeg",
            "slug": "dscf3151-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3151-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3151-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3151-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3151-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3151-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3151-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3158.jpeg",
            "slug": "dscf3158-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3158-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1536_dscf3158-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3158-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3158-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1200_dscf3158-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3158-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3165.jpeg",
            "slug": "dscf3165-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3165-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3165-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3165-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3165-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3165-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3165-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3167.jpeg",
            "slug": "dscf3167-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2134_dscf3167-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1365_dscf3167-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2134_dscf3167-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x666_dscf3167-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1067_dscf3167-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x666_dscf3167-jpeg.jpeg",
            "width": 2999
         },
         {
            "faces": [],
            "height": 2249,
            "name": "DSCF3175.jpeg",
            "slug": "dscf3175-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x899_dscf3175-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x575_dscf3175-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x899_dscf3175-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x281_dscf3175-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x449_dscf3175-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x281_dscf3175-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3191.jpeg",
            "slug": "dscf3191-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3191-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1536_dscf3191-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3191-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3191-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1200_dscf3191-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3191-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3196.jpeg",
            "slug": "dscf3196-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3196-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1536_dscf3196-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3196-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3196-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1200_dscf3196-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3196-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3200.jpeg",
            "slug": "dscf3200-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3200-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1536_dscf3200-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3200-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3200-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1200_dscf3200-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3200-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3209.jpeg",
            "slug": "dscf3209-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3209-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3209-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3209-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3209-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3209-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3209-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3211.jpeg",
            "slug": "dscf3211-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3211-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1536_dscf3211-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2400_dscf3211-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3211-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1200_dscf3211-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x750_dscf3211-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3214.jpeg",
            "slug": "dscf3214-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3214-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3214-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3214-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3214-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3214-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3214-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3218.jpeg",
            "slug": "dscf3218-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3218-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3218-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3218-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3218-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3218-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3218-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF3224.jpeg",
            "slug": "dscf3224-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x900_dscf3224-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x576_dscf3224-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x900_dscf3224-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x281_dscf3224-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x450_dscf3224-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x281_dscf3224-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2249,
            "name": "DSCF3227.jpeg",
            "slug": "dscf3227-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x899_dscf3227-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x575_dscf3227-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x899_dscf3227-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x281_dscf3227-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x449_dscf3227-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x281_dscf3227-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3259.jpeg",
            "slug": "dscf3259-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3259-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3259-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3259-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3259-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3259-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3259-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3263.jpeg",
            "slug": "dscf3263-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3263-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3263-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3263-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3263-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3263-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3263-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2667,
            "name": "DSCF3270.jpeg",
            "slug": "dscf3270-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3270-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3270-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3270-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3270-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3270-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3270-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "DSCF3271.jpeg",
            "slug": "dscf3271-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1200_dscf3271-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x768_dscf3271-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1200_dscf3271-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x375_dscf3271-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x600_dscf3271-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x375_dscf3271-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3278.jpeg",
            "slug": "dscf3278-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3278-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3278-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3278-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3278-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3278-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3278-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF3281.jpeg",
            "slug": "dscf3281-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1199_dscf3281-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x767_dscf3281-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1199_dscf3281-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x374_dscf3281-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x599_dscf3281-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x374_dscf3281-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF3296.jpeg",
            "slug": "dscf3296-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1199_dscf3296-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x767_dscf3296-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1199_dscf3296-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x374_dscf3296-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x599_dscf3296-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x374_dscf3296-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF3305.jpeg",
            "slug": "dscf3305-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1199_dscf3305-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x767_dscf3305-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1199_dscf3305-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x374_dscf3305-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x599_dscf3305-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x374_dscf3305-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF3308.jpeg",
            "slug": "dscf3308-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1199_dscf3308-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x767_dscf3308-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1199_dscf3308-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x374_dscf3308-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x599_dscf3308-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x374_dscf3308-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF3313.jpeg",
            "slug": "dscf3313-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x900_dscf3313-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x576_dscf3313-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x900_dscf3313-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x281_dscf3313-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x450_dscf3313-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x281_dscf3313-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3319.jpeg",
            "slug": "dscf3319-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3319-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3319-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3319-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3319-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3319-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3319-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF3321.jpeg",
            "slug": "dscf3321-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1199_dscf3321-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x767_dscf3321-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1199_dscf3321-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x374_dscf3321-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x599_dscf3321-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x374_dscf3321-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF3326.jpeg",
            "slug": "dscf3326-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1199_dscf3326-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x767_dscf3326-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1199_dscf3326-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x374_dscf3326-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x599_dscf3326-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x374_dscf3326-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3339.jpeg",
            "slug": "dscf3339-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3339-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3339-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3339-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3339-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3339-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3339-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3348.jpeg",
            "slug": "dscf3348-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2134_dscf3348-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1365_dscf3348-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2134_dscf3348-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x666_dscf3348-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1067_dscf3348-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x666_dscf3348-jpeg.jpeg",
            "width": 2999
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3367.jpeg",
            "slug": "dscf3367-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3367-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3367-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3367-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3367-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3367-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3367-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3376.jpeg",
            "slug": "dscf3376-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3376-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3376-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3376-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3376-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3376-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3376-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3377.jpeg",
            "slug": "dscf3377-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3377-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3377-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3377-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3377-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3377-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3377-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3384.jpeg",
            "slug": "dscf3384-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3384-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3384-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3384-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3384-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3384-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3384-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3418.jpeg",
            "slug": "dscf3418-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2134_dscf3418-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1365_dscf3418-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2134_dscf3418-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x666_dscf3418-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1067_dscf3418-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x666_dscf3418-jpeg.jpeg",
            "width": 2999
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3423.jpeg",
            "slug": "dscf3423-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3423-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3423-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3423-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3423-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3423-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3423-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3424.jpeg",
            "slug": "dscf3424-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3424-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3424-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3424-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3424-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3424-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3424-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3432.jpeg",
            "slug": "dscf3432-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3432-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3432-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3432-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3432-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3432-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3432-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3433.jpeg",
            "slug": "dscf3433-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3433-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3433-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3433-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3433-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3433-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3433-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3455.jpeg",
            "slug": "dscf3455-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2133_dscf3455-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x1365_dscf3455-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x2133_dscf3455-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x666_dscf3455-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x1066_dscf3455-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x666_dscf3455-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "DSCF3456.jpeg",
            "slug": "dscf3456-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1200_dscf3456-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x768_dscf3456-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1200_dscf3456-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x375_dscf3456-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x600_dscf3456-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x375_dscf3456-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3461.jpeg",
            "slug": "dscf3461-jpeg",
            "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3461-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1024x682_dscf3461-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/1600x1066_dscf3461-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3461-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-09-wwt-arundel/800x533_dscf3461-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf3461-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-09-09-wwt-arundel",
      "src": "/static/_gallery/albums/2022-09-09-wwt-arundel/500x333_dscf2954-jpeg.jpeg"
   },
   "2022-09-11-rising-sun": {
      "name": "2022-09-11 Rising Sun",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3522.jpeg",
            "slug": "dscf3522-jpeg",
            "src": "/static/_gallery/albums/2022-09-11-rising-sun/1600x1600_dscf3522-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/1024x1024_dscf3522-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/1600x1600_dscf3522-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/500x500_dscf3522-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/800x800_dscf3522-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-11-rising-sun/500x500_dscf3522-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3539.jpeg",
            "slug": "dscf3539-jpeg",
            "src": "/static/_gallery/albums/2022-09-11-rising-sun/1600x1066_dscf3539-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/1024x682_dscf3539-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/1600x1066_dscf3539-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/500x333_dscf3539-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/800x533_dscf3539-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-11-rising-sun/500x333_dscf3539-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3578.jpeg",
            "slug": "dscf3578-jpeg",
            "src": "/static/_gallery/albums/2022-09-11-rising-sun/1600x2400_dscf3578-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/1024x1536_dscf3578-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/1600x2400_dscf3578-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/500x750_dscf3578-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/800x1200_dscf3578-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-11-rising-sun/500x750_dscf3578-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF3584.jpeg",
            "slug": "dscf3584-jpeg",
            "src": "/static/_gallery/albums/2022-09-11-rising-sun/1600x1199_dscf3584-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/1024x767_dscf3584-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/1600x1199_dscf3584-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/500x374_dscf3584-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/800x599_dscf3584-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-11-rising-sun/500x374_dscf3584-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3629.jpeg",
            "slug": "dscf3629-jpeg",
            "src": "/static/_gallery/albums/2022-09-11-rising-sun/1600x2134_dscf3629-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/1024x1365_dscf3629-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/1600x2134_dscf3629-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/500x666_dscf3629-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-11-rising-sun/800x1067_dscf3629-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-11-rising-sun/500x666_dscf3629-jpeg.jpeg",
            "width": 2999
         }
      ],
      "slug": "2022-09-11-rising-sun",
      "src": "/static/_gallery/albums/2022-09-11-rising-sun/500x500_dscf3522-jpeg.jpeg"
   },
   "2022-09-15-squad-day": {
      "name": "2022-09-15 Squad day",
      "photos": [
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3663.jpeg",
            "slug": "dscf3663-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3663-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x682_dscf3663-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3663-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3663-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x533_dscf3663-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3663-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3666.jpeg",
            "slug": "dscf3666-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3666-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x682_dscf3666-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3666-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3666-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x533_dscf3666-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3666-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3668.jpeg",
            "slug": "dscf3668-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3668-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x682_dscf3668-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3668-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3668-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x533_dscf3668-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3668-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3674.jpeg",
            "slug": "dscf3674-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x2400_dscf3674-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x1536_dscf3674-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x2400_dscf3674-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x750_dscf3674-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x1200_dscf3674-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x750_dscf3674-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3679.jpeg",
            "slug": "dscf3679-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3679-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x682_dscf3679-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3679-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3679-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x533_dscf3679-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3679-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3682.jpeg",
            "slug": "dscf3682-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3682-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x682_dscf3682-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3682-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3682-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x533_dscf3682-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3682-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2676,
            "name": "DSCF3717.jpeg",
            "slug": "dscf3717-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x1070_dscf3717-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x685_dscf3717-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x1070_dscf3717-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x334_dscf3717-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x535_dscf3717-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x334_dscf3717-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3731.jpeg",
            "slug": "dscf3731-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3731-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x682_dscf3731-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3731-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3731-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x533_dscf3731-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3731-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3734.jpeg",
            "slug": "dscf3734-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3734-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x682_dscf3734-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3734-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3734-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x533_dscf3734-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3734-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3748.jpeg",
            "slug": "dscf3748-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3748-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x682_dscf3748-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3748-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3748-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x533_dscf3748-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3748-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3764.jpeg",
            "slug": "dscf3764-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3764-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x682_dscf3764-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3764-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3764-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x533_dscf3764-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3764-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF3779.jpeg",
            "slug": "dscf3779-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x900_dscf3779-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x576_dscf3779-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x900_dscf3779-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x281_dscf3779-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x450_dscf3779-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x281_dscf3779-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3792.jpeg",
            "slug": "dscf3792-jpeg",
            "src": "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3792-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1024x682_dscf3792-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/1600x1066_dscf3792-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3792-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-15-squad-day/800x533_dscf3792-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3792-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-09-15-squad-day",
      "src": "/static/_gallery/albums/2022-09-15-squad-day/500x333_dscf3663-jpeg.jpeg"
   },
   "2022-09-17-katie-s": {
      "name": "2022-09-17 Katie's",
      "photos": [
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF3817.jpeg",
            "slug": "dscf3817-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1280_dscf3817-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x819_dscf3817-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1280_dscf3817-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x400_dscf3817-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x640_dscf3817-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x400_dscf3817-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF3819.jpeg",
            "slug": "dscf3819-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1280_dscf3819-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x819_dscf3819-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1280_dscf3819-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x400_dscf3819-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x640_dscf3819-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x400_dscf3819-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3823.jpeg",
            "slug": "dscf3823-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf3823-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x1280_dscf3823-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf3823-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf3823-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x1000_dscf3823-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf3823-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3824.jpeg",
            "slug": "dscf3824-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf3824-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x1280_dscf3824-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf3824-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf3824-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x1000_dscf3824-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf3824-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 3199,
            "name": "DSCF3831.jpeg",
            "slug": "dscf3831-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1279_dscf3831-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x818_dscf3831-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1279_dscf3831-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x399_dscf3831-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x639_dscf3831-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x399_dscf3831-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3836.jpeg",
            "slug": "dscf3836-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf3836-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x1280_dscf3836-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf3836-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf3836-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x1000_dscf3836-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf3836-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3846.jpeg",
            "slug": "dscf3846-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf3846-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x682_dscf3846-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf3846-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf3846-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x533_dscf3846-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf3846-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2676,
            "name": "DSCF3868.jpeg",
            "slug": "dscf3868-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1070_dscf3868-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x685_dscf3868-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1070_dscf3868-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x334_dscf3868-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x535_dscf3868-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x334_dscf3868-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3887.jpeg",
            "slug": "dscf3887-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf3887-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x682_dscf3887-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf3887-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf3887-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x533_dscf3887-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf3887-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF3909.jpeg",
            "slug": "dscf3909-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf3909-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x1280_dscf3909-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf3909-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf3909-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x1000_dscf3909-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf3909-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3912.jpeg",
            "slug": "dscf3912-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf3912-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x682_dscf3912-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf3912-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf3912-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x533_dscf3912-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf3912-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3915.jpeg",
            "slug": "dscf3915-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf3915-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x682_dscf3915-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf3915-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf3915-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x533_dscf3915-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf3915-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF3923.jpeg",
            "slug": "dscf3923-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf3923-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x682_dscf3923-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf3923-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf3923-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x533_dscf3923-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf3923-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4000.jpeg",
            "slug": "dscf4000-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf4000-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x1280_dscf4000-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf4000-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf4000-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x1000_dscf4000-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf4000-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF4011.jpeg",
            "slug": "dscf4011-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1280_dscf4011-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x819_dscf4011-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1280_dscf4011-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x400_dscf4011-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x640_dscf4011-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x400_dscf4011-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4033.jpeg",
            "slug": "dscf4033-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf4033-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x1280_dscf4033-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf4033-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf4033-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x1000_dscf4033-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf4033-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4056.jpeg",
            "slug": "dscf4056-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x2400_dscf4056-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x1536_dscf4056-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x2400_dscf4056-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x750_dscf4056-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x1200_dscf4056-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x750_dscf4056-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF4068.jpeg",
            "slug": "dscf4068-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1280_dscf4068-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x819_dscf4068-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1280_dscf4068-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x400_dscf4068-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x640_dscf4068-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x400_dscf4068-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4077.jpeg",
            "slug": "dscf4077-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf4077-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x682_dscf4077-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf4077-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf4077-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x533_dscf4077-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf4077-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4103.jpeg",
            "slug": "dscf4103-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf4103-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x682_dscf4103-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1066_dscf4103-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf4103-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x533_dscf4103-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x333_dscf4103-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4141.jpeg",
            "slug": "dscf4141-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x2400_dscf4141-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x1536_dscf4141-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x2400_dscf4141-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x750_dscf4141-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x1200_dscf4141-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x750_dscf4141-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF4163.jpeg",
            "slug": "dscf4163-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x900_dscf4163-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x576_dscf4163-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x900_dscf4163-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x281_dscf4163-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x450_dscf4163-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x281_dscf4163-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4196.jpeg",
            "slug": "dscf4196-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x2400_dscf4196-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x1536_dscf4196-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x2400_dscf4196-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x750_dscf4196-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x1200_dscf4196-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x750_dscf4196-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4208.jpeg",
            "slug": "dscf4208-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x2400_dscf4208-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x1536_dscf4208-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x2400_dscf4208-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x750_dscf4208-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x1200_dscf4208-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x750_dscf4208-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF4216.jpeg",
            "slug": "dscf4216-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x1280_dscf4216-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x819_dscf4216-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x1280_dscf4216-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x400_dscf4216-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x640_dscf4216-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x400_dscf4216-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4236.jpeg",
            "slug": "dscf4236-jpeg",
            "src": "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf4236-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1024x1280_dscf4236-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/1600x2000_dscf4236-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf4236-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-17-katie-s/800x1000_dscf4236-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-17-katie-s/500x625_dscf4236-jpeg.jpeg",
            "width": 3200
         }
      ],
      "slug": "2022-09-17-katie-s",
      "src": "/static/_gallery/albums/2022-09-17-katie-s/500x400_dscf3817-jpeg.jpeg"
   },
   "2022-09-29-prufrock": {
      "name": "2022-09-29 Prufrock",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4301.jpeg",
            "slug": "dscf4301-jpeg",
            "src": "/static/_gallery/albums/2022-09-29-prufrock/1600x2000_dscf4301-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1024x1280_dscf4301-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1600x2000_dscf4301-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/500x625_dscf4301-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/800x1000_dscf4301-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-29-prufrock/500x625_dscf4301-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF4306.jpeg",
            "slug": "dscf4306-jpeg",
            "src": "/static/_gallery/albums/2022-09-29-prufrock/1600x900_dscf4306-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1024x576_dscf4306-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1600x900_dscf4306-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/500x281_dscf4306-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/800x450_dscf4306-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-29-prufrock/500x281_dscf4306-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4311.jpeg",
            "slug": "dscf4311-jpeg",
            "src": "/static/_gallery/albums/2022-09-29-prufrock/1600x2400_dscf4311-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1024x1536_dscf4311-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1600x2400_dscf4311-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/500x750_dscf4311-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/800x1200_dscf4311-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-29-prufrock/500x750_dscf4311-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4329.jpeg",
            "slug": "dscf4329-jpeg",
            "src": "/static/_gallery/albums/2022-09-29-prufrock/1600x2000_dscf4329-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1024x1280_dscf4329-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1600x2000_dscf4329-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/500x625_dscf4329-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/800x1000_dscf4329-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-29-prufrock/500x625_dscf4329-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF4336.jpeg",
            "slug": "dscf4336-jpeg",
            "src": "/static/_gallery/albums/2022-09-29-prufrock/1600x1280_dscf4336-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1024x819_dscf4336-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1600x1280_dscf4336-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/500x400_dscf4336-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/800x640_dscf4336-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-29-prufrock/500x400_dscf4336-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4354.jpeg",
            "slug": "dscf4354-jpeg",
            "src": "/static/_gallery/albums/2022-09-29-prufrock/1600x2000_dscf4354-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1024x1280_dscf4354-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1600x2000_dscf4354-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/500x625_dscf4354-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/800x1000_dscf4354-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-29-prufrock/500x625_dscf4354-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF4357.jpeg",
            "slug": "dscf4357-jpeg",
            "src": "/static/_gallery/albums/2022-09-29-prufrock/1600x1280_dscf4357-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1024x819_dscf4357-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1600x1280_dscf4357-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/500x400_dscf4357-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/800x640_dscf4357-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-29-prufrock/500x400_dscf4357-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4362.jpeg",
            "slug": "dscf4362-jpeg",
            "src": "/static/_gallery/albums/2022-09-29-prufrock/1600x2000_dscf4362-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1024x1280_dscf4362-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1600x2000_dscf4362-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/500x625_dscf4362-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/800x1000_dscf4362-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-29-prufrock/500x625_dscf4362-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4375.jpeg",
            "slug": "dscf4375-jpeg",
            "src": "/static/_gallery/albums/2022-09-29-prufrock/1600x1066_dscf4375-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1024x682_dscf4375-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/1600x1066_dscf4375-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/500x333_dscf4375-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-09-29-prufrock/800x533_dscf4375-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-09-29-prufrock/500x333_dscf4375-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-09-29-prufrock",
      "src": "/static/_gallery/albums/2022-09-29-prufrock/500x625_dscf4301-jpeg.jpeg"
   },
   "2022-10-20-flight-club": {
      "name": "2022-10-20 Flight Club",
      "photos": [
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4481.jpeg",
            "slug": "dscf4481-jpeg",
            "src": "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4481-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1024x682_dscf4481-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4481-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4481-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/800x533_dscf4481-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4481-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4485.jpeg",
            "slug": "dscf4485-jpeg",
            "src": "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4485-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1024x682_dscf4485-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4485-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4485-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/800x533_dscf4485-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4485-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4497.jpeg",
            "slug": "dscf4497-jpeg",
            "src": "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4497-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1024x682_dscf4497-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4497-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4497-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/800x533_dscf4497-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4497-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4502.jpeg",
            "slug": "dscf4502-jpeg",
            "src": "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4502-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1024x682_dscf4502-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4502-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4502-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/800x533_dscf4502-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4502-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4526.jpeg",
            "slug": "dscf4526-jpeg",
            "src": "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4526-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1024x682_dscf4526-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4526-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4526-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/800x533_dscf4526-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4526-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4527.jpeg",
            "slug": "dscf4527-jpeg",
            "src": "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4527-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1024x682_dscf4527-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4527-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4527-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/800x533_dscf4527-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4527-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4537.jpeg",
            "slug": "dscf4537-jpeg",
            "src": "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4537-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1024x682_dscf4537-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4537-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4537-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/800x533_dscf4537-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4537-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4544.jpeg",
            "slug": "dscf4544-jpeg",
            "src": "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4544-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1024x682_dscf4544-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4544-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4544-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/800x533_dscf4544-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4544-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4551.jpeg",
            "slug": "dscf4551-jpeg",
            "src": "/static/_gallery/albums/2022-10-20-flight-club/1600x2400_dscf4551-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1024x1536_dscf4551-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1600x2400_dscf4551-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/500x750_dscf4551-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/800x1200_dscf4551-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-20-flight-club/500x750_dscf4551-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4557.jpeg",
            "slug": "dscf4557-jpeg",
            "src": "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4557-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1024x682_dscf4557-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/1600x1066_dscf4557-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4557-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-20-flight-club/800x533_dscf4557-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4557-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-10-20-flight-club",
      "src": "/static/_gallery/albums/2022-10-20-flight-club/500x333_dscf4481-jpeg.jpeg"
   },
   "2022-10-28-chadshunt": {
      "name": "2022-10-28 Chadshunt",
      "photos": [
         {
            "faces": [],
            "height": 2249,
            "name": "DSCF4644.jpeg",
            "slug": "dscf4644-jpeg",
            "src": "/static/_gallery/albums/2022-10-28-chadshunt/1600x899_dscf4644-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1024x575_dscf4644-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1600x899_dscf4644-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/500x281_dscf4644-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/800x449_dscf4644-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-28-chadshunt/500x281_dscf4644-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF4646.jpeg",
            "slug": "dscf4646-jpeg",
            "src": "/static/_gallery/albums/2022-10-28-chadshunt/1600x900_dscf4646-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1024x576_dscf4646-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1600x900_dscf4646-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/500x281_dscf4646-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/800x450_dscf4646-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-28-chadshunt/500x281_dscf4646-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4650.jpeg",
            "slug": "dscf4650-jpeg",
            "src": "/static/_gallery/albums/2022-10-28-chadshunt/1600x1066_dscf4650-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1024x682_dscf4650-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1600x1066_dscf4650-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/500x333_dscf4650-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/800x533_dscf4650-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-28-chadshunt/500x333_dscf4650-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3199,
            "name": "DSCF4695.jpeg",
            "slug": "dscf4695-jpeg",
            "src": "/static/_gallery/albums/2022-10-28-chadshunt/1600x1279_dscf4695-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1024x818_dscf4695-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1600x1279_dscf4695-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/500x399_dscf4695-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/800x639_dscf4695-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-28-chadshunt/500x399_dscf4695-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2249,
            "name": "DSCF4697.jpeg",
            "slug": "dscf4697-jpeg",
            "src": "/static/_gallery/albums/2022-10-28-chadshunt/1600x899_dscf4697-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1024x575_dscf4697-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1600x899_dscf4697-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/500x281_dscf4697-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/800x449_dscf4697-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-28-chadshunt/500x281_dscf4697-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4727.jpeg",
            "slug": "dscf4727-jpeg",
            "src": "/static/_gallery/albums/2022-10-28-chadshunt/1600x2000_dscf4727-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1024x1280_dscf4727-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1600x2000_dscf4727-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/500x625_dscf4727-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/800x1000_dscf4727-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-28-chadshunt/500x625_dscf4727-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4731.jpeg",
            "slug": "dscf4731-jpeg",
            "src": "/static/_gallery/albums/2022-10-28-chadshunt/1600x1066_dscf4731-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1024x682_dscf4731-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1600x1066_dscf4731-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/500x333_dscf4731-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/800x533_dscf4731-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-28-chadshunt/500x333_dscf4731-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4738.jpeg",
            "slug": "dscf4738-jpeg",
            "src": "/static/_gallery/albums/2022-10-28-chadshunt/1600x1066_dscf4738-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1024x682_dscf4738-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/1600x1066_dscf4738-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/500x333_dscf4738-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-10-28-chadshunt/800x533_dscf4738-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-10-28-chadshunt/500x333_dscf4738-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-10-28-chadshunt",
      "src": "/static/_gallery/albums/2022-10-28-chadshunt/500x281_dscf4644-jpeg.jpeg"
   },
   "2022-11-03-rapsa": {
      "name": "2022-11-03 Rapsa",
      "photos": [
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF4782.jpeg",
            "slug": "dscf4782-jpeg",
            "src": "/static/_gallery/albums/2022-11-03-rapsa/1600x1199_dscf4782-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/1024x767_dscf4782-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/1600x1199_dscf4782-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/500x374_dscf4782-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/800x599_dscf4782-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-03-rapsa/500x374_dscf4782-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2249,
            "name": "DSCF4797.jpeg",
            "slug": "dscf4797-jpeg",
            "src": "/static/_gallery/albums/2022-11-03-rapsa/1600x899_dscf4797-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/1024x575_dscf4797-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/1600x899_dscf4797-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/500x281_dscf4797-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/800x449_dscf4797-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-03-rapsa/500x281_dscf4797-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF4829.jpeg",
            "slug": "dscf4829-jpeg",
            "src": "/static/_gallery/albums/2022-11-03-rapsa/1600x1066_dscf4829-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/1024x682_dscf4829-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/1600x1066_dscf4829-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/500x333_dscf4829-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/800x533_dscf4829-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-03-rapsa/500x333_dscf4829-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2999,
            "name": "DSCF4845.jpeg",
            "slug": "dscf4845-jpeg",
            "src": "/static/_gallery/albums/2022-11-03-rapsa/1600x1199_dscf4845-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/1024x767_dscf4845-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/1600x1199_dscf4845-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/500x374_dscf4845-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-03-rapsa/800x599_dscf4845-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-03-rapsa/500x374_dscf4845-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-11-03-rapsa",
      "src": "/static/_gallery/albums/2022-11-03-rapsa/500x374_dscf4782-jpeg.jpeg"
   },
   "2022-11-27-nettlebed": {
      "name": "2022-11-27 Nettlebed",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4882.jpeg",
            "slug": "dscf4882-jpeg",
            "src": "/static/_gallery/albums/2022-11-27-nettlebed/1600x2000_dscf4882-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1024x1280_dscf4882-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1600x2000_dscf4882-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4882-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/800x1000_dscf4882-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4882-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF4891.jpeg",
            "slug": "dscf4891-jpeg",
            "src": "/static/_gallery/albums/2022-11-27-nettlebed/1600x1280_dscf4891-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1024x819_dscf4891-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1600x1280_dscf4891-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/500x400_dscf4891-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/800x640_dscf4891-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-27-nettlebed/500x400_dscf4891-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4893.jpeg",
            "slug": "dscf4893-jpeg",
            "src": "/static/_gallery/albums/2022-11-27-nettlebed/1600x2000_dscf4893-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1024x1280_dscf4893-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1600x2000_dscf4893-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4893-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/800x1000_dscf4893-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4893-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF4899.jpeg",
            "slug": "dscf4899-jpeg",
            "src": "/static/_gallery/albums/2022-11-27-nettlebed/1600x1280_dscf4899-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1024x819_dscf4899-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1600x1280_dscf4899-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/500x400_dscf4899-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/800x640_dscf4899-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-27-nettlebed/500x400_dscf4899-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF4900.jpeg",
            "slug": "dscf4900-jpeg",
            "src": "/static/_gallery/albums/2022-11-27-nettlebed/1600x1280_dscf4900-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1024x819_dscf4900-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1600x1280_dscf4900-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/500x400_dscf4900-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/800x640_dscf4900-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-27-nettlebed/500x400_dscf4900-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4903.jpeg",
            "slug": "dscf4903-jpeg",
            "src": "/static/_gallery/albums/2022-11-27-nettlebed/1600x2000_dscf4903-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1024x1280_dscf4903-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1600x2000_dscf4903-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4903-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/800x1000_dscf4903-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4903-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4906.jpeg",
            "slug": "dscf4906-jpeg",
            "src": "/static/_gallery/albums/2022-11-27-nettlebed/1600x2000_dscf4906-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1024x1280_dscf4906-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1600x2000_dscf4906-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4906-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/800x1000_dscf4906-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4906-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4918.jpeg",
            "slug": "dscf4918-jpeg",
            "src": "/static/_gallery/albums/2022-11-27-nettlebed/1600x2000_dscf4918-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1024x1280_dscf4918-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1600x2000_dscf4918-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4918-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/800x1000_dscf4918-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4918-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4951.jpeg",
            "slug": "dscf4951-jpeg",
            "src": "/static/_gallery/albums/2022-11-27-nettlebed/1600x2000_dscf4951-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1024x1280_dscf4951-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/1600x2000_dscf4951-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4951-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-27-nettlebed/800x1000_dscf4951-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4951-jpeg.jpeg",
            "width": 3200
         }
      ],
      "slug": "2022-11-27-nettlebed",
      "src": "/static/_gallery/albums/2022-11-27-nettlebed/500x625_dscf4882-jpeg.jpeg"
   },
   "2022-11-28-oxford": {
      "name": "2022-11-28 Oxford",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF4977.jpeg",
            "slug": "dscf4977-jpeg",
            "src": "/static/_gallery/albums/2022-11-28-oxford/1600x2000_dscf4977-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-11-28-oxford/1024x1280_dscf4977-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-11-28-oxford/1600x2000_dscf4977-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-11-28-oxford/500x625_dscf4977-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-11-28-oxford/800x1000_dscf4977-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-11-28-oxford/500x625_dscf4977-jpeg.jpeg",
            "width": 3200
         }
      ],
      "slug": "2022-11-28-oxford",
      "src": "/static/_gallery/albums/2022-11-28-oxford/500x625_dscf4977-jpeg.jpeg"
   },
   "2022-12-06-caversham-with-katie": {
      "name": "2022-12-06 Caversham with Katie",
      "photos": [
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF5012.jpeg",
            "slug": "dscf5012-jpeg",
            "src": "/static/_gallery/albums/2022-12-06-caversham-with-katie/1600x1280_dscf5012-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/1024x819_dscf5012-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/1600x1280_dscf5012-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/500x400_dscf5012-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/800x640_dscf5012-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-06-caversham-with-katie/500x400_dscf5012-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5020.jpeg",
            "slug": "dscf5020-jpeg",
            "src": "/static/_gallery/albums/2022-12-06-caversham-with-katie/1600x2000_dscf5020-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/1024x1280_dscf5020-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/1600x2000_dscf5020-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/500x625_dscf5020-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/800x1000_dscf5020-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-06-caversham-with-katie/500x625_dscf5020-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5034.jpeg",
            "slug": "dscf5034-jpeg",
            "src": "/static/_gallery/albums/2022-12-06-caversham-with-katie/1600x2400_dscf5034-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/1024x1536_dscf5034-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/1600x2400_dscf5034-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/500x750_dscf5034-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/800x1200_dscf5034-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-06-caversham-with-katie/500x750_dscf5034-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF5037.jpeg",
            "slug": "dscf5037-jpeg",
            "src": "/static/_gallery/albums/2022-12-06-caversham-with-katie/1600x1066_dscf5037-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/1024x682_dscf5037-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/1600x1066_dscf5037-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/500x333_dscf5037-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-06-caversham-with-katie/800x533_dscf5037-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-06-caversham-with-katie/500x333_dscf5037-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-12-06-caversham-with-katie",
      "src": "/static/_gallery/albums/2022-12-06-caversham-with-katie/500x400_dscf5012-jpeg.jpeg"
   },
   "2022-12-18-bluecollar": {
      "name": "2022-12-18 Bluecollar",
      "photos": [
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF5077.jpeg",
            "slug": "dscf5077-jpeg",
            "src": "/static/_gallery/albums/2022-12-18-bluecollar/1600x1280_dscf5077-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1024x819_dscf5077-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1600x1280_dscf5077-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/500x400_dscf5077-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/800x640_dscf5077-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-18-bluecollar/500x400_dscf5077-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5100.jpeg",
            "slug": "dscf5100-jpeg",
            "src": "/static/_gallery/albums/2022-12-18-bluecollar/1600x2000_dscf5100-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1024x1280_dscf5100-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1600x2000_dscf5100-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/500x625_dscf5100-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/800x1000_dscf5100-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-18-bluecollar/500x625_dscf5100-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5159.jpeg",
            "slug": "dscf5159-jpeg",
            "src": "/static/_gallery/albums/2022-12-18-bluecollar/1600x2000_dscf5159-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1024x1280_dscf5159-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1600x2000_dscf5159-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/500x625_dscf5159-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/800x1000_dscf5159-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-18-bluecollar/500x625_dscf5159-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF5182.jpeg",
            "slug": "dscf5182-jpeg",
            "src": "/static/_gallery/albums/2022-12-18-bluecollar/1600x1280_dscf5182-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1024x819_dscf5182-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1600x1280_dscf5182-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/500x400_dscf5182-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/800x640_dscf5182-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-18-bluecollar/500x400_dscf5182-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5209.jpeg",
            "slug": "dscf5209-jpeg",
            "src": "/static/_gallery/albums/2022-12-18-bluecollar/1600x2001_dscf5209-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1024x1280_dscf5209-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1600x2001_dscf5209-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/500x625_dscf5209-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/800x1000_dscf5209-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-18-bluecollar/500x625_dscf5209-jpeg.jpeg",
            "width": 3198
         },
         {
            "faces": [],
            "height": 2249,
            "name": "DSCF5223.jpeg",
            "slug": "dscf5223-jpeg",
            "src": "/static/_gallery/albums/2022-12-18-bluecollar/1600x899_dscf5223-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1024x575_dscf5223-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1600x899_dscf5223-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/500x281_dscf5223-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/800x449_dscf5223-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-18-bluecollar/500x281_dscf5223-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2249,
            "name": "DSCF5227.jpeg",
            "slug": "dscf5227-jpeg",
            "src": "/static/_gallery/albums/2022-12-18-bluecollar/1600x899_dscf5227-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1024x575_dscf5227-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1600x899_dscf5227-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/500x281_dscf5227-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/800x449_dscf5227-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-18-bluecollar/500x281_dscf5227-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF5235.jpeg",
            "slug": "dscf5235-jpeg",
            "src": "/static/_gallery/albums/2022-12-18-bluecollar/1600x1066_dscf5235-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1024x682_dscf5235-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1600x1066_dscf5235-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/500x333_dscf5235-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/800x533_dscf5235-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-18-bluecollar/500x333_dscf5235-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF5237.jpeg",
            "slug": "dscf5237-jpeg",
            "src": "/static/_gallery/albums/2022-12-18-bluecollar/1600x1066_dscf5237-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1024x682_dscf5237-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1600x1066_dscf5237-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/500x333_dscf5237-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/800x533_dscf5237-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-18-bluecollar/500x333_dscf5237-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF5239.jpeg",
            "slug": "dscf5239-jpeg",
            "src": "/static/_gallery/albums/2022-12-18-bluecollar/1600x1066_dscf5239-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1024x682_dscf5239-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1600x1066_dscf5239-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/500x333_dscf5239-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/800x533_dscf5239-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-18-bluecollar/500x333_dscf5239-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF5241.jpeg",
            "slug": "dscf5241-jpeg",
            "src": "/static/_gallery/albums/2022-12-18-bluecollar/1600x1066_dscf5241-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1024x682_dscf5241-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1600x1066_dscf5241-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/500x333_dscf5241-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/800x533_dscf5241-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-18-bluecollar/500x333_dscf5241-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF5259.jpeg",
            "slug": "dscf5259-jpeg",
            "src": "/static/_gallery/albums/2022-12-18-bluecollar/1600x1066_dscf5259-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1024x682_dscf5259-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/1600x1066_dscf5259-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/500x333_dscf5259-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-18-bluecollar/800x533_dscf5259-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-18-bluecollar/500x333_dscf5259-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-12-18-bluecollar",
      "src": "/static/_gallery/albums/2022-12-18-bluecollar/500x400_dscf5077-jpeg.jpeg"
   },
   "2022-12-20-dorchester": {
      "name": "2022-12-20 Dorchester",
      "photos": [
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF5265.jpeg",
            "slug": "dscf5265-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x1066_dscf5265-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x682_dscf5265-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x1066_dscf5265-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x333_dscf5265-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x533_dscf5265-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x333_dscf5265-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF5275.jpeg",
            "slug": "dscf5275-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x900_dscf5275-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x576_dscf5275-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x900_dscf5275-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x281_dscf5275-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x450_dscf5275-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x281_dscf5275-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5279.jpeg",
            "slug": "dscf5279-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5279-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x1280_dscf5279-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5279-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5279-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x1000_dscf5279-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5279-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5282.jpeg",
            "slug": "dscf5282-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5282-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x1280_dscf5282-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5282-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5282-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x1000_dscf5282-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5282-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5289.jpeg",
            "slug": "dscf5289-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5289-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x1280_dscf5289-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5289-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5289-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x1000_dscf5289-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5289-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5300.jpeg",
            "slug": "dscf5300-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x2400_dscf5300-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x1536_dscf5300-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x2400_dscf5300-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x750_dscf5300-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x1200_dscf5300-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x750_dscf5300-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2249,
            "name": "DSCF5306.jpeg",
            "slug": "dscf5306-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x899_dscf5306-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x575_dscf5306-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x899_dscf5306-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x281_dscf5306-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x449_dscf5306-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x281_dscf5306-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5325.jpeg",
            "slug": "dscf5325-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5325-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x1280_dscf5325-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5325-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5325-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x1000_dscf5325-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5325-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5339.jpeg",
            "slug": "dscf5339-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5339-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x1280_dscf5339-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5339-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5339-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x1000_dscf5339-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5339-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF5373.jpeg",
            "slug": "dscf5373-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x1280_dscf5373-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x819_dscf5373-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x1280_dscf5373-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x400_dscf5373-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x640_dscf5373-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x400_dscf5373-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF5387.jpeg",
            "slug": "dscf5387-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x1280_dscf5387-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x819_dscf5387-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x1280_dscf5387-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x400_dscf5387-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x640_dscf5387-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x400_dscf5387-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF5393.jpeg",
            "slug": "dscf5393-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x1280_dscf5393-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x819_dscf5393-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x1280_dscf5393-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x400_dscf5393-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x640_dscf5393-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x400_dscf5393-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5400.jpeg",
            "slug": "dscf5400-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5400-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x1280_dscf5400-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5400-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5400-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x1000_dscf5400-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5400-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5407.jpeg",
            "slug": "dscf5407-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5407-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x1280_dscf5407-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5407-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5407-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x1000_dscf5407-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5407-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5429.jpeg",
            "slug": "dscf5429-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5429-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x1280_dscf5429-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5429-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5429-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x1000_dscf5429-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5429-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 1714,
            "name": "DSCF5437.jpeg",
            "slug": "dscf5437-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x685_dscf5437-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x438_dscf5437-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x685_dscf5437-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x214_dscf5437-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x342_dscf5437-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x214_dscf5437-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF5440.jpeg",
            "slug": "dscf5440-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x900_dscf5440-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x576_dscf5440-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x900_dscf5440-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x281_dscf5440-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x450_dscf5440-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x281_dscf5440-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3199,
            "name": "DSCF5441.jpeg",
            "slug": "dscf5441-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x1279_dscf5441-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x818_dscf5441-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x1279_dscf5441-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x399_dscf5441-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x639_dscf5441-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x399_dscf5441-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5446.jpeg",
            "slug": "dscf5446-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5446-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x1280_dscf5446-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x2000_dscf5446-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5446-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x1000_dscf5446-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x625_dscf5446-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF5450.jpeg",
            "slug": "dscf5450-jpeg",
            "src": "/static/_gallery/albums/2022-12-20-dorchester/1600x900_dscf5450-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1024x576_dscf5450-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/1600x900_dscf5450-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/500x281_dscf5450-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-20-dorchester/800x450_dscf5450-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-20-dorchester/500x281_dscf5450-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2022-12-20-dorchester",
      "src": "/static/_gallery/albums/2022-12-20-dorchester/500x333_dscf5265-jpeg.jpeg"
   },
   "2022-12-22-nettlebed": {
      "name": "2022-12-22 Nettlebed",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5460.jpeg",
            "slug": "dscf5460-jpeg",
            "src": "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5460-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1024x1280_dscf5460-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5460-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5460-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/800x1000_dscf5460-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5460-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5465.jpeg",
            "slug": "dscf5465-jpeg",
            "src": "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5465-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1024x1280_dscf5465-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5465-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5465-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/800x1000_dscf5465-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5465-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5482.jpeg",
            "slug": "dscf5482-jpeg",
            "src": "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5482-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1024x1280_dscf5482-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5482-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5482-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/800x1000_dscf5482-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5482-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5500.jpeg",
            "slug": "dscf5500-jpeg",
            "src": "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5500-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1024x1280_dscf5500-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5500-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5500-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/800x1000_dscf5500-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5500-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5506.jpeg",
            "slug": "dscf5506-jpeg",
            "src": "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5506-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1024x1280_dscf5506-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5506-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5506-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/800x1000_dscf5506-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5506-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5526.jpeg",
            "slug": "dscf5526-jpeg",
            "src": "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5526-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1024x1280_dscf5526-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5526-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5526-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/800x1000_dscf5526-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5526-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5528.jpeg",
            "slug": "dscf5528-jpeg",
            "src": "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5528-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1024x1280_dscf5528-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5528-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5528-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/800x1000_dscf5528-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5528-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5540.jpeg",
            "slug": "dscf5540-jpeg",
            "src": "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5540-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1024x1280_dscf5540-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1600x2000_dscf5540-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5540-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/800x1000_dscf5540-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5540-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5551.jpeg",
            "slug": "dscf5551-jpeg",
            "src": "/static/_gallery/albums/2022-12-22-nettlebed/1600x2400_dscf5551-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1024x1536_dscf5551-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1600x2400_dscf5551-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/500x750_dscf5551-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/800x1200_dscf5551-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-22-nettlebed/500x750_dscf5551-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5564.jpeg",
            "slug": "dscf5564-jpeg",
            "src": "/static/_gallery/albums/2022-12-22-nettlebed/1600x2400_dscf5564-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1024x1536_dscf5564-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1600x2400_dscf5564-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/500x750_dscf5564-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/800x1200_dscf5564-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-22-nettlebed/500x750_dscf5564-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5568.jpeg",
            "slug": "dscf5568-jpeg",
            "src": "/static/_gallery/albums/2022-12-22-nettlebed/1600x2400_dscf5568-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1024x1536_dscf5568-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/1600x2400_dscf5568-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/500x750_dscf5568-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2022-12-22-nettlebed/800x1200_dscf5568-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2022-12-22-nettlebed/500x750_dscf5568-jpeg.jpeg",
            "width": 2666
         }
      ],
      "slug": "2022-12-22-nettlebed",
      "src": "/static/_gallery/albums/2022-12-22-nettlebed/500x625_dscf5460-jpeg.jpeg"
   },
   "2023-01-21-katie-s": {
      "name": "2023-01-21 Katie's",
      "photos": [
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF5572.jpeg",
            "slug": "dscf5572-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x1280_dscf5572-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x819_dscf5572-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x1280_dscf5572-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x400_dscf5572-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x640_dscf5572-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x400_dscf5572-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF5581.jpeg",
            "slug": "dscf5581-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x1280_dscf5581-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x819_dscf5581-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x1280_dscf5581-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x400_dscf5581-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x640_dscf5581-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x400_dscf5581-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5603.jpeg",
            "slug": "dscf5603-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5603-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x1280_dscf5603-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5603-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5603-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x1000_dscf5603-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5603-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF5613.jpeg",
            "slug": "dscf5613-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x1280_dscf5613-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x819_dscf5613-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x1280_dscf5613-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x400_dscf5613-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x640_dscf5613-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x400_dscf5613-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF5615.jpeg",
            "slug": "dscf5615-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x1280_dscf5615-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x819_dscf5615-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x1280_dscf5615-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x400_dscf5615-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x640_dscf5615-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x400_dscf5615-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5629.jpeg",
            "slug": "dscf5629-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5629-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x1280_dscf5629-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5629-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5629-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x1000_dscf5629-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5629-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5641.jpeg",
            "slug": "dscf5641-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5641-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x1280_dscf5641-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5641-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5641-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x1000_dscf5641-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5641-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 2249,
            "name": "DSCF5663.jpeg",
            "slug": "dscf5663-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x899_dscf5663-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x575_dscf5663-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x899_dscf5663-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x281_dscf5663-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x449_dscf5663-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x281_dscf5663-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5695.jpeg",
            "slug": "dscf5695-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5695-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x1280_dscf5695-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5695-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5695-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x1000_dscf5695-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5695-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5713.jpeg",
            "slug": "dscf5713-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5713-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x1280_dscf5713-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5713-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5713-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x1000_dscf5713-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5713-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 3199,
            "name": "DSCF5782.jpeg",
            "slug": "dscf5782-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x1279_dscf5782-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x818_dscf5782-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x1279_dscf5782-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x399_dscf5782-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x639_dscf5782-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x399_dscf5782-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF5808.jpeg",
            "slug": "dscf5808-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x1280_dscf5808-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x819_dscf5808-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x1280_dscf5808-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x400_dscf5808-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x640_dscf5808-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x400_dscf5808-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5813.jpeg",
            "slug": "dscf5813-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5813-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x1280_dscf5813-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5813-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5813-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x1000_dscf5813-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5813-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5832.jpeg",
            "slug": "dscf5832-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5832-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x1280_dscf5832-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5832-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5832-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x1000_dscf5832-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5832-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5849.jpeg",
            "slug": "dscf5849-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5849-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x1280_dscf5849-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5849-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5849-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x1000_dscf5849-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5849-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 3199,
            "name": "DSCF5855.jpeg",
            "slug": "dscf5855-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x1279_dscf5855-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x818_dscf5855-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x1279_dscf5855-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x399_dscf5855-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x639_dscf5855-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x399_dscf5855-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5871.jpeg",
            "slug": "dscf5871-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5871-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x1280_dscf5871-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5871-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5871-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x1000_dscf5871-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5871-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF5876.jpeg",
            "slug": "dscf5876-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5876-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x1280_dscf5876-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x2000_dscf5876-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5876-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x1000_dscf5876-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x625_dscf5876-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF5887.jpeg",
            "slug": "dscf5887-jpeg",
            "src": "/static/_gallery/albums/2023-01-21-katie-s/1600x900_dscf5887-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1024x576_dscf5887-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/1600x900_dscf5887-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/500x281_dscf5887-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-21-katie-s/800x450_dscf5887-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-21-katie-s/500x281_dscf5887-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2023-01-21-katie-s",
      "src": "/static/_gallery/albums/2023-01-21-katie-s/500x400_dscf5572-jpeg.jpeg"
   },
   "2023-01-30-amelia-baby-shower": {
      "name": "2023-01-30 Amelia baby shower",
      "photos": [
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF5933.jpeg",
            "slug": "dscf5933-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf5933-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf5933-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf5933-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf5933-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf5933-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf5933-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF5947.jpeg",
            "slug": "dscf5947-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf5947-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf5947-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf5947-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf5947-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf5947-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf5947-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF5956.jpeg",
            "slug": "dscf5956-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1280_dscf5956-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x819_dscf5956-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1280_dscf5956-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x400_dscf5956-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x640_dscf5956-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x400_dscf5956-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF5959.jpeg",
            "slug": "dscf5959-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf5959-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf5959-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf5959-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf5959-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf5959-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf5959-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF5965.jpeg",
            "slug": "dscf5965-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf5965-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf5965-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf5965-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf5965-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf5965-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf5965-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6005.jpeg",
            "slug": "dscf6005-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6005-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf6005-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6005-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6005-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf6005-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6005-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6015.jpeg",
            "slug": "dscf6015-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6015-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf6015-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6015-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6015-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf6015-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6015-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6027.jpeg",
            "slug": "dscf6027-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6027-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf6027-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6027-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6027-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf6027-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6027-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6065.jpeg",
            "slug": "dscf6065-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6065-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf6065-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6065-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6065-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf6065-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6065-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6084.jpeg",
            "slug": "dscf6084-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6084-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf6084-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6084-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6084-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf6084-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6084-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6093.jpeg",
            "slug": "dscf6093-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6093-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf6093-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6093-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6093-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf6093-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6093-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6100.jpeg",
            "slug": "dscf6100-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6100-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf6100-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6100-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6100-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf6100-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6100-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6105.jpeg",
            "slug": "dscf6105-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6105-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf6105-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6105-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6105-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf6105-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6105-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6125.jpeg",
            "slug": "dscf6125-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6125-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf6125-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6125-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6125-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf6125-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6125-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6163.jpeg",
            "slug": "dscf6163-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x2400_dscf6163-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x1536_dscf6163-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x2400_dscf6163-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x750_dscf6163-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x1200_dscf6163-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x750_dscf6163-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 3199,
            "name": "DSCF6167.jpeg",
            "slug": "dscf6167-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1279_dscf6167-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x818_dscf6167-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1279_dscf6167-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x399_dscf6167-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x639_dscf6167-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x399_dscf6167-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6175.jpeg",
            "slug": "dscf6175-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6175-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf6175-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6175-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6175-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf6175-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6175-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6208.jpeg",
            "slug": "dscf6208-jpeg",
            "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6208-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1024x682_dscf6208-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/1600x1066_dscf6208-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6208-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-01-30-amelia-baby-shower/800x533_dscf6208-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf6208-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2023-01-30-amelia-baby-shower",
      "src": "/static/_gallery/albums/2023-01-30-amelia-baby-shower/500x333_dscf5933-jpeg.jpeg"
   },
   "2023-02-04-toby": {
      "name": "2023-02-04 Toby",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6218.jpeg",
            "slug": "dscf6218-jpeg",
            "src": "/static/_gallery/albums/2023-02-04-toby/1600x2400_dscf6218-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-04-toby/1024x1536_dscf6218-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-04-toby/1600x2400_dscf6218-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-04-toby/500x750_dscf6218-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-04-toby/800x1200_dscf6218-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-04-toby/500x750_dscf6218-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6222.jpeg",
            "slug": "dscf6222-jpeg",
            "src": "/static/_gallery/albums/2023-02-04-toby/1600x2400_dscf6222-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-04-toby/1024x1536_dscf6222-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-04-toby/1600x2400_dscf6222-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-04-toby/500x750_dscf6222-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-04-toby/800x1200_dscf6222-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-04-toby/500x750_dscf6222-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6232.jpeg",
            "slug": "dscf6232-jpeg",
            "src": "/static/_gallery/albums/2023-02-04-toby/1600x1066_dscf6232-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-04-toby/1024x682_dscf6232-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-04-toby/1600x1066_dscf6232-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-04-toby/500x333_dscf6232-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-04-toby/800x533_dscf6232-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-04-toby/500x333_dscf6232-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6235.jpeg",
            "slug": "dscf6235-jpeg",
            "src": "/static/_gallery/albums/2023-02-04-toby/1600x2400_dscf6235-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-04-toby/1024x1536_dscf6235-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-04-toby/1600x2400_dscf6235-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-04-toby/500x750_dscf6235-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-04-toby/800x1200_dscf6235-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-04-toby/500x750_dscf6235-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6236.jpeg",
            "slug": "dscf6236-jpeg",
            "src": "/static/_gallery/albums/2023-02-04-toby/1600x2400_dscf6236-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-04-toby/1024x1536_dscf6236-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-04-toby/1600x2400_dscf6236-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-04-toby/500x750_dscf6236-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-04-toby/800x1200_dscf6236-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-04-toby/500x750_dscf6236-jpeg.jpeg",
            "width": 2666
         }
      ],
      "slug": "2023-02-04-toby",
      "src": "/static/_gallery/albums/2023-02-04-toby/500x750_dscf6218-jpeg.jpeg"
   },
   "2023-02-05-hurstpierpoint": {
      "name": "2023-02-05 Hurstpierpoint",
      "photos": [
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6251.jpeg",
            "slug": "dscf6251-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6251-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6251-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6251-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6251-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6251-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6251-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6254.jpeg",
            "slug": "dscf6254-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6254-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6254-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6254-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6254-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6254-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6254-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6256.jpeg",
            "slug": "dscf6256-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6256-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6256-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6256-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6256-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6256-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6256-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6262.jpeg",
            "slug": "dscf6262-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6262-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6262-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6262-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6262-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6262-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6262-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6265.jpeg",
            "slug": "dscf6265-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6265-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6265-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6265-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6265-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6265-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6265-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6282.jpeg",
            "slug": "dscf6282-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6282-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6282-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6282-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6282-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6282-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6282-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6287.jpeg",
            "slug": "dscf6287-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x2400_dscf6287-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x1536_dscf6287-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x2400_dscf6287-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x750_dscf6287-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x1200_dscf6287-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x750_dscf6287-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6293.jpeg",
            "slug": "dscf6293-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6293-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6293-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6293-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6293-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6293-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6293-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6309.jpeg",
            "slug": "dscf6309-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6309-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6309-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6309-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6309-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6309-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6309-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6315.jpeg",
            "slug": "dscf6315-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6315-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6315-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6315-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6315-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6315-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6315-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6318.jpeg",
            "slug": "dscf6318-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6318-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6318-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6318-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6318-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6318-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6318-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6320.jpeg",
            "slug": "dscf6320-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x2400_dscf6320-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x1536_dscf6320-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x2400_dscf6320-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x750_dscf6320-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x1200_dscf6320-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x750_dscf6320-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6325.jpeg",
            "slug": "dscf6325-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x2400_dscf6325-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x1536_dscf6325-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x2400_dscf6325-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x750_dscf6325-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x1200_dscf6325-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x750_dscf6325-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6330.jpeg",
            "slug": "dscf6330-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6330-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6330-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6330-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6330-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6330-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6330-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6331.jpeg",
            "slug": "dscf6331-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6331-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6331-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6331-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6331-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6331-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6331-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6334.jpeg",
            "slug": "dscf6334-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6334-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6334-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6334-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6334-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6334-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6334-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6337.jpeg",
            "slug": "dscf6337-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6337-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6337-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6337-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6337-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6337-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6337-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6340.jpeg",
            "slug": "dscf6340-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6340-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6340-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6340-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6340-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6340-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6340-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6359.jpeg",
            "slug": "dscf6359-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6359-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6359-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6359-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6359-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6359-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6359-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6370.jpeg",
            "slug": "dscf6370-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6370-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6370-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6370-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6370-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6370-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6370-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6396.jpeg",
            "slug": "dscf6396-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6396-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6396-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6396-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6396-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6396-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6396-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6423.jpeg",
            "slug": "dscf6423-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6423-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6423-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6423-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6423-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6423-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6423-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6461.jpeg",
            "slug": "dscf6461-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6461-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6461-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6461-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6461-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6461-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6461-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6470.jpeg",
            "slug": "dscf6470-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6470-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6470-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6470-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6470-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6470-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6470-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6478.jpeg",
            "slug": "dscf6478-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x2400_dscf6478-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x1536_dscf6478-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x2400_dscf6478-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x750_dscf6478-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x1200_dscf6478-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x750_dscf6478-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6486.jpeg",
            "slug": "dscf6486-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6486-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6486-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6486-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6486-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6486-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6486-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6490.jpeg",
            "slug": "dscf6490-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6490-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6490-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6490-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6490-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6490-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6490-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6493.jpeg",
            "slug": "dscf6493-jpeg",
            "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6493-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1024x682_dscf6493-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/1600x1066_dscf6493-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6493-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-05-hurstpierpoint/800x533_dscf6493-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6493-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2023-02-05-hurstpierpoint",
      "src": "/static/_gallery/albums/2023-02-05-hurstpierpoint/500x333_dscf6251-jpeg.jpeg"
   },
   "2023-02-07-ditchling-beacon": {
      "name": "2023-02-07 Ditchling Beacon",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6506.jpeg",
            "slug": "dscf6506-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x2400_dscf6506-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x1536_dscf6506-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x2400_dscf6506-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x750_dscf6506-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x1200_dscf6506-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x750_dscf6506-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6511.jpeg",
            "slug": "dscf6511-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6511-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x682_dscf6511-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6511-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6511-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x533_dscf6511-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6511-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6517.jpeg",
            "slug": "dscf6517-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6517-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x682_dscf6517-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6517-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6517-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x533_dscf6517-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6517-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6524.jpeg",
            "slug": "dscf6524-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6524-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x682_dscf6524-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6524-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6524-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x533_dscf6524-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6524-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6529.jpeg",
            "slug": "dscf6529-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6529-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x682_dscf6529-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6529-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6529-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x533_dscf6529-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6529-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6539.jpeg",
            "slug": "dscf6539-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6539-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x682_dscf6539-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6539-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6539-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x533_dscf6539-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6539-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6544.jpeg",
            "slug": "dscf6544-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6544-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x682_dscf6544-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6544-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6544-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x533_dscf6544-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6544-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6550.jpeg",
            "slug": "dscf6550-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6550-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x682_dscf6550-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6550-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6550-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x533_dscf6550-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6550-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6552.jpeg",
            "slug": "dscf6552-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6552-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x682_dscf6552-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6552-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6552-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x533_dscf6552-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6552-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6556.jpeg",
            "slug": "dscf6556-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6556-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x682_dscf6556-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6556-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6556-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x533_dscf6556-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6556-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6576.jpeg",
            "slug": "dscf6576-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x2400_dscf6576-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x1536_dscf6576-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x2400_dscf6576-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x750_dscf6576-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x1200_dscf6576-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x750_dscf6576-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6590.jpeg",
            "slug": "dscf6590-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x2400_dscf6590-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x1536_dscf6590-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x2400_dscf6590-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x750_dscf6590-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x1200_dscf6590-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x750_dscf6590-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6592.jpeg",
            "slug": "dscf6592-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x2400_dscf6592-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x1536_dscf6592-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x2400_dscf6592-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x750_dscf6592-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x1200_dscf6592-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x750_dscf6592-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6615.jpeg",
            "slug": "dscf6615-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6615-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x682_dscf6615-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6615-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6615-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x533_dscf6615-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6615-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6620.jpeg",
            "slug": "dscf6620-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x2400_dscf6620-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x1536_dscf6620-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x2400_dscf6620-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x750_dscf6620-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x1200_dscf6620-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x750_dscf6620-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6625.jpeg",
            "slug": "dscf6625-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6625-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x682_dscf6625-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6625-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6625-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x533_dscf6625-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6625-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6628.jpeg",
            "slug": "dscf6628-jpeg",
            "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6628-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1024x682_dscf6628-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/1600x1066_dscf6628-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6628-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-07-ditchling-beacon/800x533_dscf6628-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x333_dscf6628-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2023-02-07-ditchling-beacon",
      "src": "/static/_gallery/albums/2023-02-07-ditchling-beacon/500x750_dscf6506-jpeg.jpeg"
   },
   "2023-02-10-seven-sisters": {
      "name": "2023-02-10 Seven Sisters",
      "photos": [
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6632.jpeg",
            "slug": "dscf6632-jpeg",
            "src": "/static/_gallery/albums/2023-02-10-seven-sisters/1600x1066_dscf6632-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1024x682_dscf6632-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1600x1066_dscf6632-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/500x333_dscf6632-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/800x533_dscf6632-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-10-seven-sisters/500x333_dscf6632-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2249,
            "name": "DSCF6642.jpeg",
            "slug": "dscf6642-jpeg",
            "src": "/static/_gallery/albums/2023-02-10-seven-sisters/1600x899_dscf6642-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1024x575_dscf6642-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1600x899_dscf6642-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/500x281_dscf6642-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/800x449_dscf6642-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-10-seven-sisters/500x281_dscf6642-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6645.jpeg",
            "slug": "dscf6645-jpeg",
            "src": "/static/_gallery/albums/2023-02-10-seven-sisters/1600x2000_dscf6645-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1024x1280_dscf6645-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1600x2000_dscf6645-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/500x625_dscf6645-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/800x1000_dscf6645-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-10-seven-sisters/500x625_dscf6645-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6657.jpeg",
            "slug": "dscf6657-jpeg",
            "src": "/static/_gallery/albums/2023-02-10-seven-sisters/1600x2000_dscf6657-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1024x1280_dscf6657-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1600x2000_dscf6657-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/500x625_dscf6657-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/800x1000_dscf6657-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-10-seven-sisters/500x625_dscf6657-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 1714,
            "name": "DSCF6659.jpeg",
            "slug": "dscf6659-jpeg",
            "src": "/static/_gallery/albums/2023-02-10-seven-sisters/1600x685_dscf6659-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1024x438_dscf6659-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1600x685_dscf6659-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/500x214_dscf6659-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/800x342_dscf6659-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-10-seven-sisters/500x214_dscf6659-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF6667.jpeg",
            "slug": "dscf6667-jpeg",
            "src": "/static/_gallery/albums/2023-02-10-seven-sisters/1600x1280_dscf6667-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1024x819_dscf6667-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1600x1280_dscf6667-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/500x400_dscf6667-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/800x640_dscf6667-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-10-seven-sisters/500x400_dscf6667-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6694.jpeg",
            "slug": "dscf6694-jpeg",
            "src": "/static/_gallery/albums/2023-02-10-seven-sisters/1600x2000_dscf6694-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1024x1280_dscf6694-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1600x2000_dscf6694-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/500x625_dscf6694-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/800x1000_dscf6694-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-10-seven-sisters/500x625_dscf6694-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF6704.jpeg",
            "slug": "dscf6704-jpeg",
            "src": "/static/_gallery/albums/2023-02-10-seven-sisters/1600x900_dscf6704-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1024x576_dscf6704-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1600x900_dscf6704-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/500x281_dscf6704-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/800x450_dscf6704-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-10-seven-sisters/500x281_dscf6704-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6708.jpeg",
            "slug": "dscf6708-jpeg",
            "src": "/static/_gallery/albums/2023-02-10-seven-sisters/1600x2000_dscf6708-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1024x1280_dscf6708-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1600x2000_dscf6708-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/500x625_dscf6708-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/800x1000_dscf6708-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-10-seven-sisters/500x625_dscf6708-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF6709.jpeg",
            "slug": "dscf6709-jpeg",
            "src": "/static/_gallery/albums/2023-02-10-seven-sisters/1600x900_dscf6709-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1024x576_dscf6709-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/1600x900_dscf6709-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/500x281_dscf6709-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-10-seven-sisters/800x450_dscf6709-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-10-seven-sisters/500x281_dscf6709-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2023-02-10-seven-sisters",
      "src": "/static/_gallery/albums/2023-02-10-seven-sisters/500x333_dscf6632-jpeg.jpeg"
   },
   "2023-02-11-katie-s-park": {
      "name": "2023-02-11 Katie's park",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6742.jpeg",
            "slug": "dscf6742-jpeg",
            "src": "/static/_gallery/albums/2023-02-11-katie-s-park/1600x2000_dscf6742-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-11-katie-s-park/1024x1280_dscf6742-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-11-katie-s-park/1600x2000_dscf6742-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-11-katie-s-park/500x625_dscf6742-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-11-katie-s-park/800x1000_dscf6742-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-11-katie-s-park/500x625_dscf6742-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF6759.jpeg",
            "slug": "dscf6759-jpeg",
            "src": "/static/_gallery/albums/2023-02-11-katie-s-park/1600x1280_dscf6759-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-11-katie-s-park/1024x819_dscf6759-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-11-katie-s-park/1600x1280_dscf6759-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-11-katie-s-park/500x400_dscf6759-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-11-katie-s-park/800x640_dscf6759-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-11-katie-s-park/500x400_dscf6759-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6762.jpeg",
            "slug": "dscf6762-jpeg",
            "src": "/static/_gallery/albums/2023-02-11-katie-s-park/1600x2000_dscf6762-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-11-katie-s-park/1024x1280_dscf6762-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-11-katie-s-park/1600x2000_dscf6762-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-11-katie-s-park/500x625_dscf6762-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-11-katie-s-park/800x1000_dscf6762-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-11-katie-s-park/500x625_dscf6762-jpeg.jpeg",
            "width": 3200
         }
      ],
      "slug": "2023-02-11-katie-s-park",
      "src": "/static/_gallery/albums/2023-02-11-katie-s-park/500x625_dscf6742-jpeg.jpeg"
   },
   "2023-02-16-george-s-wedding": {
      "name": "2023-02-16 George's wedding",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6823.jpeg",
            "slug": "dscf6823-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1600_dscf6823-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1024_dscf6823-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1600_dscf6823-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x500_dscf6823-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x800_dscf6823-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x500_dscf6823-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6832.jpeg",
            "slug": "dscf6832-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf6832-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x682_dscf6832-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf6832-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf6832-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x533_dscf6832-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf6832-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6836.jpeg",
            "slug": "dscf6836-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1600_dscf6836-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1024_dscf6836-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1600_dscf6836-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x500_dscf6836-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x800_dscf6836-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x500_dscf6836-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF6856.jpeg",
            "slug": "dscf6856-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1280_dscf6856-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x819_dscf6856-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1280_dscf6856-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x400_dscf6856-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x640_dscf6856-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x400_dscf6856-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6869.jpeg",
            "slug": "dscf6869-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2000_dscf6869-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1280_dscf6869-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2000_dscf6869-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x625_dscf6869-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x1000_dscf6869-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x625_dscf6869-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6870.jpeg",
            "slug": "dscf6870-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1999_dscf6870-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1279_dscf6870-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1999_dscf6870-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x624_dscf6870-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x999_dscf6870-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x624_dscf6870-jpeg.jpeg",
            "width": 3201
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6882.jpeg",
            "slug": "dscf6882-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2000_dscf6882-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1280_dscf6882-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2000_dscf6882-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x625_dscf6882-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x1000_dscf6882-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x625_dscf6882-jpeg.jpeg",
            "width": 3199
         },
         {
            "faces": [],
            "height": 3200,
            "name": "DSCF6898.jpeg",
            "slug": "dscf6898-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1280_dscf6898-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x819_dscf6898-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1280_dscf6898-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x400_dscf6898-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x640_dscf6898-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x400_dscf6898-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6909.jpeg",
            "slug": "dscf6909-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2000_dscf6909-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1280_dscf6909-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2000_dscf6909-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x625_dscf6909-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x1000_dscf6909-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x625_dscf6909-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6913.jpeg",
            "slug": "dscf6913-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2133_dscf6913-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1365_dscf6913-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2133_dscf6913-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x666_dscf6913-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x1066_dscf6913-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x666_dscf6913-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6922.jpeg",
            "slug": "dscf6922-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2134_dscf6922-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1365_dscf6922-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2134_dscf6922-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x666_dscf6922-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x1067_dscf6922-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x666_dscf6922-jpeg.jpeg",
            "width": 2999
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6926.jpeg",
            "slug": "dscf6926-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2133_dscf6926-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1365_dscf6926-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2133_dscf6926-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x666_dscf6926-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x1066_dscf6926-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x666_dscf6926-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF6937.jpeg",
            "slug": "dscf6937-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2400_dscf6937-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1536_dscf6937-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2400_dscf6937-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x750_dscf6937-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x1200_dscf6937-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x750_dscf6937-jpeg.jpeg",
            "width": 2666
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF6986.jpeg",
            "slug": "dscf6986-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf6986-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x682_dscf6986-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf6986-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf6986-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x533_dscf6986-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf6986-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2250,
            "name": "DSCF6998.jpeg",
            "slug": "dscf6998-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x900_dscf6998-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x576_dscf6998-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x900_dscf6998-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x281_dscf6998-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x450_dscf6998-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x281_dscf6998-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7009.jpeg",
            "slug": "dscf7009-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2000_dscf7009-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1280_dscf7009-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2000_dscf7009-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x625_dscf7009-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x1000_dscf7009-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x625_dscf7009-jpeg.jpeg",
            "width": 3200
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF7020.jpeg",
            "slug": "dscf7020-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7020-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x682_dscf7020-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7020-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7020-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x533_dscf7020-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7020-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7073.jpeg",
            "slug": "dscf7073-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1600_dscf7073-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1024_dscf7073-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1600_dscf7073-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x500_dscf7073-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x800_dscf7073-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x500_dscf7073-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7087.jpeg",
            "slug": "dscf7087-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1600_dscf7087-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1024_dscf7087-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1600_dscf7087-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x500_dscf7087-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x800_dscf7087-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x500_dscf7087-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF7156.jpeg",
            "slug": "dscf7156-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7156-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x682_dscf7156-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7156-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7156-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x533_dscf7156-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7156-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF7178.jpeg",
            "slug": "dscf7178-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7178-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x682_dscf7178-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7178-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7178-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x533_dscf7178-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7178-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "DSCF7190.jpeg",
            "slug": "dscf7190-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2133_dscf7190-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x1365_dscf7190-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x2133_dscf7190-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x666_dscf7190-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x1066_dscf7190-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x666_dscf7190-jpeg.jpeg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF7198.jpeg",
            "slug": "dscf7198-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7198-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x682_dscf7198-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7198-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7198-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x533_dscf7198-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7198-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF7268.jpeg",
            "slug": "dscf7268-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7268-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x682_dscf7268-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7268-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7268-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x533_dscf7268-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7268-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF7277.jpeg",
            "slug": "dscf7277-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7277-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x682_dscf7277-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7277-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7277-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x533_dscf7277-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7277-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF7289.jpeg",
            "slug": "dscf7289-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7289-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x682_dscf7289-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7289-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7289-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x533_dscf7289-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7289-jpeg.jpeg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2666,
            "name": "DSCF7332.jpeg",
            "slug": "dscf7332-jpeg",
            "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7332-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1024x682_dscf7332-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/1600x1066_dscf7332-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7332-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/2023-02-16-george-s-wedding/800x533_dscf7332-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x333_dscf7332-jpeg.jpeg",
            "width": 4000
         }
      ],
      "slug": "2023-02-16-george-s-wedding",
      "src": "/static/_gallery/albums/2023-02-16-george-s-wedding/500x500_dscf6823-jpeg.jpeg"
   }
};